import React from 'react';
import { StyledDescription, StyledTitle, SlideContainer } from './styles';
import plantImage from '../../../Images/Keiken/plant.png';
import { useTranslation } from 'react-i18next';

const SideImage = () => {
  const { t } = useTranslation();

  return (
    <SlideContainer>
      <img src={plantImage} alt="plant" width="178px" height="282px" />
      <StyledTitle>{t('login.aside.title')}</StyledTitle>
      <StyledDescription>{t('login.aside.subtitle')}</StyledDescription>
    </SlideContainer>
  );
};

export default SideImage;
