import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GroupActivityCategory } from 'src/Models/Business/CoParticipationGroup';
import BusinessGroupBenefits from 'src/Services/Api/BusinessGroupBenefits';
import BenefitCategoriesCard from './BenefitCategoriesCard';
import './styles.scss';

export interface BenefitCategories extends WithTranslation {
  selectedCategories?: GroupActivityCategory[];
  setSelectedCategories?: (items: GroupActivityCategory[]) => void;
  maxSpendingGroup: number;
}

const BenefitCategories: React.FC<BenefitCategories> = ({
  t,
  selectedCategories,
  setSelectedCategories,
  maxSpendingGroup = 0,
}) => {
  const [allCategories, setAllCategories] = useState(undefined);

  const activeAll = () => {
    let includeCategory: GroupActivityCategory[] = [];
    allCategories.forEach((it) => {
      if (
        !selectedCategories.some(
          (groupCategory) => groupCategory.activityCategory.id === it.id,
        )
      ) {
        includeCategory.push({
          activityCategory: it,
          maxSpendingCategory: maxSpendingGroup ?? 0,
        });
      }
    });
    setSelectedCategories([...selectedCategories, ...includeCategory]);
  };

  const getCategories = async () => {
    setAllCategories(
      (await BusinessGroupBenefits.getActivityCategories()).data.content,
    );
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="col-8 mt-40 BenefitCategories">
      <span className="title">{t('business.flux.choose_categories')}</span>
      <span className="headerBtn item " onClick={activeAll}>
        {t('business.flux.select_all')}
      </span>
      <div className="divider" />
      <div className="images">
        {allCategories &&
          allCategories.map((category) => (
            <BenefitCategoriesCard
              key={`BenefitCategoriesCard-${category.id}`}
              categories={selectedCategories}
              categoryId={category.id}
              setCategories={setSelectedCategories}
              image={category?.image?.imageUrl}
              name={category?.name}
            />
          ))}
      </div>
    </div>
  );
};

export default withTranslation()(BenefitCategories);
