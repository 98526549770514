import React, { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NavLink, Row } from 'reactstrap';
import BaseHeader from 'src/Components/BaseHeader';
import BusinessRegisterForm from 'src/Components/BusinessRegisterForm';
import { IRootState } from 'src/Stores';

import './styles.scss';

const BusinessRegister: React.FC<WithTranslation> = ({ t }) => {
  const history = useHistory();
  const isAuthenticated = useSelector(
    (state: IRootState) => state.authentication.isAuthenticated,
  );
  useEffect(() => {
    if (isAuthenticated) {
      history.replace('/login');
    }
  }, [isAuthenticated]);
  return (
    <div className="colorBackground BusinessRegister">
      <BaseHeader />
      <div className="colorBackground">
        <div className="container mt-8pc">
          <BusinessRegisterForm />
        </div>
      </div>
      <Row />
    </div>
  );
};

export default withTranslation()(BusinessRegister);
