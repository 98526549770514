import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';

import './styles.scss';
import BenefitSelect from 'src/Components/BenefitSelect';
import ColaboratorBenefits from 'src/Components/ColaboratorBenefits';
import ListColaborators from 'src/Components/ListColaborators';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import ToastService from 'src/Services/ToastService';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

export interface NoShowProps {
  noShowActive: boolean;
  noShowTimesForBlock: number;
  noShowMonthsBlocked: number;
}

export type BusinessNewSegments = WithTranslation;

const BusinessNewSegments: React.FC<BusinessNewSegments> = ({ t }) => {
  const history = useHistory();

  const [segmentName, setSegmentName] = React.useState<string>();
  const [segmentCustomers, setSegmentCustomers] = React.useState<any[]>([]);
  const [segmentBenefits, setSegmentBenefits] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [payrollDiscountEnabled, setPayrollDiscountEnabled] =
    React.useState<boolean>(false);
  const [noShowProps, setNoShowProps] = React.useState<NoShowProps>({
    noShowActive: false,
    noShowTimesForBlock: 0,
    noShowMonthsBlocked: 0,
  });

  const handleIncludeBenefit = (selected: boolean, benefit: any) => {
    if (selected) {
      const benefits = segmentBenefits.filter(
        (item: any) => item.id !== benefit.id,
      );
      setSegmentBenefits(benefits);
    } else {
      setSegmentBenefits((benefits) => [...benefits, benefit]);
    }
  };

  const createSegment = async () => {
    if (loading == true) {
      return;
    }
    const { noShowMonthsBlocked, noShowActive, noShowTimesForBlock } =
      noShowProps;
    if (
      noShowActive &&
      (noShowTimesForBlock < 1 ||
        noShowMonthsBlocked < 1 ||
        !noShowMonthsBlocked ||
        !noShowTimesForBlock)
    ) {
      ToastService.error(
        'Erro ao cadastrar novo segmento, número de faltas ou número de meses não pode ser zero.',
      );
      return;
    }

    setLoading(true);
    const result = await BusinessSegment.addOneSegment({
      customers: segmentCustomers,
      groups: segmentBenefits,
      nameSegment: segmentName,
      payrollDiscountEnabled,
      noShowActive: noShowProps.noShowActive,
      noShowTimesForBlock: noShowProps.noShowTimesForBlock,
      noShowMonthsBlocked: noShowProps.noShowMonthsBlocked,
    });
    setLoading(false);
    if (result.status <= 201) {
      history.replace('/benefits/segments');
    } else if (
      result.status == 400 &&
      result.data?.code == 'error.invalidParams' &&
      _.some(result.data.fieldErrors, (it) => it.field == 'nameSegment')
    ) {
      ToastService.error('Erro ao cadastrar novo segmento, nome inválido.');
    } else {
      ToastService.error('Erro ao cadastrar novo segmento.');
    }
  };

  return (
    <div className="businessNewSegments">
      <BaseHeaderBenefits
        step="create"
        color="green"
        title="Novo Segmento"
        to="/benefits/segments"
      />
      <div className="new-benefit-boxes">
        <BenefitSelect
          placeholder="Nome do segmento"
          name="benefit"
          type="text"
          title="Nome do Segmento"
          question="O que é o nome do segmento"
          value={segmentName}
          setValue={setSegmentName}
          payrollDiscountEnabled={payrollDiscountEnabled}
          setPayrollDiscountEnabled={setPayrollDiscountEnabled}
          noShowProps={noShowProps}
          setNoShowProps={setNoShowProps}
        />
        <ListColaborators
          customers={segmentCustomers}
          segmentName={segmentName}
          setSegmentCustomers={setSegmentCustomers}
          deleteOrEdit="delete"
          onOpenModal={() => setLoading(true)}
          onCloseModal={() => setLoading(false)}
        />
        <ColaboratorBenefits
          benefits={segmentBenefits}
          onChange={(items) => setSegmentBenefits(items)}
        />
        <div className="mb-40" />
      </div>
      <BaseFooterBenefits
        disabled={loading == true}
        hidden={loading == true}
        text="Criar segmento"
        hasBackButton="true"
        textBack="Cancelar"
        saveFunc={createSegment}
        backTo="/benefits/segments"
      />
    </div>
  );
};

export default withTranslation()(BusinessNewSegments);
