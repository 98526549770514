import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Link,
  NavLink,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AuthUtils from '../../Utils/AuthUtils';
import BusinessHeader from './BusinessHeader';
import LoggedIn from './LoggedIn';
import './styles.scss';

interface IFixedHeaderProps extends RouteComponentProps, WithTranslation {
  username: string;
}

interface IFixedHeaderState {}

class FixedHeader extends React.Component<
  IFixedHeaderProps,
  IFixedHeaderState
> {
  $defaultPath: string;

  constructor(props) {
    super(props);
    this.state = {};
    this.$defaultPath = '';
  }

  componentDidMount() {}

  render() {
    const { t } = this.props;
    return (
      <div className="fixed-header">
        <Container style={{ height: "100%" }}>
          <div className='fixed-header__items'>
            <div className="fixed-header__items--logo" />
            <ul className="fixed-header__items--menu">
              {AuthUtils.isBusiness() && (
                <>
                  <BusinessHeader />
                  <LoggedIn />
                </>
              )}
              {/* <li className="fixed-header__items--menu--logout">
                <NavLink to={this.redirectPath()}>
                <div className="logout-button">
                {t('header.account.logout')}
                </div>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(withRouter(FixedHeader));
