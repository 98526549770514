import { Reducer } from 'redux';
import { CompanyUser } from 'src/Models/CompanyUser';
import { Customer } from 'src/Models/Customer';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import AuthUtils from '../../Utils/AuthUtils';
import { AuthenticationActionTypes, AuthenticationState } from './types';
import { ActionType } from 'typesafe-actions';
import * as authAction from "./actions"

export type AuthAction = ActionType<typeof authAction>

export const initialState: AuthenticationState = {
  getSessionStatus: HttpRequestStatus.NOOP,
  loginStatus: HttpRequestStatus.NOOP,
  isAuthenticated: false,
};

const reducer: Reducer<AuthenticationState> = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.LOGIN_REQUEST: {
      return { ...state, loginStatus: HttpRequestStatus.ONGOING };
    }
    case AuthenticationActionTypes.LOGIN_SUCCESS: {
      return { ...state, loginStatus: HttpRequestStatus.SUCCESS, isAuthenticated: true };
    }
    case AuthenticationActionTypes.LOGIN_ERROR: {
      return { ...state, loginStatus: HttpRequestStatus.ERROR, error: action.payload };
    }
    case AuthenticationActionTypes.LOGIN_RESET_STATUS: {
      return { ...state, loginStatus: HttpRequestStatus.NOOP };
    }
    case AuthenticationActionTypes.GET_SESSION_REQUEST: {
      return { ...state, getSessionStatus: HttpRequestStatus.ONGOING };
    }
    case AuthenticationActionTypes.GET_SESSION_SUCCESS: {
      const companyUser = action.payload as CompanyUser;
      const account = companyUser?.user;
      return { ...state, getSessionStatus: HttpRequestStatus.SUCCESS, isAuthenticated: true, account, companyUser };
    }
    case AuthenticationActionTypes.GET_SESSION_ERROR: {
      return { ...state, getSessionStatus: HttpRequestStatus.ERROR, errors: action.payload };
    }

    case AuthenticationActionTypes.UPDATE_COMPANY_SESSION_STATE: {
      return { ...state, 
        companyUser: {
          ...state.companyUser,
          company: action.payload
        }
      };
    }
    case AuthenticationActionTypes.LOGOUT_REQUEST: {
      AuthUtils.removeToken();
      return { ...state, getSessionStatus: HttpRequestStatus.NOOP, loginStatus: HttpRequestStatus.NOOP, isAuthenticated: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authenticationReducer };
