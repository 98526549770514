import { AxiosResponse } from 'axios';
import { PageableResponse } from 'src/Models/pageable';
import { Address, City } from 'src/Models/Address';
import { Company } from 'src/Models/Company';
import { api } from '.';
import qs from 'qs';

const baseUrl = 'companies';

class BusinessCompany {
  static getAddressWithGeoPoint = async (formatedAddress: string): Promise<AxiosResponse<Address[]>> => {
    const params = qs.stringify({
      address: formatedAddress
    })
    return api.get(`/address/geolocalize?${params}`)
  }

  static getCityByName = (city: City): Promise<AxiosResponse<PageableResponse<City>>> => {
    const params = {
      name: city?.name,
      ['state.acronym']: city?.state?.acronym,
    };
    return api.get('/cities', { params });
  }

  static getCompanyData = (): Promise<AxiosResponse> => {
    return api.get(`${baseUrl}/users/me`);
  };

  static update = (company: Company): Promise<AxiosResponse> => {
    return api.put(`${baseUrl}/${company.id}`, company);
  };
}

export default BusinessCompany;
