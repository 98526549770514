import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { ReactComponent as Arrow } from '../../../Images/Icons/arrow.svg';

export interface SegmentsCardProps extends WithTranslation {
  linkTo: string;
  categories: string[];
  nContributors: string;
  segment: string;
}

const SegmentsCard: React.FC<SegmentsCardProps> = ({
  t,
  segment,
  nContributors,
  categories,
  linkTo,
}) => {
  return (
    <Card className="SegmentsCard">
      <div className="SegmentsCard--title">{segment}</div>
      <div className="SegmentsCard--contributors">
        {nContributors} {t('menu.contributors')}
      </div>
      <div className="SegmentsCard__segments">
        {categories?.map((segment, index) => (
          <div
            key={`segment-card-cat-${index}`}
            className="SegmentsCard__segments--segment">
            {segment}
          </div>
        ))}
      </div>
      <Link to={linkTo} className="SegmentsCard--link">
        Editar <Arrow />
      </Link>
    </Card>
  );
};

export default withTranslation()(SegmentsCard);
