import { WalletPieData } from 'src/Models/CompanyWallet';
import graphColors from 'src/Themes/graphColors';

export const getPieDataWithColors = (pieData: WalletPieData[]) => {
  const colors = Object.values(graphColors);
  const pieDataWithColors = pieData.map((item, index) => ({
    ...item,
    color: colors[index],
  }));
  return pieDataWithColors;
}