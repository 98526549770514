import React from 'react';
import './styles.scss';
import times from '../../Images/Keiken/Business/Button.svg';
import { Link } from 'react-router-dom';

const BaseHeaderContributor: React.FC = () => {
  return (
    <div className="headerGreen">
      <Link to="/benefits/contributors" className="button">
        <img src={times} alt="exit" />
      </Link>
      <span className="span">Editar Colaborador</span>
    </div>
  );
};

export default BaseHeaderContributor;
