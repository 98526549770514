import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import AddMany from 'src/Components/AddColaboratorsModal/AddMany';
import AddSingle from 'src/Components/AddColaboratorsModal/AddSingle';
import { Popover } from '@material-ui/core';
import PopoverAddCustomers from 'src/Components/AddColaboratorsModal/PopoverAddCustomers';
import OrderBy from '../../OrderBy';
import SearchText from '../../SearchText';
import { ReactComponent as DoubleArrow } from '../../../Images/Icons/doubleArrow.svg';
import { Filter } from '../index';

export interface SettingsProps extends WithTranslation {
  searchName: string;
  setSearchName: React.Dispatch<React.SetStateAction<string>>;
  search: boolean;
  setSearch: React.Dispatch<React.SetStateAction<boolean>>;
  sort: boolean;
  setSort: React.Dispatch<React.SetStateAction<boolean>>;
  newer: boolean;
  setNewer: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  filter: Filter;
  onSubmit: () => void;
  setContributorsSelected: React.Dispatch<React.SetStateAction<any[]>>;
  contributorsSelected: any[];
  redirectToSegment: boolean;
}

const Settings: React.FC<SettingsProps> = ({
  t,
  searchName,
  setSearchName,
  onSubmit,
  search,
  setSearch,
  sort,
  setSort,
  newer,
  setNewer,
  setFilter,
  filter,
  contributorsSelected,
  setContributorsSelected,
  redirectToSegment
}) => {
  const [openSingle, setOpenSingle] = useState<boolean>(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [popover, setPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allDiscounts, setAllDiscounts] = useState<boolean>(false);

  function handleOpenTooltip() {
    setOpenTooltip(true);
  }

  const handleOpenSingle = () => {
    setOpenTooltip(false);
    setOpenSingle(true);
  };

  const handleOpenMultiple = () => {
    setOpenTooltip(false);
    setOpenMultiple(true);
  };

  useEffect(() => {
    let newContributorList = [];
    contributorsSelected.map((contributor) => {
      const newContributor = contributor;

      newContributor.payrollDiscount = allDiscounts;

      newContributorList = [...newContributorList, newContributor];
    });

    setContributorsSelected(newContributorList);
  }, [allDiscounts]);

  const popoverText =
    filter === Filter.ALL
      ? t('Contributors.all')
      : filter === Filter.BLOCKED_BY_NO_SHOW
      ? t('Contributors.blockedByNoShow')
      : filter === Filter.WITHOUT_BENEFITS
      ? t('Contributors.noSegments')
      : t('Contributors.withSegments');

  return (
    <>
      <AddMany
        setOpenMultiple={setOpenMultiple}
        openMultiple={openMultiple}
      />
      <AddSingle
        setOpenSingle={setOpenSingle}
        openSingle={openSingle}
      />
      <div className="settings">
        <div className="settings__wrapper">
          <div className="settings__description">
            <div>
              <div
                id="simple-popover"
                className="align-center all-contributor-row"
                onClick={(event) => {
                  setPopover(true);
                  setAnchorEl(event.currentTarget);
                }}
              >
                <DoubleArrow className="settings__description--icon" />
                <div className="settings__description--text">{popoverText}</div>
              </div>
              <Popover
                anchorEl={anchorEl}
                open={popover}
                onClose={() => {
                  setPopover(false);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div
                  className={`OrderBy__dropdown__card--text ${
                    filter === Filter.ALL && 'true'
                  }`}
                  onClick={() => setFilter(Filter.ALL)}
                >
                  Todos os Colaboradores
                </div>
                <div
                  className={`OrderBy__dropdown__card--text ${
                    filter === Filter.WITH_BENEFITS && 'true'
                  }`}
                  onClick={() => setFilter(Filter.WITH_BENEFITS)}
                >
                  Colaboradores com segmento
                </div>
                <div
                  className={`OrderBy__dropdown__card--text ${
                    filter === Filter.WITHOUT_BENEFITS && 'true'
                  }`}
                  onClick={() => setFilter(Filter.WITHOUT_BENEFITS)}
                >
                  Colaboradores sem segmento
                </div>
                <div
                  className={`OrderBy__dropdown__card--text ${
                    filter === Filter.BLOCKED_BY_NO_SHOW && 'true'
                  }`}
                  onClick={() => setFilter(Filter.BLOCKED_BY_NO_SHOW)}
                >
                  Bloqueados por falta
                </div>
              </Popover>
            </div>
          </div>
          {/* {contributorsSelected?.length > 1 && (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <label className="switch" style={{ marginBottom: '0px' }}>
                <input
                  type="checkbox"
                  onChange={() => setAllDiscounts(!allDiscounts)}
                  checked={allDiscounts}
                />
                <span className="slider round" />
              </label>

              <div className="switchText"> Habilitar descontro em folha</div>
            </div>
          )} */}

          <div className="settings__actions">
            <div className="settings__actions--row">
              <SearchText
                searchName={searchName}
                setSearchName={setSearchName}
                search={search}
                setSearch={setSearch}
                onSubmit={onSubmit}
              />
              <OrderBy
                type="contributor"
                first={sort}
                setFirst={setSort}
                second={newer}
                setSecond={setNewer}
              />
              <PopoverAddCustomers
                handleOpenMultiple={handleOpenMultiple}
                handleOpenSingle={handleOpenSingle}
                redirectToSegment={redirectToSegment}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Settings);
