import React from 'react';
import { ButtonContainer } from './styles';

export interface GradientButtonProps {
  isSmall?: boolean;
  buttonDisabled?: boolean;
  title?: string;
  isSecundary?: boolean;
  onClick?: () => void;
}

const GradientButtonSC: React.FunctionComponent<GradientButtonProps> = (
  props,
) => {
  const { onClick, buttonDisabled, title, isSecundary } = props;

  function handleClick() {
    if (onClick && !buttonDisabled) {
      onClick();
    }
  }

  return (
    <ButtonContainer
      disabled={buttonDisabled}
      isSecundary={isSecundary}
      onClick={handleClick}>
      <div>
        <label>{title}</label>
      </div>
    </ButtonContainer>
  );
};

export default GradientButtonSC;
