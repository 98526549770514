import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import 'toasted-notes/src/styles.css';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import { HttpResponseTypeError } from '../../Models/Utils/HttpReponseError';
import { IRootState } from 'src/Stores';
import {
  getSessionRequest,
  loginRequest,
  loginResetStatus,
  logoutRequest,
} from '../../Stores/authentication/actions';
import AuthUtils from '../../Utils/AuthUtils';

import { CircleLoading } from 'react-loadingg';
import Images from '../../Themes/Constants/Images';
import LogoImage from '../../Images/Keiken/logo.png';
import SideImage from './SideImage';

import CustomTextField from 'src/Components/CustomTextField';
import WrongLoginModal from 'src/Components/WrongLoginModal';
import { AUTHORITIES } from 'src/Config/Constants';

import {
  DataContainer,
  LogoBox,
  StyledButton,
  StyledDescription,
  StyledForm,
  StyledTitle,
  StyledScreenContainer,
  IconLogin,
} from './style';

interface ILoginProps
  extends StateProps,
    DispatchProps,
    WithTranslation,
    RouteComponentProps<{}> {}

export interface ILoginState {
  login: string;
  password: string;
  loading: boolean;
  isLoadingSend: boolean;
  hasErrorLogin: boolean;
  usernameError?: boolean;
  passwordError?: boolean;
  isLoadingAction: boolean;
  usernameErrorText: string;
  passwordErrorText: string;
  loginResetPassword: string;
  isResetPasswordOpen: boolean;
  getSessionEventPrevent: boolean;
  loginResetPasswordError: boolean;
  loginResetPasswordErrorText: string;
  isWrongLoginModalOpen: boolean;
  userRole: AUTHORITIES | null;
}

class Login extends React.Component<ILoginProps, ILoginState> {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      loading: true,
      isLoadingSend: false,
      hasErrorLogin: false,
      loginResetPassword: '',
      isLoadingAction: false,
      isResetPasswordOpen: false,
      getSessionEventPrevent: false,
      loginResetPasswordError: false,
      loginResetPasswordErrorText: '',
      usernameErrorText: this.props.t('login.error.username'),
      passwordErrorText: this.props.t('login.error.password'),
      isWrongLoginModalOpen: false,
      userRole: null,
    };
  }

  componentDidMount() {
    if (this.props?.location?.state?.['from'] != null) {
      window.history.replaceState(
        null,
        '',
        this.props.location?.state?.['from'],
      );
    }

    if (AuthUtils.isAuthenticated()) {
      console.log('redirecting');
      this.redirectToPath();
    } else {
      console.log('not authenticated');
      this.loading(false);
    }
  }

  componentWillReceiveProps(newProps: Readonly<ILoginProps>) {
    if (newProps.getSessionStatus === HttpRequestStatus.SUCCESS) {
      this.redirectToPath();
    }

    if (newProps.loginStatus === HttpRequestStatus.SUCCESS) {
      this.props.loginResetStatus();
    }

    if (newProps.loginStatus === HttpRequestStatus.ERROR) {
      switch (newProps.loginError?.type) {
        case HttpResponseTypeError.LOGIN_ERROR_USER:
          this.setState({
            loading: false,
            passwordError: undefined,
            usernameError: true,
          });
          break;
        case HttpResponseTypeError.LOGIN_ERROR_PASSWORD:
          this.setState({
            loading: false,
            usernameError: undefined,
            passwordError: true,
          });
          break;
        case HttpResponseTypeError.LOGIN_ERROR_PARTNER_LOGIN:
          this.setState({
            loading: false,
            userRole: AUTHORITIES.PARTNER,
            isWrongLoginModalOpen: true,
          });
          break;
        case HttpResponseTypeError.LOGIN_ERROR_CUSTOMER_LOGIN:
          this.setState({
            loading: false,
            userRole: AUTHORITIES.CUSTOMER,
            isWrongLoginModalOpen: true,
          });
          break;
        case HttpResponseTypeError.UNKNOWN:
        case HttpResponseTypeError.LOGIN_ERROR_USER_AND_PASSWORD:
          this.setState({
            loading: false,
            passwordError: true,
            usernameError: true,
          });
          break;
      }
      this.props.loginResetStatus();
    }
  }

  redirectToPath = () => {
    const redirectPath =
      this.props.location.state?.['from'] || AuthUtils.getMainPath();
    this.props.history.replace(redirectPath);
  };

  loading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  isLoadingAction = () => {
    this.setState({
      isLoadingAction: !this.state.isLoadingAction,
    });
  };

  isLoadingSend = () => {
    this.setState({
      isLoadingSend: !this.state.isLoadingSend,
    });
  };

  handleSubmit = () => {
    if (this.state.login.length === 0 || this.state.password.length === 0) {
      this.setState({
        usernameError: this.state.login.length === 0,
        passwordError: this.state.password.length === 0,
        usernameErrorText: this.props.t('login.error.usernameLength'),
        passwordErrorText: this.props.t('login.error.passwordLength'),
      });
    } else {
      this.setState({
        loading: true,
        passwordError: false,
        usernameError: false,
      });
      this.props.login({
        username: this.state.login,
        password: this.state.password,
      });
    }
  };

  onChangeUserName = (value: string) => {
    this.setState({
      login: value,
    });
  };

  onChangePassword = (value: string) => {
    this.setState({
      password: value,
    });
  };

  onSetErrorLogin = (value: boolean) => {
    this.setState({
      hasErrorLogin: value,
    });
  };

  onBlurUsername = () => {
    this.setState({
      usernameError: this.state.usernameError
        ? this.state.login.length === 0
        : undefined,
    });
  };

  onBlurPassword = () => {
    this.setState({
      passwordError: this.state.passwordError
        ? this.state.password.length === 0
        : undefined,
    });
  };

  closeModal = () => {
    this.setState({
      isWrongLoginModalOpen: false,
    });
  };

  render() {
    const {
      login,
      password,
      usernameError,
      usernameErrorText,
      passwordError,
      passwordErrorText,
      isWrongLoginModalOpen,
      userRole,
    } = this.state;
    const { t } = this.props;

    return (
      <StyledScreenContainer
        theme={{
          width: '100vw',
          maxWidth: '100vw',
          minWidth: '100vw',
          height: '100vh',
          minHeight: '100vh',
          maxHeight: '100vh',
          flexDirection: 'row',
          margin: '0px',
          padding: '0px',
          backgroundColor: '#fff',
          justifyContent: 'center',
          alingItems: 'center',
        }}>
        <DataContainer>
          <LogoBox>
            <img src={LogoImage} alt="logo" />
          </LogoBox>
          <StyledTitle>{this.props.t('login.title')}</StyledTitle>
          <StyledDescription>{this.props.t('login.welcome')}</StyledDescription>
          <div>
            <StyledForm>
              <CustomTextField
                style={{ marginBottom: '40px' }}
                onChange={this.onChangeUserName}
                id="username"
                isEmail
                value={login}
                label={'E-mail'}
                placeholder={'E-mail'}
                error={usernameError}
                errorText={usernameErrorText}
                onBlur={this.onBlurUsername}
                leftIcon={<IconLogin src={Images.icons.mailGrey} />}
              />
              <CustomTextField
                onChange={this.onChangePassword}
                id="password"
                isPassword
                value={password}
                label={'Senha'}
                placeholder={'Senha'}
                onEnterPress={this.handleSubmit}
                error={passwordError}
                errorText={passwordErrorText}
                onBlur={this.onBlurPassword}
                InputProps={{
                  autoComplete: 'off',
                }}
                leftIcon={<IconLogin src={Images.icons.lockGrey} />}
              />
            </StyledForm>
          </div>
          <StyledButton onClick={this.handleSubmit}>
            {this.state.loading ? <CircleLoading /> : t('login.confirm')}
          </StyledButton>
        </DataContainer>
        <SideImage />
        <WrongLoginModal
          userRole={userRole}
          onCancel={this.closeModal}
          isOpen={isWrongLoginModalOpen}
        />
      </StyledScreenContainer>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginStatus: authentication.loginStatus,
  loginError: authentication.error,
  isAuthenticated: authentication.isAuthenticated,
  getSessionStatus: authentication.getSessionStatus,
});

const mapDispatchToProps = {
  login: loginRequest,
  logout: logoutRequest,
  getSession: getSessionRequest,
  loginResetStatus: loginResetStatus,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(Login) as React.ComponentType<{}>;
