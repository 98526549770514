import qs from 'qs';
import HttpStatus from '../Models/Enums/HttpStatus';
import { Pageable } from '../Models/pageable';
import { Predicate } from '../Models/predicate';

export const checkApiStatus = (validation: boolean, status: number, expectedStatus?: HttpStatus): boolean => {
  const compareStatus = expectedStatus || HttpStatus.OK;
  return status === compareStatus && validation;
};

export const requestParamsFormatter = (predicate: Predicate, page: Pageable, predicateParams?: object) => {
  const pageParams = qs.stringify({ ...page });
  const filterParams: string = Object.keys(predicate)
    .filter(key => predicate[key] != null)
    .map(key =>
      Array.isArray(predicate[key])
        ? `${key}=${predicate[key].map(item => item[predicateParams?.[key] || 'name']).join(',')}`
        : `${key}=${predicate[key]}`
    )
    .join('&');
  return [pageParams, filterParams];
};
