import { AxiosResponse } from 'axios';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import { CoParticipationSegment } from 'src/Models/Business/CoParticipationSegment';
import { api } from '.';

const baseUrl = 'co-participation-segments';
class BusinessSegment {
  static getAllSegments = (): Promise<AxiosResponse<CoParticipationSegment[]>> => {
    return api.get<CoParticipationSegment[]>(`${baseUrl}/from-my-company`);
  };

  static getOneSegment = (id: number):  Promise<AxiosResponse> => {
    return api.get(`${baseUrl}/${id}`);
  };

  static deleteOneSegment = (id: number):  Promise<AxiosResponse> => {
    return api.delete(`${baseUrl}/${id}`);
  };

  static addOneSegment = (segment: CoParticipationSegment):  Promise<AxiosResponse> => {
    return api.post(`${baseUrl}`, segment);
  };

  static editOneSegment = (segment: CoParticipationSegment):  Promise<AxiosResponse> => {
    return api.put(`${baseUrl}`, segment);
  };
}

export default BusinessSegment;
