import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './styles.scss';

export interface BaseFooterBenefits extends WithTranslation {
  text?: string;
  textBack?: string;
  hasBackButton?: string;
  linkTo?: string;
  disabled?: boolean;
  hidden?: boolean;
  saveFunc?: () => void;
  backTo?: string;
}

const BaseFooterBenefits: React.FC<BaseFooterBenefits> = ({
  t,
  text,
  textBack,
  hasBackButton,
  linkTo,
  backTo = "/benefits/benefits",
  saveFunc,
  disabled = false,
  hidden = false,
}) => {
  if (hidden == true) {
    return null;
  }
  return (
    <div>
      <div className="footerBenefits">
        {hasBackButton && (
          <Link to={backTo} className="back">
            {textBack}
          </Link>
        )}

        <div className="positionButton" onClick={() => saveFunc && saveFunc()}>
          {linkTo != null ? (
            <Link to={linkTo} className="button">
              {text}
            </Link>
          ) : (
            <button disabled={disabled} className="button">
              {text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BaseFooterBenefits);
