import React, { useState } from 'react';
import { Edit } from '@material-ui/icons';
import { Address, AddressUtils } from 'src/Models/Address';
import { Company } from 'src/Models/Company';
import { useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CircleLoading } from 'react-loadingg';

import { updateCompanyData } from 'src/Stores/company/action';
import { UpdateCompanyTarget } from 'src/Stores/company/types';
import ModalAddressForm from './components/ModalAdressForm';

interface AddressDataProps extends WithTranslation {
  company: Company;
}

const AddressData: React.FC<AddressDataProps> = ({ t, company }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleUpdateAddress = async (address: Address) => {
    const companyHaveGeoPoint = !!company.address?.geoPoint;
    const isGeopointDifferent =
      companyHaveGeoPoint &&
      (company.address.geoPoint.latitude !== address.geoPoint.latitude ||
        company.address.geoPoint.longitude !== address.geoPoint.longitude);
    const shouldUpdateImage = !companyHaveGeoPoint || isGeopointDifferent;

    const newAddress = shouldUpdateImage
      ? { ...address, image: null }
      : address;

    dispatch(
      updateCompanyData(
        { ...company, address: newAddress },
        UpdateCompanyTarget.COMPANY_ADDRESS,
      ),
    );

    setIsEditModalOpen(false);
  };

  const address = company?.address;

  return (
    <div className="BusinessCard">
      <div className="BusinessCard--header">
        <h3 className="addressCard--header--title">
          {t('cards.business.address')}
        </h3>
        <div
          className="BusinessCard--button"
          onClick={() => setIsEditModalOpen(true)}>
          <Edit />
          <span>{t('cards.business.addressEdit')}</span>
        </div>
      </div>
      <hr className="hr" />
      <div className="addressImage-container">
        {!address ? (
          <h4 className="center-Title">{t('cards.business.noAddressImage')}</h4>
        ) : !address.image?.imageUrl ? (
          <h4 className="center-Title">
            <CircleLoading
              style={{ position: 'static', marginInline: 'auto' }}
            />
            {t('cards.business.imageProcessing')}
          </h4>
        ) : (
          <img src={address.image.imageUrl} alt="map location" />
        )}
      </div>
      <p className="adress-formated">{AddressUtils.format(address)}</p>
      <ModalAddressForm
        isOpen={isEditModalOpen}
        data={address}
        onClose={() => setIsEditModalOpen(false)}
        onConfirm={handleUpdateAddress}
      />
    </div>
  );
};

export default withTranslation()(AddressData);
