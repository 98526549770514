import React, {useState} from 'react';
import { Lock } from '@material-ui/icons';
import EditPassModal from './EditPassModal';
import BusinessAccount from 'src/Services/Api/BusinessAccount';
import { useTranslation } from 'react-i18next';
import ToastService from 'src/Services/ToastService';
import BusinessService from 'src/Services/BusinessService';

const ChangePassCard = () => {
  const [changePassModal, setChangeditPassModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleUpdatePassword = async (oldPass: string, newPass: string, confirmNewPass: string) => {
    if (newPass !== confirmNewPass) return alert('Senhas novas diferentes');
    await BusinessService.changePass({
      newPassword: newPass,
      currentPassword: oldPass,
    }).then(res => ToastService.success(t('cards.edit.passSuccess'))
    ).catch(err => {
      if(err.code === "error.update.password.wrong.current.password"){
        ToastService.error(t('cards.edit.passError'))
      } else {
        ToastService.error(t('cards.edit.passError2'))
      }
    });

    setChangeditPassModal(false);
  }

  return (
    <>
      <div className="BusinessCard">
        <div className="BusinessCard--header">
          <h3>{t('cards.header.password')}</h3>
          <div onClick={() => setChangeditPassModal(true)} className="BusinessCard--button">
            <Lock />
            {t('cards.edit.pass')}
          </div>
        </div>
      </div>
      <EditPassModal
        isOpen={changePassModal}
        onClose={() => setChangeditPassModal(false)}
        onConfirm={handleUpdatePassword}
      />
    </>
  );
};

export default ChangePassCard;