import { ChevronRightOutlined, ImportExport } from '@material-ui/icons';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CoParticipationCustomer } from 'src/Models/Business/CoParticipationCustomer';
import Images from 'src/Themes/Constants/Images';
import AddMany from '../AddColaboratorsModal/AddMany';
import AddSingle from '../AddColaboratorsModal/AddSingle';
import PopoverAddCustomers from '../AddColaboratorsModal/PopoverAddCustomers';
import Pagination from '../Pagination';

import './styles.scss';

export interface ListColaborators extends WithTranslation {
  customers?: CoParticipationCustomer[];
  segmentName: string;
  setSegmentCustomers: any;
  deleteOrEdit: string;
  onOpenModal?: () => void;
  onCloseModal?: () => void;
}

const ListColaborators: React.FC<ListColaborators> = ({
  t,
  customers,
  segmentName,
  setSegmentCustomers,
  deleteOrEdit,
  onOpenModal,
  onCloseModal,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openSingle, setOpenSingle] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [customerPage, setCustomerPage] = useState<CoParticipationCustomer[]>(
    [],
  );
  const handleAllPages = () => {
    if (customers.length > 0) {
      return Math.ceil(customers.length / 5);
    }
    return 1;
  };
  function handleOpenTooltip() {
    setOpenTooltip(!openTooltip);
  }

  const handleOpenSingle = () => {
    onOpenModal && onOpenModal();
    setOpenTooltip(false);
    setOpenSingle(true);
  };

  const handleOpenMultiple = () => {
    onOpenModal && onOpenModal();
    setOpenTooltip(false);
    setOpenMultiple(true);
  };

  const handleDelete = (contributor: any) => {
    setSegmentCustomers((customers) =>
      customers.filter((customer) => customer?.email !== contributor?.email),
    );
  };

  React.useEffect(() => {
    setCustomerPage(customers.slice((page - 1) * 5, page * 5));
  }, [page, customers]);
  return (
    <div className="col-8 mt-40 ListColaborators">
      <div className="flex space-btwn">
        <div className="flex">
          <span className="title">
            {t('business.flux.colaborators_of_segment')}
            {' '}
          </span>
          <span className="titleGreen">
            {' '}
            | 
            {' '}
            {customers?.length} 
            {' '}
            {t('business.flux.colaborators')}
          </span>
        </div>
        <PopoverAddCustomers
          handleOpenMultiple={handleOpenMultiple}
          handleOpenSingle={handleOpenSingle}
        />
      </div>

      <div className="divider" />
      {customerPage.length == 0 && (
        <div className="mb-30">
          <AccountBoxOutlinedIcon className="icon" />
          <br />
          <span className="col-3 text-card">
            {t('business.flux.dont_have_yet')}
            <br />
            {t('business.flux.colaborators_in_this_segment')}
          </span>
        </div>
      )}

      <div>
        <table className="width-100">
          {customerPage.map((item) => (
            <tr className="line-table col-12">
              <td className="col-3">
                <div className="tableAlign">
                  {/* <CustomBoxCheckBox /> */}
                  <img src={Images.icons.defaultPerfil} alt="img" />
                  <div className="names">
                    <span className="name">
                      {item?.customer?.name ?? 'Novo colaborador'}
                    </span>
                    <span className="email">{item?.email}</span>
                  </div>
                </div>
              </td>
              <td className="col-6 alignTags">
                <span className="tag">{segmentName || 'Sem nome'}</span>
                {item?.groups?.map((group, index) => (
                  <>
                    {index < 2 && (
                      <span className="tag2">{group?.nameGroup}</span>
                    )}
                    {index === 2 && (
                      <span className="tag2">
                        +
                        {item.groups.length - 2}
                      </span>
                    )}
                  </>
                ))}
              </td>
              <td className="col-2">
                {deleteOrEdit === 'edit' ? (
                  <div className="end">
                    <span>{t('business.flux.edit')}</span>
                    <ChevronRightOutlined />
                  </div>
                ) : deleteOrEdit === 'delete' ? (
                  <div className="end" onClick={() => handleDelete(item)}>
                    <DeleteOutlineOutlinedIcon className="right circle" />
                  </div>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
        </table>
        <Pagination page={page} allPages={handleAllPages()} setPage={setPage} />
      </div>

      <AddMany
        setOpenMultiple={(value) => {
          setOpenMultiple(value);
          if (value == false) {
            onCloseModal && onCloseModal();
          } else if (value == true) {
            onOpenModal && onOpenModal();
          }
        }}
        openMultiple={openMultiple}
        segmentName={segmentName}
        setSegmentCustomers={setSegmentCustomers}
      />
      <AddSingle
        setOpenSingle={(value) => {
          setOpenSingle(value);
          if (value == false) {
            onCloseModal && onCloseModal();
          } else if (value == true) {
            onOpenModal && onOpenModal();
          }
        }}
        openSingle={openSingle}
        segmentName={segmentName}
        setSegmentCustomers={setSegmentCustomers}
      />
    </div>
  );
};

export default withTranslation()(ListColaborators);
