import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';

import './styles.scss';
import CreateSegment from 'src/Components/CreateSegment';
import AddColaboratorsSegment from 'src/Components/AddColaboratorsSegment';
import AddedColaborators from 'src/Components/AddedColaborators';
import { useSelector } from 'react-redux';
import BusinessGroupBenefits from 'src/Services/Api/BusinessGroupBenefits';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import { useHistory } from 'react-router-dom';
import ToastService from 'src/Services/ToastService';
import _ from 'lodash';

export type BusinessAddColaborators = WithTranslation

const BusinessAddColaborators: React.FC<BusinessAddColaborators> = ({ t }) => {
  const history = useHistory();

  const benefits = useSelector((state) => (state as any).createBenefit.benefit);
  const [segmentName, setSegmentName] = React.useState<string>('');
  const [segmentCustomers, setSegmentCustomers] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const createNewBenefit = async () => {
    if (loading == true) {
      return;
    }
    setLoading(true);
    let result = await BusinessGroupBenefits.addOneGroupBenefit(benefits);
    if (result.status != 201) {
      ToastService.error('Erro ao criar benefício.');
      return;
    }
    const benefit = result.data;
    result = await BusinessSegment.addOneSegment({
      customers: segmentCustomers,
      groups: [benefit],
      nameSegment: segmentName,
    });
    setLoading(false);
    if (result.status <= 201) {
      ToastService.success('Benefício criado com sucesso.', {
        onClose: () => {
          history.replace('/benefits/benefits');
        },
        delay: 500
      });
    } else {
      ToastService.error('Erro ao criar benefício.');
    }
  };

  return (
    <div className="businessAddColaborators">
      <BaseHeaderBenefits step="add" to="/create-benefit" />
      <div className="mt-100">
        <CreateSegment
          type="text"
          placeholder={t('business.flux.example_placeholder')}
          name="segment"
          value={segmentName}
          setValue={setSegmentName}
        />
        <AddColaboratorsSegment
          placeholder="colaborador@email.com.br"
          type="email"
          name="email"
          setSegmentCustomers={setSegmentCustomers}
        />
        <div className="col-8 marginAutoZero">
          <AddedColaborators
            segmentName={segmentName}
            segmentCustomers={segmentCustomers}
            setSegmentCustomers={setSegmentCustomers}
          />
        </div>
      </div>
      <BaseFooterBenefits
        disabled={loading}
        text={t('business.flux.finhish_benefit')}
        hasBackButton="true"
        textBack={t('business.flux.back_to_benefit')}
        saveFunc={createNewBenefit}
      />
    </div>
  );
};

export default withTranslation()(BusinessAddColaborators);
