import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FixedHeader from 'src/Components/FixedHeader';
import MenuAndContent from 'src/Components/MenuAndContent';
import PageTitle from 'src/Components/PageTitle';
import BusinessCards from 'src/Components/BusinessCards';
import BusinessChildrenCards from 'src/Components/BusinessChildrenCards';

export interface BusinessProfileProps extends WithTranslation {}

const BusinessProfile: React.FC<BusinessProfileProps> = ({ t }) => {
  return (
    <>
      {/* <FixedHeader /> */}
      <PageTitle title="profile" />
      <MenuAndContent
        redirect="data"
        prefix="/profile/"
        menuOptions={[
          {
            id: 1,
            title: 'data',
            component: <BusinessCards />,
          },
          // {
            // TODO: waiting api
            //   id: 2,
            //   title: 'children',
            //   component: <BusinessChildrenCards />,
            //   comingSoon: true,
          // },
        ]}
      />
    </>
  );
};

export default withTranslation()(BusinessProfile);
