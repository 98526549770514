import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { getWalletById } from 'src/Stores/company/action';
import GraphCard from 'src/Components/BigCardInfo/GraphCard';
import dayjs, { Dayjs } from 'dayjs';
import Balance from './Balance';
import { ReactComponent as Arrow } from '../../Images/Icons/arrow.svg';
import './styles.scss';
import SegmentActivities from '../BigCardInfo/SegmentActivities';
import SubCategoriesCard from '../SubCategoriesCard';

export interface WalletProps
  extends WithTranslation,
  DispatchProps,
  StateProps { }

export interface ISubcategory {
  key: string;
  count: number;
}

const Wallet: React.FC<WalletProps> = ({
  t,
  companyWallet,
  getWalletById,
  company,
}) => {
  const [modalPage, setModalPage] = useState<string>('');
  const [date, setDate] = useState<Dayjs>(dayjs());

  const toggle = (main: string, step?: string) =>
    setModalPage(`${main} ${step}`);

  useEffect(() => {
    getWalletById(company.id, date);
  }, [date]);

  const totalCount = companyWallet?.pieData.reduce((acc, item) => {
    return acc + item.count;
  }, 0);

  const shouldDisplaySegments = totalCount > 4; // to discuss with the team

  return (
    <Container className="no-padding wallet">
      {/* <Balance
        modal={modalPage}
        toggle={toggle}
        companyWallet="fund"
        wallet={companyWallet}
      /> */}
      <Balance
        modal={modalPage}
        toggle={toggle}
        companyWallet="to_pay"
        wallet={companyWallet}
      />
      <Row className="Section">
        <div className="title">{t('wallet.wallet.cards.activityTitle')}</div>
        <a className="historicButton" href="/wallet/extract">
          {t('wallet.wallet.cards.activityHistory')}
          <Arrow />
        </a>
      </Row>
      <Row>
        <Container className="no-padding">
          <div className="graphs-row">
            <GraphCard
              pieData={companyWallet?.pieData}
              emptyMessage={t('wallet.wallet.cards.notEnouthData')}
              withDatePicker
              date={date}
              setDate={setDate}
            />
            {shouldDisplaySegments && (
              <SegmentActivities
                pieData={companyWallet?.pieData}
                segments={companyWallet?.segments}
              />
            )}
          </div>
        </Container>
      </Row>
      {shouldDisplaySegments && (
        <Row className="subCategories-cards">
          {companyWallet?.activityCategories.map((activity) => {
            const { count, activitySubCategories, name } = activity;
            const myCat = companyWallet.pieData.filter(
              (item) => item.name === name,
            );

            return (
              <SubCategoriesCard
                key={name}
                total={count}
                title={name}
                subcategories={activitySubCategories}
                color={myCat[0].color}
              />
            );
          })}
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    companyWallet: state.companyState.companyWallet,
    company: state.authentication?.companyUser.company,
  };
};

const mapDispatchToProps = {
  getWalletById,
};
type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Wallet),
);
