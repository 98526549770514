import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';

import './styles.scss';
import Pagination from '../Pagination';

export interface AddedColaborators extends WithTranslation {
  segmentCustomers: any[];
  segmentName: string;
  setSegmentCustomers: React.Dispatch<React.SetStateAction<any[]>>;
}

const AddedColaborators: React.FC<AddedColaborators> = ({
  t,
  segmentCustomers,
  segmentName,
  setSegmentCustomers,
}) => {
  const [customersFiltered, setCustomersFiltered] = React.useState<any[]>([]);
  const [page, setPage] = React.useState<number>(1);

  const handleDelete = (contributor: any) => {
    setSegmentCustomers((customers) =>
      customers.filter((customer) => customer?.email !== contributor?.email),
    );
  };

  const handleAllPages = () => {
    if (segmentCustomers.length > 0) {
      return Math.ceil(segmentCustomers.length / 5);
    }
    return 1;
  };

  React.useEffect(() => {
    setCustomersFiltered(segmentCustomers.slice((page - 1) * 5, page * 5));
  }, [page, segmentCustomers]);

  return (
    <div className="mt-40 AddedColaborators">
      <span className="title">Colaboradores adicionados</span>
      <div className="divider" />
      {customersFiltered.length == 0 && (
        <div className="mb-30">
          <AccountBoxOutlinedIcon className="icon" />
          <br />
          <span className="col-3 text-card">
            Você ainda não tem nenhum 
            {' '}
            <br />
            colaborador adicionado
          </span>
        </div>
      )}

      <div>
        <table className="width-100">
          {customersFiltered.map((customer) => (
            <>
              <tr className="line-table col-12">
                <td className="col-3 email">{customer?.email}</td>
                <td className="col-2">
                  <div className="tag">{segmentName || 'Sem segmento'}</div>
                </td>
                <td
                  className="col-7"
                  onClick={() => {
                    handleDelete(customer);
                  }}
                >
                  <DeleteOutlineOutlinedIcon className="right circle" />
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
      <Pagination page={page} allPages={handleAllPages()} setPage={setPage} />
    </div>
  );
};

export default withTranslation()(AddedColaborators);
