/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CoParticipationCustomer } from 'src/Models/Business/CoParticipationCustomer';
import BenefitModal from './Modal/Benefit';
import DeleteCustomersModal from './Modal/DeleteCustomersModal';
import SegmentModal from './Modal/Segment';

export interface OptionsProps extends WithTranslation {
  customers: CoParticipationCustomer[];
}

const Options: React.FC<OptionsProps> = ({ t, customers }) => {
  const [openBenefit, setOpenBenefit] = React.useState<boolean>(false);
  const [openSegment, setOpenSegment] = React.useState<boolean>(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);

  const segmentId = customers?.[0]?.coParticipationSegment?.id;
  const someDiffSegment = customers?.some(
    (it) => it?.coParticipationSegment?.id !== segmentId,
  );

  return (
    <div className="ContributorOptions">
      <BenefitModal isOpen={openBenefit} close={setOpenBenefit} />
      <SegmentModal isOpen={openSegment} close={setOpenSegment} />
      <DeleteCustomersModal isOpen={openDelete} close={setOpenDelete} />

      {someDiffSegment ? (
        <Tooltip
          title={t('business.optionsContributorModal.disabled')}
          placement="top"
        >
          <div className="ContributorOptions__disable">
            {t('business.optionsContributorModal.addGroup')}
          </div>
        </Tooltip>
      ) : (
        <div
          className="ContributorOptions__addBenefit"
          onClick={() => setOpenBenefit(true)}
          onKeyDown={e => e.key === 'enter' && setOpenBenefit(true)}
        >
          {t('business.optionsContributorModal.addGroup')}
        </div>
      )}
      <div
        className="ContributorOptions__changeSegment"
        onClick={() => setOpenSegment(true)}
        onKeyDown={e => e.key === 'enter' && setOpenSegment(true)}
      >
        {t('business.optionsContributorModal.changeSegment')}
      </div>
      <div
        className="ContributorOptions__deleteContributor"
        onClick={() => setOpenDelete(true)}
        onKeyDown={e => e.key === 'enter' && setOpenDelete(true)}
      >

        {t('business.optionsContributorModal.delete')}
      </div>
    </div>
  );
};

export default withTranslation()(Options);
