import { ActivityPlan } from './ActivityPlan';
import { ActivitySchedule } from './ActivitySchedule';
import { IImage } from './IImage';

export interface BillingCoParticipation {
  id: number;
  subsidyValue: number;
  date?: string;
  status?: BillingCoParticipationStatus;
  billing?: {
    id: number;
    date: string;
    value: string;
    status: string;
    activitySchedules: ActivitySchedule[];
    activityPlan: ActivityPlan;
  };
  coParticipationCustomer: {
    id: number;
    email: string;
    customer: {
      id: number;
      name: string;
      user: {
        id: number;
        email: string;
      };
    };
    coParticipationSegment: {
      id: number;
      nameSegment: string;
    };
  };
  coParticipationGroup: {
    id: number;
    nameGroup: string;
  };
  coParticipationGroupActivityCategory: {
    id: number;
    activityCategory: {
      id: number;
      name: string;
    };
  };
  keikenPayInvoice?: {
    id: number;
    image: IImage;
    auxUserImages: IImage[];
  };
}

export enum BillingCoParticipationStatus {
  CREATED = 'CREATED',
  FUTURE_CHARGE = 'FUTURE_CHARGE',
  FUTURE_CHARGE_ERROR = 'FUTURE_CHARGE_ERROR',
  WAITING = 'WAITING',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  REVERTED = 'REVERTED',
}
