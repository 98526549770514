import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import './styles.scss';

export interface CreateSegment extends WithTranslation {
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
}

const CreateSegment: React.FC<CreateSegment> = ({
  t,
  name,
  placeholder,
  type,
  value,
  setValue,
}) => {
  const [showMore, setshowMore] = useState<boolean>(false);

  return (
    <div className="col-8 mt-40 CreateSegment">
      <span className="title">{t('business.flux.create_segment')}</span>
      <div className="divider" />
      <div className="customInput mt-20">
        <span className="inputTitle">{t('business.flux.segment_name')}</span>
        <br />
        <input
          type={type}
          className="no-border"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      {!showMore && (
        <span
          className="subtitle"
          onClick={() => {
            setshowMore(true);
          }}>
          + {t('business.flux.whats_segment_name')}
        </span>
      )}
      {showMore && (
        <div>
          <span
            className="subtitle"
            onClick={() => {
              setshowMore(false);
            }}>
            - {t('business.flux.whats_segment_name')}
          </span>
          <span className="message">
            {t('business.flux.message_name_segment')}
          </span>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(CreateSegment);
