import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyUser } from 'src/Models/CompanyUser';
import EditOwnerModal from './ownerEdit';


interface OwnerProps {
  onEdit?: () => void;
  companyUser: CompanyUser;
}

const OwnerData: React.FC<OwnerProps> = ({ onEdit, companyUser }) => {
  const { t } = useTranslation();
  const [editOwnerModal, setEditOwnerModal] = useState<boolean>(false);

  return (
    <>
    <div className="BusinessCard">
      <div className="BusinessCard--header">
        <h3>{t('cards.header.owner')}</h3>
        {/* <div onClick={onEdit} className={'BusinessCard--button'}>
          <Edit />
          {t('cards.edit.edit')}
        </div> */}
      </div>
      <hr className="hr" />
      <div className="BusinessCard--body ">
        <div className='BusinessCard--owner-data'>
          <h4>{companyUser?.name ?? ''}</h4>
          <p>{t('cards.header.defaultRole')}</p>
          {/* <p>{owner?.cpf || dadosFantasiaOwner.cpf}</p> */}
          <p>{companyUser?.user?.email}</p>
        </div>
      </div>
    </div>
    <EditOwnerModal
        isOpen={editOwnerModal}
        onClose={() => setEditOwnerModal(true)}
    />
    </>
  );
};

export default OwnerData;