import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import StringUtils from 'src/Utils/StringUtils';
import { convertDateTimeFromServer } from 'src/Utils/DateUtils';
import { Billing } from 'src/Models/Billing';

import arrowForwardGreen from '../../../Images/Keiken/wallet/arrowForwardGreen.svg';
import divider from '../../../Images/Keiken/wallet/Divider.svg';

export interface ContributorProps extends WithTranslation {
  billing: Billing;
  info?: boolean;
}

const Contributor: React.FC<ContributorProps> = ({ t, billing, info }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const splitDate = convertDateTimeFromServer(billing.date).split('T');
  const date = StringUtils.apiDateFormatMask(splitDate[0]);
  const hour = splitDate[1];

  const splitCostumerDate =
    billing?.customerActivityPlan?.startPeriodDate &&
    convertDateTimeFromServer(
      billing?.customerActivityPlan?.startPeriodDate,
    ).split('T');

  const costumerDate = StringUtils.apiDateFormatMask(splitCostumerDate?.[0]);
  const costumerHour = splitCostumerDate?.[1];

  const hasAtivity =
    !info && (billing?.activitySchedules?.length > 0 || billing?.activityPlan);

  const activityDescription = billing?.activityPlan
    ? '1 Plano'
    : billing?.activitySchedules.length > 0
    ? `${billing?.activitySchedules.length} atividades`
    : 'Sem atividades';

  return (
    <div style={{ borderBottom: '1px solid #f1ecec' }}>
      <div className="Contributor">
        <div id="boxDateTime">
          <div id="currentDate">{date}</div>
          <div id="currentHour">{hour}</div>
        </div>
        <div id="Contributor__left">
          <div className="Contributor__personalInfos">
            <div className="Contributor__personalInfos--name">
              {billing.customerBillingAccount.customer.name || ''}
            </div>
            <div className="Contributor__personalInfos--email">
              {billing.customerBillingAccount.customer.user.email}
            </div>
          </div>
        </div>
        <div id="activitieRow">{activityDescription}</div>
        <div id="valueRow">{StringUtils.currencyPtBr(billing?.pricePaid)}</div>
        <div id="subsidyRow">
          {StringUtils.currencyPtBr(
            billing?.billingCoParticipation?.subsidyValue ?? 0,
          )}
        </div>

        {!info ? (
          <div id="payrollDiscountRow">
            {StringUtils.currencyPtBr(billing?.value ?? 0)}
          </div>
        ) : (
          <div
            id="payrollDiscountRow"
            style={{ color: '#25B379', fontWeight: 'bold' }}>
            {StringUtils.currencyPtBr(billing.value ?? 0)}
          </div>
        )}

        {hasAtivity && (
          <div id="openInfo" onClick={() => setIsOpen(!isOpen)}>
            <img
              className={isOpen ? 'active' : ''}
              src={arrowForwardGreen}
              alt="arrowForwardGreen"
            />
          </div>
        )}
      </div>

      {isOpen && (
        <>
          {billing?.activityPlan && (
            <>
              <div id="alignRow">
                <div id="boxDateTime">
                  <div id="currentDate">{costumerDate}</div>
                  <div id="currentHour">{costumerHour}</div>
                  {billing?.activitySchedules?.length - 1 > 0 && (
                    <div id="divider">
                      <img src={divider} alt="divider" />
                    </div>
                  )}
                </div>
                <div id="activityRow">{billing?.activityPlan.name}</div>
                <div id="valueRow">
                  {StringUtils.currencyPtBr(billing.pricePaid)}
                </div>
                <div id="subsidyRow">
                  {StringUtils.currencyPtBr(
                    billing.billingCoParticipation?.subsidyValue ?? 0,
                  )}
                </div>
                <div id="payrollDiscountRow">
                  {StringUtils.currencyPtBr(billing.value)}
                </div>
              </div>
            </>
          )}
          {billing?.activitySchedules.map((activity, i) => {
            const splitActivityDate = convertDateTimeFromServer(
              activity?.date,
            ).split('T');
            const activityDate = splitActivityDate[0]
              .split('-')
              .reverse()
              .join('/');
            const activityHour = splitActivityDate[1];
            return (
              <div id="alignRow" key={i}>
                <div id="boxDateTime">
                  <div id="currentDate">{activityDate}</div>
                  <div id="currentHour">{activityHour}</div>
                  {billing?.activitySchedules?.length - 1 !== i && (
                    <div id="divider">
                      <img src={divider} alt="divider" />
                    </div>
                  )}
                </div>
                <div id="activityRow">{activity.activity.name}</div>
                <div id="valueRow">
                  {StringUtils.currencyPtBr(
                    billing.value / billing.activitySchedules.length,
                  )}
                </div>
                <div id="subsidyRow">
                  {StringUtils.currencyPtBr(
                    billing.billingCoParticipation?.subsidyValue ?? 0,
                  )}
                </div>
                <div id="payrollDiscountRow">
                  {StringUtils.currencyPtBr(billing.value ?? 0)}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default withTranslation()(Contributor);
