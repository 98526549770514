import React, { useEffect } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';

import './styles.scss';
import BenefitSelect from 'src/Components/BenefitSelect';
import BenefitCategories from 'src/Components/BenefitCategories';
import RenewalFrequency from 'src/Components/RenewalFrequency';
import BenefitValue from 'src/Components/BenefitValue';
import BenefitIndividualValues from 'src/Components/BenefitIndividualValues';
import BenefitSegments from 'src/Components/BenefitSegments/BenefitSegments';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { makeStyles } from '@material-ui/styles';
import Modal from '@material-ui/core/Modal';
import SimpleModal from 'src/Components/SimpleModal';
import CloseIcon from '@material-ui/icons/Close';
import BusinessGroupBenefits from 'src/Services/Api/BusinessGroupBenefits';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import RenewingPeriod from 'src/Models/Enums/RenewingPeriod';
import {
  GroupActivityCategory,
  CoParticipationGroup,
} from 'src/Models/Business/CoParticipationGroup';
import ToastService from 'src/Services/ToastService';
import BoxComponentCurrencyTextField from 'src/Components/BoxComponentCurrencyTextField';

export type BusinessNewBenefit = WithTranslation;

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: 482,
    backgroundColor: '#FFF',
    padding: 15,
    borderRadius: 15,
    margin: 'auto',
    top: '40%',
    left: '36%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  close: {
    cursor: 'pointer',
  },
});

const BusinessNewBenefit: React.FC<BusinessNewBenefit> = ({ t }) => {
  // @ts-ignore
  const classes = useStyles();
  const { id } = useParams() as { id: string };
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [benefitSegments, setBenefitSegments] = React.useState<any[]>([]);
  const [benefitName, setBenefitName] = React.useState<string>();
  const [benefitValue, setBenefitValue] = React.useState<number>();
  const [annualLimit, setAnnualLimit] = React.useState<number>();
  const [maxSpendingPerPurchase, setMaxSpendingPerPurchase] =
    React.useState<number>();
  const [selectedCategories, setSelectedCategories] = React.useState<
    GroupActivityCategory[]
  >([]);
  const [period, setPeriod] = React.useState<RenewingPeriod>(
    RenewingPeriod.MONTH_PERIOD,
  );

  const history = useHistory();

  useEffect(() => {
    getBenefit();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getBenefit = async () => {
    const benefit: CoParticipationGroup = (
      (await BusinessGroupBenefits.getOneGroupBenefit(parseInt(id))) as any
    ).data;
    setBenefitName(benefit?.nameGroup);
    setBenefitValue(benefit?.maxSpendingGroup ?? 0);
    setBenefitSegments(benefit.segments);
    setSelectedCategories(benefit.activityCategories);
    setAnnualLimit(benefit.annualLimit);
    setMaxSpendingPerPurchase(benefit.maxSpendingPerPurchase);
    setLoading(false);
  };

  const deleteBenefit = async () => {
    if (loading == true) {
      return;
    }
    setLoading(true);
    const result = await BusinessGroupBenefits.deleteOneGroupBenefit(
      parseInt(id),
    );
    setLoading(false);
    if (result.status == 200) {
      ToastService.success('Excluido com sucesso!');
      setTimeout(() => {
        history.replace('/benefits/benefits');
      }, 500);
    } else {
      ToastService.error('Falha ao excluir o benefício.');
    }
  };

  const updateBenefit = async () => {
    if (loading == true) {
      return;
    }
    setLoading(true);

    const result = await BusinessGroupBenefits.editOneGroupBenefit({
      id: parseInt(id),
      maxSpendingGroup: benefitValue ?? 0,
      nameGroup: benefitName,
      renewingPeriod: period,
      segments: benefitSegments.map((segment) => ({ id: segment.id })),
      activityCategories: selectedCategories,
      annualLimit,
      maxSpendingPerPurchase,
    });

    setLoading(false);
    if (result.status == 200) {
      ToastService.success('Benefício editado com sucesso!');
      setTimeout(() => {
        history.replace('/benefits/benefits');
      }, 500);
    } else if (
      result.status == 400 &&
      result.data?.code ==
        'error.category.max.spending.must.be.lower.or.equal.to.max.spending.group'
    ) {
      ToastService.error(
        'Erro ao editar benefício. O valor do benefício precisa ser maior que valor teto das categorias.',
      );
    } else if (
      result.status == 400 &&
      result.data?.code ==
        'error.cant.get.this.groups.because.duplicate.categories'
    ) {
      ToastService.error(
        'Falha ao vincular colaborador, pois existe benefício com categoria duplicada.',
      );
    } else {
      ToastService.error(result.data.detail ?? "Erro ao editar benefício.");
    }
  };

  const handleCount = () => {
    return benefitSegments.reduce(
      (prev, curr) => prev + (curr?.customersSize ?? curr?.customers?.length),
      0,
    );
  };

  function handleOnChangeInvidualValue(id: number, value: number) {
    setSelectedCategories((prev) =>
      prev.map((it) => ({
        ...it,
        maxSpendingCategory:
          it.activityCategory.id == id ? value : it.maxSpendingCategory,
      })),
    );
  }

  const modalBody = (
    <div className={classes.paper}>
      <div className="modalEditBusiness">
        <div className={classes.header}>
          <p id="simple-modal-title" className="modalTitle">
            {t('business.flux.deleteBenefit')}
          </p>
          <CloseIcon className={classes.close} onClick={handleClose} />
        </div>
        <div className="underline" />

        <p id="simple-modal-description" className="textModalDelete">
          {t('business.flux.modalText', {
            count: handleCount(),
          })}
        </p>

        <div className="footerModal">
          <span className="cancel" onClick={handleClose}>
            {t('business.flux.cancel')}
          </span>
          <span
            className="buttonModal"
            onClick={async () => await deleteBenefit()}
          >
            {t('business.flux.deleteBenefit')}
          </span>
        </div>
      </div>
      <SimpleModal />
    </div>
  );
  return loading ? (
    <></>
  ) : (
    <div className="businessNewBenefit">
      <BaseHeaderBenefits
        step="create"
        color="green"
        title="Editar Benefício"
        to="/benefits/benefits"
      />
      <div className="mt-100">
        <BenefitSelect
          placeholder={t('business.flux.benefit_name')}
          name="benefit"
          type="text"
          title={t('business.flux.benefit_name')}
          question={t('business.flux.subtitle')}
          value={benefitName}
          setValue={setBenefitName}
        />
        <div className="mt-30">
          <BenefitSegments
            segments={benefitSegments}
            onChange={(segments) => setBenefitSegments(segments)}
          />
        </div>
        <BenefitCategories
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          maxSpendingGroup={benefitValue}
        />
        <div className="col-8 flex">
          <div className="col-6 pl-0">
            {/* <RenewalFrequency period={period} setPeriod={setPeriod} /> */}
            <BenefitValue value={benefitValue} setValue={setBenefitValue} />
          </div>
          <div className="col-6 pr-0" />
        </div>
        <BenefitIndividualValues
          selectedActivityCategories={selectedCategories}
          onChangeValue={handleOnChangeInvidualValue}
          maxValue={benefitValue}
        />
        <div className="col-8 flex">
          <div className="col-6 pl-0">
            <BoxComponentCurrencyTextField
              value={maxSpendingPerPurchase}
              onChange={setMaxSpendingPerPurchase}
              title={t('business.group.perPurchase.title')}
              placeholder={t('business.group.perPurchase.placeholder')}
              infoQuestion={t('business.group.perPurchase.infoQuestion')}
              infoAnswer={t('business.group.perPurchase.infoAnswer')}
            />
          </div>
          <div className="col-6 pr-0">
            <BoxComponentCurrencyTextField
              value={annualLimit}
              onChange={setAnnualLimit}
              title={t('business.group.annualLimit.title')}
              placeholder={t('business.group.annualLimit.placeholder')}
              infoQuestion={t('business.group.annualLimit.infoQuestion')}
              infoAnswer={t('business.group.annualLimit.infoAnswer')}
            />
          </div>
        </div>
        <br />
        <div className="delete-benefit">
          <DeleteOutlineOutlinedIcon />
          <span className="ml-10" onClick={handleOpen}>
            {t('business.flux.deleteBenefit')}
          </span>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </div>
      <BaseFooterBenefits
        disabled={loading}
        text="Salvar alterações"
        hasBackButton="true"
        textBack="Cancelar"
        saveFunc={updateBenefit}
        backTo='/benefits/benefits'
      />
    </div>
  );
};

export default withTranslation()(BusinessNewBenefit);
