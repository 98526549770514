import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ModalProps } from 'reactstrap';
import GradientButtonSC from 'src/Components/GradientButtonSC';
import { Close } from "@material-ui/icons"
import {
  SimpleModalBody,
  SimpleModalHeader,
  SimpleModalFooter,
  SimpleModalContainer,
} from './styles';

export interface SimpleModalProps extends ModalProps, WithTranslation {
  onCancel: () => void;
  onConfirm: () => void;
  labelConfirm: string;
  labelCancel: string;
}

const SimpleModal: React.FC<SimpleModalProps> = (props) => {
  const {
    t,
    confirmEnabled,
    backdrop,
    keyboard,
    isOpen,
    onCancel,
    onConfirm,
    title,
    labelCancel,
    labelConfirm,
    children,
    onToggle,
  } = props;

  function handleConfirm() {
    onConfirm && onConfirm();
  }

  function handleCancel() {
    onCancel && onCancel();
  }

  function handleToggle() {
    onToggle && onToggle();
  }

  const confirmDisabled = confirmEnabled != null ? !confirmEnabled : false;
  return (
    <SimpleModalContainer
      modalClassName="simple-modal-content"
      isOpen={isOpen}
      toggle={handleToggle}
      backdrop={backdrop ?? 'static'}
      keyboard={keyboard ?? true}>
      <SimpleModalHeader>
        {title}
        <Close fontSize='large' onClick={handleCancel}/>  
      </SimpleModalHeader>
      <SimpleModalBody>{children}</SimpleModalBody>
      <SimpleModalFooter>
        <GradientButtonSC
          isSecundary
          onClick={handleCancel}
          title={labelCancel}
        />
        <GradientButtonSC
          buttonDisabled={confirmDisabled}
          onClick={handleConfirm}
          title={labelConfirm}
        />
      </SimpleModalFooter>
    </SimpleModalContainer>
  );
};

export default withTranslation()(SimpleModal);
