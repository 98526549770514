import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import BenefitCategoriesInputValue from './value';
import './styles.scss';
import { GroupActivityCategory } from 'src/Models/Business/CoParticipationGroup';

export interface BenefitIndividualValues extends WithTranslation {
  selectedActivityCategories?: GroupActivityCategory[];
  maxValue?: number;
  onChangeValue?: (index, value) => void;
}

const BenefitIndividualValues: React.FC<BenefitIndividualValues> = ({
  t,
  selectedActivityCategories,
  onChangeValue,
  maxValue,
}) => {
  const [Expand, setExpand] = useState(true);
  const [showMore, setshowMore] = useState<boolean>(false);

  return (
    <div className="col-8 mt-40 BenefitIndividualValues">
      <span className="title">{t('business.flux.value_per_category')}</span>
      {!Expand && (
        <span className="headerBtn item mb-20" onClick={() => setExpand(true)}>
          <ExpandMoreIcon />
        </span>
      )}
      {Expand && (
        <span className="headerBtn item " onClick={() => setExpand(false)}>
          <ExpandLessIcon />
        </span>
      )}
      {!Expand && <div className="dividerWhite" />}
      {Expand && (
        <div>
          <div className="divider" />
          <div className="flex" style={{ flexWrap: 'wrap' }}>
            {selectedActivityCategories?.map((category, index) => {
              return (
                <BenefitCategoriesInputValue
                  key={`benefit-categories-input-value-${index}`}
                  groupActivityCategory={category}
                  onChangeValue={onChangeValue}
                  maxValue={maxValue}
                />
              );
            })}
          </div>
          {!showMore && (
            <span className="subtitle" onClick={() => setshowMore(true)}>
              + {t('business.flux.individual_question')}
            </span>
          )}
          {showMore && (
            <div>
              <span className="subtitle" onClick={() => setshowMore(false)}>
                - {t('business.flux.individual_question')}
              </span>
              <span className="message">
                {t('business.flux.message_category_max_spending')}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(BenefitIndividualValues);
