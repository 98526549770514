import React from 'react';

import './styles.scss';

import times from '../../Images/Keiken/Business/Button.svg';
import create from '../../Images/Keiken/Business/Stepper.svg';
import add from '../../Images/Keiken/Business/create.png';

type headerProps = {
  step?: string;
  color?: string;
  title?: string;
  to?: string;
};

const BaseHeaderBenefits: React.FC<headerProps> = ({
  step,
  color,
  title,
  to,
}) => {
  return (
    <div>
      {color !== 'green' && (
        <div className="headerBenefits">
          <button
            className="button"
            onClick={() => {
              window.location.href = to;
            }}>
            <img src={times} alt="exit" />
          </button>
          {step == 'create' && <img src={create} alt="step" />}
          {step == 'add' && <img src={add} alt="step" />}
        </div>
      )}
      {color === 'green' && (
        <div className="headerGreen">
          <button
            className="button"
            onClick={() => {
              window.location.href = to;
            }}>
            <img src={times} alt="exit" />
          </button>
          <span className="span">{title}</span>
        </div>
      )}
    </div>
  );
};

export default BaseHeaderBenefits;
