import { TextField } from '@material-ui/core';
import React, {ChangeEvent, useState} from 'react';
import { useTranslation } from 'react-i18next';
import SimpleModal from 'src/Components/SimpleModal';

interface ChangePasswordProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (oldPass:string, newPass: string, confirmNewPass: string) => void;
}

const ChangePassword = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [oldPass, setOldPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [confirmNewPass, setConfirmNewPass] = useState<string>('');
  const {t} = useTranslation();
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'oldPass':
        setOldPass(value);
        break;
      case 'newPass':
        setNewPass(value);
        break;
      case 'confirmNewPass':
        setConfirmNewPass(value);
        break;
    }
  };

  const handleConfirm = () => {
    onConfirm(oldPass, newPass, confirmNewPass);
    onClose();
  }

  return (
    <SimpleModal
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={handleConfirm}
      title={t('cards.edit.pass')}
      labelConfirm={t('modalOptions.save')}
      labelCancel={t('modalOptions.cancel')}
    >
      <div className="BusinessCard--body">
        <div className="passwords-container">
          <div className='password-input'>
            <TextField
              type="password"
              label={t('changePass.old')}
              name="oldPass"
              className="BusinessCard--input"
              onChange={handleChange}
            />
          </div>
          <div className='password-input'>
            <TextField
              type="password"
              name='newPass'
              label={t('changePass.new')}
              className="BusinessCard--input"
              onChange={handleChange}
            />
          </div>
          <div className='password-input'>
            <TextField
              type="password"
              label={t('changePass.confirm')}
              name="confirmNewPass"
              className="BusinessCard--input"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

export default ChangePassword;