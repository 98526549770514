import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Modal, ModalHeader } from 'reactstrap';
import { ReactComponent as Close } from '../../../Images/Icons/close-gray.svg';
import Infos from './Infos';
import Pix from './Pix';
import Success from './Success';
import Ticket from './Ticket';

export interface WalletModalProps extends WithTranslation {
  toggle: (main: string, step?: string) => void;
  modal: string;
  companyWallet: string;
}

const WalletModal: React.FC<WalletModalProps> = ({
  t,
  toggle,
  modal,
  companyWallet,
}) => {
  return (
    <>
      <Modal
        isOpen={modal.includes(companyWallet)}
        className={'WalletModal'}
        centered>
        <ModalHeader className="WalletModal__header">
          <div className="WalletModal__header--title">
            {companyWallet === 'fund' ? 'Adicionar saldo' : 'Pagar saldo'}
          </div>
          <Close
            onClick={() => toggle('')}
            className="WalletModal__header--close_icon"
          />
        </ModalHeader>
        {modal.includes('infos') ? (
          <Infos toggle={toggle} companyWallet={companyWallet} />
        ) : modal.includes('ticket') ? (
          <Ticket toggle={toggle} companyWallet={companyWallet}></Ticket>
        ) : modal.includes('pix') ? (
          <Pix toggle={toggle} companyWallet={companyWallet}></Pix>
        ) : modal.includes('success') ? (
          <Success toggle={toggle} companyWallet={companyWallet}></Success>
        ) : (
          <Infos toggle={toggle} companyWallet={companyWallet} />
        )}
      </Modal>
    </>
  );
};

export default withTranslation()(WalletModal);
