import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './styles.scss';
import { ReactComponent as Check } from '../../Images/Icons/default-check.svg';

export interface CustomBoxCheckBoxProps extends WithTranslation {
  onClick?: () => void;
  isChecked?: boolean;
}

const CustomBoxCheckBox: React.FC<CustomBoxCheckBoxProps> = ({
  t,
  onClick,
  isChecked,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(isChecked);
    
  }, [isChecked]);

  return (
    <div
      className="CustomBoxCheckBox"
      onClick={() => {
        setChecked(!checked);
        onClick();
      }}
    >
      <div className={`CustomBoxCheckBox--check ${checked}`}>
        {checked && <Check />}
      </div>
    </div>
  );
};

export default withTranslation()(CustomBoxCheckBox);
