import { api } from '.';
import { PageableRequest, PageableResponse } from 'src/Models/pageable';

class CompanyReportApi {
  static getCompanyReports = (companyId: number, page: PageableRequest) => {
    return api.get<PageableResponse<ICompanyReport>>(
      `/companies/${companyId}/company-reports`,
      {
        params: page,
      },
    );
  };
}

export default CompanyReportApi;

export interface ICompanyReport {
  id: number;
  url: string;
  reportDate: string;
  status?: 'ENABLE' | 'DISABLE';
  company?: {
    id: number;
  };
}
