import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import { IConferenceState } from './state';
import { ConferenceSessionTypes } from './types';
import { ActionType } from 'typesafe-actions';
import * as conferenceAction from "./actions"

export type ConferenceAction = ActionType<typeof conferenceAction>

export const initialState: IConferenceState = {
  checkByCustomerActivityTokenStatus: HttpRequestStatus.NOOP,
  checkCustomerTokenStatus: HttpRequestStatus.NOOP,
  forceDisconnectStatus: HttpRequestStatus.NOOP,
  data: {}
};
const reducer: Reducer<IConferenceState> = (state: IConferenceState = initialState, action: ConferenceAction) => {
  switch (action.type) {
    case ConferenceSessionTypes.CHECK_CUSTOMER_TOKEN:
      return { ...state, checkCustomerTokenStatus: HttpRequestStatus.ONGOING };
    case ConferenceSessionTypes.CHECK_CUSTOMER_TOKEN_SUCCESS:
      return { ...state, checkCustomerTokenStatus: HttpRequestStatus.SUCCESS };
    case ConferenceSessionTypes.CHECK_CUSTOMER_TOKEN_ERROR:
      return { ...state, checkCustomerTokenStatus: HttpRequestStatus.ERROR };

    case ConferenceSessionTypes.CHECK_BY_CUSTOMER_ACTIVITY_TOKEN:
      return { ...state, checkByCustomerActivityTokenStatus: HttpRequestStatus.ONGOING };
    case ConferenceSessionTypes.CHECK_BY_CUSTOMER_ACTIVITY_TOKEN_SUCCESS:
      return { ...state, checkByCustomerActivityTokenStatus: HttpRequestStatus.SUCCESS };
    case ConferenceSessionTypes.CHECK_BY_CUSTOMER_ACTIVITY_TOKEN_ERROR:
      return { ...state, checkByCustomerActivityTokenStatus: HttpRequestStatus.ERROR };

    case ConferenceSessionTypes.FORCE_DISCONNECT_CONFERENCE:
      return { ...state, forceDisconnectStatus: HttpRequestStatus.ONGOING };
    case ConferenceSessionTypes.FORCE_DISCONNECT_CONFERENCE_SUCCESS:
      return { ...state, forceDisconnectStatus: HttpRequestStatus.SUCCESS };
    case ConferenceSessionTypes.FORCE_DISCONNECT_CONFERENCE_ERROR:
      return { ...state, forceDisconnectStatus: HttpRequestStatus.ERROR };

    case ConferenceSessionTypes.RESET:
      return { ...initialState };
  }
  return state;
};

export { reducer as conferenceReducer };
