import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import CompanyWalletService from 'src/Services/CompanyWalletServices';
import { Billing } from 'src/Models/Billing';
import {
  CompanyWalletApi,
  FilterParams,
} from 'src/Services/Api/CompanyWalletApi';
import Pagination from '../Pagination';
import Contributor from './Contributor';
import Settings from './Settings';
import './styles.scss';
import moment from 'moment';
import { RotateCircleLoading } from 'react-loadingg';

export interface CalendarDate {
  startDate: string;
  endDate: string;
}

export type PayrollDiscountProps = WithTranslation;

const PayrollDiscount: React.FC<PayrollDiscountProps> = ({ t }) => {
  const [searchName, setSearchName] = useState<string>();
  const [search, setSearch] = useState<boolean>(false);
  const [sortByAsc, setSortByAsc] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [allPages, setAllPages] = useState<number>(1);

  const [billingList, setBillingList] = useState<Billing[]>([]);
  const [formatedDate, setFormatedDate] = useState<CalendarDate>({
    startDate: moment().startOf('month').format('DD/MM/YYYY'),
    endDate: moment().endOf('month').format('DD/MM/YYYY'),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const getContribuitorsList = async () => {
    setIsLoading(true);
    const startDateToSend = moment(
      formatedDate?.startDate,
      'DD/MM/YYYY',
    ).toISOString();
    const endDateToSend = moment(formatedDate?.endDate, 'DD/MM/YYYY')
      .add(1, 'day')
      .add(-1, 's')
      .toISOString();
    const params: FilterParams = {
      sort: sortByAsc,
      size: 10,
      page: page - 1,
      startDate: startDateToSend,
      endDate: endDateToSend,
      search: searchName,
    };

    CompanyWalletService.walletDetails(params)
      .then((result) => {
        setBillingList(result.data.content);
        setAllPages(result.data.totalPages);
        setIsLoading(false);
      })
      .catch(() => {
        setError(
          'Ocorreu um erro nos nossos servidores, tente novamente mais tarde...',
        );
        setIsLoading(false);
      });

    setIsLoading(false);
  };
  useEffect(() => {
    getContribuitorsList();
  }, [page, sortByAsc, formatedDate]);

  const handleDownload = async () => {
    setIsDownloading(true);
    const startDateToSend = moment(
      formatedDate?.startDate,
      'DD/MM/YYYY',
    ).toISOString();
    const endDateToSend = moment(formatedDate?.endDate, 'DD/MM/YYYY')
      .add(1, 'day')
      .add(-1, 's')
      .toISOString();
    const params: FilterParams = {
      sort: sortByAsc,
      size: 10,
      page: page - 1,
      startDate: startDateToSend,
      endDate: endDateToSend,
      search: searchName,
    };

    await CompanyWalletApi.getWalletDetailsInExcel(params);
    setIsDownloading(false);
  };

  return (
    <Row style={{ marginBottom: '40px' }}>
      <Container className="contributors">
        <Settings
          sort={sortByAsc}
          setSort={setSortByAsc}
          onSubmit={getContribuitorsList}
          searchName={searchName}
          setSearchName={setSearchName}
          search={search}
          setSearch={setSearch}
          setFormatedDate={setFormatedDate}
          formatedDate={formatedDate}
          handleDownload={handleDownload}
          isDownloading={isDownloading}
        />
        <Row style={{ borderBottom: '1px solid #f1ecec' }}>
          <Col id="titleTable">
            <div id="contribuitorsDate">{t('header.account.date')}</div>
            <div id="contribuitorsName">{t('header.account.colaborator')}</div>
            <div id="contribuitorsActivities">
              {t('header.account.activity')}
            </div>
            <div id="contribuitorsValues">{t('header.account.value')}</div>
            <div id="contribuitorsSubsidy">{t('header.account.subsidy')}</div>
            <div id="contribuitorsPayrollDiscount">
              {t('header.account.payrollDiscount')}
            </div>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col>
            {isLoading ? (
              <div style={{ width: '100%', height: '100%' }}>
                <RotateCircleLoading
                  size="large"
                  style={{
                    position: 'static',
                    marginInline: 'auto',
                    marginBlock: '100px',
                  }}
                />
              </div>
            ) : billingList.length > 0 ? (
              billingList.map((contributor, idx) => (
                <Contributor billing={contributor} key={idx} />
              ))
            ) : (
              <div id="no-data">
                <p style={{ color: error && 'red' }}>
                  {error ? error : t('wallet.extract.noData')}
                </p>
              </div>
            )}
            <Pagination page={page} setPage={setPage} allPages={allPages} />
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default withTranslation()(PayrollDiscount);
