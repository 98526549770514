import classNames from 'classnames';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BusinessAddColaborators from 'src/Pages/Business/BusinessAddColaborators';
import BusinessBenefits from 'src/Pages/Business/BusinessBenefits';
import BusinessCreateBenefit from 'src/Pages/Business/BusinessCreateBenefit';
import BusinessEditBenefit from 'src/Pages/Business/BusinessEditBenefit';
import BusinessEditContributor from 'src/Pages/Business/BusinessEditContributor';
import BusinessEditSegments from 'src/Pages/Business/BusinessEditSegments';
import BusinessForgotPassword from 'src/Pages/Business/BusinessForgotPassword';
import BusinessLogin from 'src/Pages/Business/BusinessLogin';
import BusinessNewBenefit from 'src/Pages/Business/BusinessNewBenefit';
import BusinessNewSegments from 'src/Pages/Business/BusinessNewSegments';
import BusinessOverview from 'src/Pages/Business/BusinessOverview';
import BusinessProfile from 'src/Pages/Business/BusinessProfile';
import BusinessRegister from 'src/Pages/Business/BusinessRegister';
import BusinessWallet from 'src/Pages/Business/BusinessWallet';
import Login from 'src/Pages/Login';
import ChatPage from 'src/Pages/Business/Chat';
import Footer from '../Components/Footer';
import Logout from '../Pages/Logout';
import PageNotFound from './Components/Error/PageNotFound';
import PrivateRoutes, { AccessLevel } from './Components/PrivateRoutes';

const AppRoutes = ({ match, isAuthenticated }) => {
  const appRoutesClassName = isAuthenticated ? 'has-header' : '';
  return (
    <div className={classNames([appRoutesClassName, 'App-routes-wrapper'])}>
      <div className="App-routes-wrap">
        <Switch>
          <Redirect exact from="/" to="/register" />
          <Route exact path="/login" component={Login} />
          <Route path="/register" component={BusinessRegister} />
          <PrivateRoutes
            path="/overview"
            component={BusinessOverview}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/benefits"
            component={BusinessBenefits}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/wallet"
            component={BusinessWallet}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/profile"
            component={BusinessProfile}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/forgot-password"
            component={BusinessForgotPassword}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/login"
            component={BusinessLogin}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/register"
            component={BusinessRegister}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/create-benefit"
            component={BusinessCreateBenefit}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/add-colaborators"
            component={BusinessAddColaborators}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/contributors/:id"
            component={BusinessEditContributor}
            accessLevel={AccessLevel.COMPANY}
            exact
          />

          <PrivateRoutes
            path="/new-benefit"
            component={BusinessNewBenefit}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/edit-benefit/:id"
            component={BusinessEditBenefit}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/new-segment"
            component={BusinessNewSegments}
            accessLevel={AccessLevel.COMPANY}
          />
          <PrivateRoutes
            path="/edit-segment/:id"
            component={BusinessEditSegments}
            accessLevel={AccessLevel.COMPANY}
          />
          {/* <PrivateRoutes
            path="/chat"
            component={ChatPage}
            accessLevel={AccessLevel.COMPANY}
          /> */}
          <Route exact path="/logout" component={Logout} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
      {isAuthenticated && <Footer />}
    </div>
  );
};

export const DEFAULT_ROUTE_BUSINESS = '/overview';

export default AppRoutes;
