export default {
    "cor1": "#a6d1a9",
    "cor2": "#f6b3b3",
    "cor3": "#b7b6d1",
    "cor4": "#f0d1b3",
    "cor5": "#cfd1a6",
    "cor6": "#d6a6c9",
    "cor7": "#a6c3d6",
    "cor8": "#b1d6c9",
    "cor9": "#c9a6b1",
    "cor10": "#d1b1a6"
}