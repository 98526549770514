import { AxiosResponse } from 'axios';
import { PayrollDicountType } from 'src/Models/payrollDiscountType';
import { api } from '.';

class PayrollDicountApi {
  static updatePayroll = (
    contributors: PayrollDicountType[],
  ): Promise<AxiosResponse<PayrollDicountType[]>> => {
    return api.put<PayrollDicountType[]>(
      `/co-participation-customers/payroll-discount`,
      contributors,
    );
  };

  static getContributors = (params: any): Promise<AxiosResponse<any>> => {
    return api.get<any>(`/api/billings/wallet-details?${params}`);
  };
}

export default PayrollDicountApi;
