import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ModalProps } from 'reactstrap';
import { ErrorOutline } from "@material-ui/icons";
import {
  SimpleModalBody,
  SimpleModalHeader,
  SimpleModalFooter,
  SimpleModalContainer,
  CustomButton,
} from './styles';
import { AUTHORITIES, CUSTOMER_URL, PARTNER_URL } from 'src/Config/Constants';

export interface WrongLoginModalProps extends ModalProps, WithTranslation {
  onCancel: () => void;
  userRole?: AUTHORITIES;
}

const WrongLoginModal: React.FC<WrongLoginModalProps> = (props) => {
  const {
    t,
    backdrop,
    keyboard,
    isOpen,
    onCancel,
    userRole
  } = props;

  function handleCancel() {
    onCancel && onCancel();
  }

  const isCustomer = userRole === AUTHORITIES.CUSTOMER;
  const siteUrl = userRole === AUTHORITIES.CUSTOMER ? CUSTOMER_URL : PARTNER_URL;

  return (
    <SimpleModalContainer
      modalClassName="simple-modal-content"
      isOpen={isOpen}
      toggle={handleCancel}
      backdrop={backdrop ?? 'static'}
      keyboard={keyboard ?? true}
    >
      <SimpleModalHeader> 
        {t('login.modalError.header')}
        <ErrorOutline/>
      </SimpleModalHeader>
      <SimpleModalBody>
        <p>
          {t('login.modalError.p1')}
        </p>
        <p>
          {t('login.modalError.p2')}
        </p>
        <p>
          {t('login.modalError.p3')}
        </p>
      </SimpleModalBody>
      <SimpleModalFooter>
        <CustomButton onClick={handleCancel}>
          {t('login.modalError.Cancel')}
        </CustomButton>
        <CustomButton>
          <a href={siteUrl}>
            {isCustomer ? t('login.modalError.customerSite') : t('login.modalError.partnerSite')}
          </a>
        </CustomButton>
      </SimpleModalFooter>
    </SimpleModalContainer>
  );
};

export default withTranslation()(WrongLoginModal);