import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import "src/Themes/Constants/colors.scss"
import styled, { css } from 'styled-components';

export const SimpleModalContainer = styled(Modal)`
  width: 90vw;
  max-width: 600px;
  margin: 10rem auto;
  .modal-content {
    border-radius: 12px;
  }
`;

export const SimpleModalHeader = styled(ModalHeader)`
  margin-top: 10px;
  font-weight: 400;
  font-family: MullerBold;
`;

export const SimpleModalBody = styled(ModalBody)`
  font-weight: 400;
  font-family: Muller;
  text-align: center;
  padding-inline: 20px;

  p {
    text-align: justify;
  }
`;

export const SimpleModalFooter = styled(ModalFooter)`
  justify-content: space-between;
`;

export const CustomButton = styled.button`
  padding: 8px 12px;
  border-radius: 30px;
  cursor: pointer;
  background-color: white;
  border: 2px solid #25b379;
  color: #25b379;
  font-weight: 800;
  transition: all 200ms ease-in-out;

  a {
    text-decoration: none;
    color: #25b379;
  }
  
  &:hover {
    background-color: #25b379;
    color: white;

    a {
      color: white;
    }
  }
`;