import React from 'react';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons';
import FirstPageOutlinedIcon from '@material-ui/icons/FirstPageOutlined';
import './styles.scss';

interface PageNumberElement {
  pageReference?: number;
  type: 'page' | 'dots';
}

interface Props {
  page: number;
  allPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
const Pagination: React.FC<Props> = ({ page, allPages, setPage }) => {
  const [pagination, setPagination] = React.useState<PageNumberElement[]>([]);
  const activePage = (forPage: number) => {
    if (page === forPage) return 'active';
    return '';
  };
  
  const allPagesSmaller7 = () => {
    let pages = [];
    for (let forPage = 1; forPage <= allPages; forPage++) {
      pages.push({
        pageReference: forPage,
        type: 'page',
      });
    }
    setPagination(pages);
  };

  const pageReachAll7 = () => {
    let pages = [];
    for (let forPage = allPages - 6; forPage <= allPages; forPage++) {
      pages.push({
        pageReference: forPage,
        type: 'page',
      });
    }
    setPagination(pages);
  };

  const defaultPagination = () => {
    let pages = [];
    for (let forPage = 0; forPage < 6; forPage++) {
      if (forPage < 3) {
        pages.push({
          pageReference: page + forPage,
          type: 'page',
        });
      } else if (forPage === 3) {
        pages.push({type: 'dots'})
      } else if (forPage === 4) {
        pages.push({
          pageReference: allPages - 1,
          type: 'page',
        });
      } else {
        pages.push({
          pageReference: allPages,
          type: 'page',
        });
      }
    }
    setPagination(pages);
  };

  const handlePagination = () => {
    if (allPages < 7) {
      return allPagesSmaller7();
    } else if (page + 6 > allPages) {
      return pageReachAll7();
    } else {
      return defaultPagination();
    }
  };

  React.useEffect(() => {
    handlePagination();
  }, [allPages, page]);

  return (
    <div className="pagination center mb-10">
      {page > 1 && (
        <>
          <a
            onClick={() => {
              setPage(1);
            }}>
            <FirstPageOutlinedIcon />
          </a>
          <a
            onClick={() => {
              setPage(page - 1);
            }}>
            <ChevronLeftOutlined />
          </a>
        </>
      )}
      {pagination.map((item) => {
        const { pageReference: forPage, type } = item;
        if(type === 'dots'){
          return (
            <a key="dots" onClick={(e) => {e.preventDefault()}} className="dots">...</a>
          )
        } else {
          return (
            <a
              key={forPage}
              style={{ cursor: 'pointer' }}
              className={activePage(forPage)}
              onClick={() => {
                setPage(forPage);
              }}
            >
              {forPage}
            </a>
          )
        }
      })}
      {page < allPages && (
        <a
          onClick={() => {
            setPage(page + 1);
          }}>
          <ChevronRightOutlined />
        </a>
      )}
    </div>
  );
};

export default Pagination;
