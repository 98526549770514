import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { logoutRequest } from '../../../Stores/authentication/actions';
import Images from '../../../Themes/Constants/Images';
import AuthUtils from '../../../Utils/AuthUtils';

interface IAccessDeniedProps
  extends DispatchProps,
    WithTranslation,
    RouteComponentProps {
  // TODO
}

class AccessDenied extends React.Component<IAccessDeniedProps> {
  $defaultPath: string;
  constructor(props) {
    super(props);
    this.$defaultPath = 'accessDenied';
  }

  redirectToMainPath = () => {
    this.props.logout();
    this.props.history.replace(AuthUtils.getMainPath());
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Row className={'base-full-height'}>
          <Col md={12} className={'base-centered-column'}>
            <div className={'base-access-denied-wrapper'}>
              <img
                src={Images.icons.accessDenied}
                onClick={this.redirectToMainPath}
                alt={''}
              />
              <Alert color={'danger'}>
                <span>{t(`${this.$defaultPath}.title`)}</span>
              </Alert>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  logout: logoutRequest,
};
type DispatchProps = typeof mapDispatchToProps;
export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(),
)(withRouter(AccessDenied)) as React.ComponentType<any>;
