import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as Wifi } from '../../Images/Icons/wifi.svg';
import './styles.scss';
import { Popover } from '@material-ui/core';

export interface OrderByProps extends WithTranslation {
  type: string;
  first?: boolean;
  setFirst?: React.Dispatch<React.SetStateAction<boolean>>;
  second?: boolean;
  setSecond?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderBy: React.FC<OrderByProps> = ({
  t,
  type,
  first,
  setFirst,
  second,
  setSecond,
}) => {
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <div className="OrderBy">
        <div
          className="align-center pointer OrderBy--row"
          onClick={(event) => {
            setDropDown(true);
            setAnchorEl(event.currentTarget);
          }}>
          <Wifi className="OrderBy--icon" />
          <div className="OrderBy--text">{t("filter.order.label")}</div>
        </div>

        <Popover
          anchorEl={anchorEl}
          open={dropDown}
          onClose={() => setDropDown(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <div className="OrderBy__dropdown__card">
            {type === 'contributor' ? (
              <>
                <div
                  className={`OrderBy__dropdown__card--text ${first}`}
                  onClick={() => setFirst(!first)}>
                  {t("filter.order.alphab")}
                </div>
                <div
                  className={`OrderBy__dropdown__card--text ${second}`}
                  onClick={() => setSecond(!second)}>
                  {t("filter.order.newer")}
                </div>
              </>
            ) : type === 'extract' ? (
              <>
                <div
                  className={`OrderBy__dropdown__card--text ${first}`}
                  onClick={() => setFirst(true)}>
                  {t("filter.order.recent")}
                </div>
                <div
                  className={`OrderBy__dropdown__card--text ${second}`}
                  onClick={() => setFirst(false)}>
                  {t("filter.order.older")}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Popover>
      </div>
    </>
  );
};

export default withTranslation()(OrderBy);
