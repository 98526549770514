import React from 'react';

import './styles.scss';
import meditation from '../../Images/Keiken/Business/meditation.svg';
import connections from '../../Images/Keiken/Business/connections.svg';
import pocket from '../../Images/Keiken/Business/pocket.svg';
import InputMask from 'react-input-mask';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { STATES } from '../../Utils/BrazilStates';
import { Link } from 'react-router-dom';
import BusinessRegisterUserForm from './BusinessRegisterUserForm';
import classnames from 'classnames';
import _ from 'lodash';
import { api } from 'src/Services/Api';
import { toast } from 'react-toastify';
import CompanyService from 'src/Services/CompanyService';

export interface BusinessRegisterForm extends WithTranslation {}

export function classnameField(error) {
  return classnames(['customInput mt-20', error != null && 'customInputError']);
}
export interface BusinessFormValues {
  companyName: string;
  companyCnpj: string;
  userName: string;
  phone: string;
  email: string;
}
const BusinessRegisterForm: React.FC<BusinessRegisterForm> = ({ t }) => {
  const bussinessRegisterFormSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(1, t('bussiness.register.error.default'))
      .required('Required'),
    companyCnpj: Yup.string()
      .test(
        'len',
        t('bussiness.register.error.default'),
        (val) => val?.replaceAll(/[^\d]/g, '').length == 14,
      )
      .required('Required'),
    userName: Yup.string()
      .min(2, t('bussiness.register.error.default'))
      .required('Required'),
    phone: Yup.string()
      .min(2, t('bussiness.register.error.default'))
      .required('Required'),
    email: Yup.string()
      .email(t('bussiness.register.error.default'))
      .required('Required'),
  });

  function onSubmit(value: BusinessFormValues) {
    const company = {
      name: value.companyName,
      cnpj: value.companyCnpj,
    };
    const user = {
      name: value.userName,
      phone: value.phone,
      email: value.email,
    };

    CompanyService.register({ company, user })
      .then((res) => {
        toast.success('Registrado com sucesso, logo entraremos em contato!');
        setTimeout(() => {
          window.location.href = 'https://keiken.app/obrigado-empresa';
        }, 3000);
      })
      .catch((err) => {
        toast.error(
          'Falha ao solicitar cadastro, por favor envie email para contato@keiken.app',
        );
      });
  }

  return (
    <Formik
      initialValues={{
        companyName: '',
        companyCnpj: '',
        userName: '',
        phone: '',
        email: '',
      }}
      validationSchema={bussinessRegisterFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}>
      {({ errors }) => (
        <Form className="centerForm">
          <div className="formContainer">
            <div className="initialText">
              <span className="titleForm color-green">
                {t('business.register.health_quality')}
              </span>
              <span className="titleForm">
                {t('business.register.for_your_team')}
              </span>
              <span className="titleForm color-green">
                {t('business.register.your_company')}
              </span>
              <p className="mt-10 subtitle">
                {t('business.register.subtitle')}
              </p>
              <div className="divider" />

              <div className={classnameField(errors?.companyName)}>
                <span className="inputTitle">
                  {t('business.register.company_name')}
                </span>
                <br />
                <Field
                  type="text"
                  className="no-border"
                  placeholder="Digite aqui o nome da empresa"
                  name="companyName"
                  id="companyName"
                />
              </div>
              <div className={classnameField(errors?.companyCnpj)}>
                <span className="inputTitle">
                  {t('business.register.CNPJ')}
                </span>
                <br />
                <Field
                  id="companyCnpj"
                  name="companyCnpj"
                  type="text"
                  className="no-border"
                  placeholder="Digite aqui o CNPJ da empresa"
                  render={({ field, form, ...props }) => (
                    <InputMask
                      mask="99.999.999/9999-99"
                      placeholder="Digite aqui o CNPJ da empresa"
                      {...field}
                      {...props}
                    />
                  )}
                />
              </div>
              <br />
              <BusinessRegisterUserForm errors={errors} />
              {/* 
          <span className="AddressStyle">
            {t('business.register.company_address')}
          </span>
          <br />
          <div className="flex">
            <div className="col-md-4 pl-0">
              <div className="customInput mt-20">
                <span className="inputTitle">{t('business.register.CEP')}</span>
                <br />
                <InputMask
                  mask="99.999-999"
                  type="text"
                  className="no-border"
                  placeholder="00.000-000"
                  name="CEP"
                />
              </div>
            </div>
            <div className="col-md-6 pl-0">
              <div className="customInput mt-20">
                <span className="inputTitle">
                  {t('business.register.street')}
                </span>
                <br />
                <input
                  type="text"
                  className="no-border"
                  placeholder="Digite aqui "
                  name="street"
                />
              </div>
            </div>
            <div className="col-md-2 pl-0 pr-0">
              <div className="customInput mt-20">
                <span className="inputTitle">
                  {t('business.register.number')}
                </span>
                <br />
                <input
                  type="text"
                  maxLength={4}
                  className="no-border"
                  placeholder="0000"
                  name="number"
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="col-md-5 pl-0">
              <div className="customInput mt-20">
                <span className="inputTitle">
                  {t('business.register.neibourhood')}
                </span>
                <br />
                <input
                  type="text"
                  className="no-border"
                  placeholder="Digite aqui"
                  name="neibourhood"
                />
              </div>
            </div>
            <div className="col-md-5 pl-0">
              <div className="customInput mt-20">
                <span className="inputTitle">
                  {t('business.register.city')}
                </span>
                <br />
                <input
                  type="text"
                  className="no-border"
                  placeholder="Digite aqui "
                  name="city"
                />
              </div>
            </div>
            <div className="col-md-2 pl-0 pr-0">
              <div className="customInput mt-20">
                <span className="inputTitle">
                  {t('business.register.state')}
                </span>
                <br />
                <select name="state">
                  <option selected disabled value="0"></option>
                  {STATES.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
         */}
            </div>
            <br />
            <button type="submit" className="registerButton mb-20">
              {t('business.register.request_register_company')}
            </button>

            <span className="textStyle ">
              {t('business.register.already_have_account')}
            </span>
            <Link to="login" className="textStyle colorGreen">
              {t('business.register.do_login')}
            </Link>
          </div>
          <div className="formImage">
            <div className="flex">
              <img className="imgSize" src={meditation} alt="meditation" />
              <span className="imgSpan">
                {t('business.register.message_one')}
              </span>
            </div>
            <br />
            <br />
            <div className="flex">
              <img className="imgSize" src={connections} alt="connections" />
              <span className="imgSpan">
                {t('business.register.message_two')}
              </span>
            </div>
            <br />
            <br />
            <div className="flex">
              <img className="imgSize" src={pocket} alt="pocket" />
              <span className="imgSpan">
                {t('business.register.message_tree')}
              </span>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withTranslation()(BusinessRegisterForm);
