import moment from 'moment';
import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'src/Config/Constants';

export const convertDateTimeFromServer = (
  date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined
) => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = (
  date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined
) => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);
