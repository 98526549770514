import { Tooltip } from '@material-ui/core';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { abort } from 'process';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export type BusinessHeaderProps = WithTranslation;

const BusinessHeader: React.FC<BusinessHeaderProps> = ({ t }) => {
  return (
    <div className="customer-header">
      <div className="customer-header--text">
        <li>
          <NavLink activeClassName="link-is-active" to="/overview">
            {t('header.overview')}
          </NavLink>
          <div className="link-is-active--select-menu" />
        </li>
        <li>
          <NavLink activeClassName="link-is-active" to="/benefits">
            {t('header.benefits')}
          </NavLink>
        </li>
        <li>
          {/* {/* TODO: waiting wallet */}
          {/* <NavLink activeClassName="link-is-active" to="/wallet"> */}
          <NavLink activeClassName="link-is-active" to="/wallet">
            {t('header.wallet')}
          </NavLink>
        </li>
        {/* <li>
          <NavLink activeClassName="link-is-active" to="/chat">
          {t('header.chat')}
          </NavLink>
        </li> */}
        <li>
          <NavLink activeClassName="link-is-active" to="/profile">
            {t('header.profile')}
          </NavLink>
        </li>
        <li>
          <a
            href="https://api.whatsapp.com/send/?phone=554199224109&text=Olá+Keiken,+sou+uma+empresa+e+preciso+de+um+suporte!&type=phone_number&app_absent=0"
            target="_blank"
            rel="noreferrer"
          >
            {t('header.support')}
          </a>
        </li>
      </div>
    </div>
  );
};

export default withTranslation()(BusinessHeader);