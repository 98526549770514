import React, { useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import dayjs from 'dayjs';
import CustomIconTextButton from 'src/Components/CustomIconTextButton';
import CustomInfoCard from 'src/Components/CustomInfoCard';
import PageTitle from 'src/Components/PageTitle';
import GraphCard from 'src/Components/BigCardInfo/GraphCard';
import LastActivitiesCard from 'src/Components/BigCardInfo/LastActivitiesCard';
import BusinessDashboard from 'src/Services/Api/BusinessDashboard';
import StringUtils from 'src/Utils/StringUtils';
import { IBusinessDashboard } from 'src/Models/Business/BusinessDashboard';
import { getPieDataWithColors } from 'src/Utils/colorUtils';
import './styles.scss';
import BusinessWalletReport from 'src/Components/BusinessWalletReport';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/Stores';
import { getCompanyReports } from 'src/Stores/company/action';

export type BusinessOverviewProps = WithTranslation;

const BusinessOverview: React.FC<BusinessOverviewProps> = ({ }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [date, setDate] = React.useState<dayjs.Dayjs>(dayjs());
  const [dashboardData, setDashboardData] =
    React.useState<IBusinessDashboard>(null);

  const getDashboard = async () => {
    const newDashboardData = (await BusinessDashboard.getDashboard(date)).data;
    const pieDataWithColors = getPieDataWithColors(newDashboardData.pieData);
    setDashboardData({ ...newDashboardData, pieData: pieDataWithColors });
    setLoading(false);
  };

  useEffect(() => {
    dispatch(getCompanyReports({ page: 0, size: 20 }));
  }, []);

  React.useEffect(() => {
    getDashboard();
  }, [date]);

  const companyReports = useSelector(
    (state: IRootState) => state.companyState.reportsPage,
  )?.content;
  const reportsDisabled = !companyReports || companyReports.length === 0;


  const {
    walletBalance,
    activityThisMonth,
    usersCount,
    pieData,
    billingCoParticipations,
    usersWithoutSegment,
  } = dashboardData ?? {};

  return (
    <>
      {!loading ? (
        <>
          {/* <FixedHeader /> */}
          <PageTitle title="overview" />
          <div className="Content">
            <Container className="no-padding overview-card-row" style={{ maxWidth: '1600px' }}>
              <CustomIconTextButton
                newDescription="benefit"
                url="create-benefit"
              />
              <CustomInfoCard
                title="Saldo em Carteira"
                value={`${StringUtils.currencyPtBr(walletBalance)}`}
                linkTitle="Ver saldo"
                linkTo="wallet/wallet"
              />
              <CustomInfoCard
                title="Atividades nesse mês"
                value={`${activityThisMonth}`}
                linkTitle="Ver todas atividades"
                linkTo="wallet/extract"
              />
              <CustomInfoCard
                title="Colaboradores"
                value={`${usersCount}`}
                linkTitle="Adicionar colaboradores"
                linkTo="benefits/contributors"
                title2="Colaboradores sem segmento"
                value2={
                  usersWithoutSegment > 0 ? usersWithoutSegment : undefined
                }
                alternativeLink="benefits/contributors?withBenefits=false"
                alternativeLinkTitle="Ver sem segmento"
              />
            </Container>

            <Row>
              <Container className="no-padding" style={{ maxWidth: '1600px' }}>
                <Row className="overview-big-cards">
                  <GraphCard
                    pieData={pieData}
                    withDatePicker
                    date={date}
                    setDate={setDate}
                  />
                  <LastActivitiesCard
                    billingCoParticipations={billingCoParticipations ?? []}
                  />
                </Row>
              </Container>
            </Row>

            {!reportsDisabled ? (
              <Row style={{ position: 'relative', marginBottom: '5%' }}>
                <BusinessWalletReport
                  insideOverview
                />
              </Row>
            ) : (
              null
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BusinessOverview;
