import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Dropzone from '../Dropzone';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import './styles.scss';

export interface AddColaboratorsSegment extends WithTranslation {
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  setSegmentCustomers?: any;
}

const AddColaboratorsSegment: React.FC<AddColaboratorsSegment> = ({
  t,
  name,
  placeholder,
  type,
  setSegmentCustomers,
}) => {
  const [newCustomer, setNewCustomer] = React.useState<string>('');
  const handleAdd = () => {
    setSegmentCustomers((customers) => [...customers, { email: newCustomer }]);
  };
  return (
    <div className="col-8 mt-40 AddColaboratorsSegment">
      <span className="title">
        {t('business.flux.add_colaborators_this_segment')}
      </span>
      <div className="divider" />
      <div className="flex">
        <div className="col-5 spanCategory">
          <span>{t('business.flux.add_colaborators_individually')}</span>
        </div>
        <div className="col-5 spanCategory right">
          <span>{t('business.flux.add_colaborators_by_group')}</span>
        </div>
      </div>

      <div className="flex">
        <div className="col-5 pl-0 pr-0">
          <div className="customInput mt-20">
            <span className="inputTitle">
              {t('business.flux.colaborator_email')}
            </span>
            <br />
            <input
              type={type}
              className="no-border"
              placeholder={placeholder}
              name={name}
              value={newCustomer}
              onChange={(e) => setNewCustomer(e.target.value)}
            />
            <div className="add" onClick={() => handleAdd()}>
              <AddIcon fontSize="large" />
            </div>
          </div>
        </div>
        <div className="col-5 pl-0 pr-0 right">
          <Dropzone setEmails={setSegmentCustomers} />
          <br />
          <a
            className="span"
            href="https://keiken-prod.s3.amazonaws.com/documents/template.csv"
            download>
            {t('business.flux.download_template')}
          </a>

          <ChevronRightIcon className="spanChevron" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AddColaboratorsSegment);
