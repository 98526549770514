import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

interface Props {
  handleEdit: any;
}
const BaseFooterContributor: React.FC<Props> = ({ handleEdit }) => {
  return (
    <div>
      <div className="footerBenefits">
        <Link to="/benefits/contributors" className="back">
          Cancelar
        </Link>

        <div className="positionButton" onClick={() => handleEdit()}>
          <button className="button">
            Salvar Alterações
          </button>
        </div>
      </div>
    </div>
  );
};

export default BaseFooterContributor;
