import { toast } from 'react-toastify';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import async from 'react-select/async';
import AuthUtils from '../../Utils/AuthUtils';
import { SERVER_API_URL } from '../../Config/Constants';

const createApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: SERVER_API_URL,
    timeout: 60 * 1000,
    validateStatus: () => true
  };

  const instance = axios.create(config);
  // tslint:disable-next-line: no-shadowed-variable
  instance.interceptors.request.use(async config => {
    const token = AuthUtils.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export const api = createApiInstance();
export default {
  api
};
