import React from 'react';
import { TextField } from '@material-ui/core';
import SimpleModal from 'src/Components/SimpleModal';
import { useTranslation } from 'react-i18next';

interface OwnerEditProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditOwnerModal = ({ isOpen, onClose }: OwnerEditProps) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onClose();
  }

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      title={t('cards.edit.pass')}
      labelConfirm={t('modalOptions.save')}
      labelCancel={t('modalOptions.cancel')}
    >
      <div>
        <div className="BusinessCard--body">
          <TextField
            label="Nome do responsável"
            className="BusinessCard--input--6--right"
            />
          <TextField
            label="Cargo"
            className="BusinessCard--input--6--right"
            />
          <TextField
            label="E-mail"
            className="BusinessCard--input--6--right"
            />
          <TextField label="CPF" className="BusinessCard--input--6--right" />
        </div>
      </div>
    </SimpleModal>
  );
};

export default EditOwnerModal;