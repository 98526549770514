import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { PayrollDicountType } from 'src/Models/payrollDiscountType';

import PayrollDicountApi from './Api/PayrollDiscountApi';

class PayrollDicountService {
  static updatePayroll = async (
    contributors: PayrollDicountType[] ,
  ): Promise<void> => {
    try {
      await PayrollDicountApi.updatePayroll(contributors);
      return Promise.resolve();
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
}

export default PayrollDicountService;
