import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import CoParticipationGroupCard from '../CoParticipationGroupCard';

export interface CoParticipationGroupListProps extends WithTranslation {
  initialSelected?: CoParticipationGroup[];
  groups: CoParticipationGroup[];
  onChange: (items: CoParticipationGroup[]) => void;
}

const CoParticipationGroupList: React.FC<CoParticipationGroupListProps> = ({
  t,
  initialSelected,
  groups,
  onChange,
}) => {
  const [selecteds, setSelecteds] = useState<CoParticipationGroup[]>();

  useEffect(() => {
    setSelecteds(initialSelected ?? []);
  }, [initialSelected]);

  useEffect(() => {
    setSelecteds(initialSelected ?? []);
  }, [groups]);

  const handleOnSelectGroup = (item: CoParticipationGroup): void => {
    if (!selecteds.some((it) => it.id == item.id)) {
      setSelecteds((prev) => {
        const newValue = [...prev, item];
        onChange(newValue);
        return newValue;
      });
    } else {
      setSelecteds((prev) => {
        const newValue = prev.filter((it) => it.id != item.id);
        onChange(newValue);
        return newValue;
      });
    }
  }

  return (
    <>
      {groups?.map?.((item, index) => (
        <CoParticipationGroupCard
          key={`co-participation-group-modal-item-${item.id}`}
          item={item}
          selected={selecteds?.some((it) => it.id == item.id)}
          onClick={handleOnSelectGroup}
        />
      ))}
    </>
  );
};

export default withTranslation()(CoParticipationGroupList);
