import Popover from '@material-ui/core/Popover';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import { generateColor } from 'src/Utils/Utils';

import './styles.scss';

export interface CoParticipationGroupCardProps extends WithTranslation {
  item?: CoParticipationGroup;
  selected: boolean;
  onClick?: (benefit: CoParticipationGroup) => void;
}

const CoParticipationGroupCard: React.FC<CoParticipationGroupCardProps> = ({
  t,
  item,
  selected,
  onClick,
}) => {
  const [colors, setColors] = useState(generateColor({ arrayLength: 4 }));

  const variation = selected ? 'plr-1' : 'plr-0';

  return (
    <div className="SegmentCards">
      <div
        className={`col-md-12 col-md-offset-1 flex ${variation}`}
        onClick={() => onClick(item)}
      >
        <div className="p-2">
          <div className={`circle ${selected ? 'circle-color' : ''}`} />
          <span className="title-card">{item?.nameGroup}</span>
          <span className="subtitles">{`R$ ${item?.maxSpendingGroup}`}</span>
          <div className="wrap">
            {item?.activityCategories?.map?.((activityCategories, index) => {
              const backgroundColor = activityCategories?.activityCategory?.color ?? '#25b379'
              if (index < 2) {
                return (
                  <span style={{ backgroundColor }} key={`group-card-${activityCategories.id}-${index}`}>
                    {activityCategories?.activityCategory?.name}
                  </span>
                );
              }
              if (index === 2) {
                return (
                  <div className="more_infos">
                    <div className="more_infos--balloon">
                      <div className="more_infos--balloon__card">
                        {item?.activityCategories
                          ?.slice(2)
                          ?.map((categories) => (
                            <div>{categories?.activityCategory?.name}</div>
                          ))}
                      </div>
                    </div>
                    <span style={{ backgroundColor }}>
                      {`+ ${item?.activityCategories?.length - 2}`}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CoParticipationGroupCard);
