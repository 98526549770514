import React, { useState } from 'react';
import { Edit } from '@material-ui/icons';
import { Company } from 'src/Models/Company';
import { useTranslation } from 'react-i18next';
import { CircleLoading } from 'react-loadingg';
import Images from 'src/Themes/Constants/Images';

import BusinessEditModal, { CompanyInfo } from './BusinessEditModal';
import { useDispatch } from 'react-redux';
import { updateCompanyData } from 'src/Stores/company/action';
import { IImage } from 'src/Models/IImage';
import { UpdateCompanyTarget } from 'src/Stores/company/types';

const BusinessData: React.FC<{ company: Company }> = ({ company }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleUpdateCompanyData = (
    companyInfo: CompanyInfo,
    image?: IImage,
  ) => {
    let newCompany = { ...company, ...companyInfo };
    if (image) {
      newCompany = { ...newCompany, image };
    }

    dispatch(updateCompanyData(newCompany, UpdateCompanyTarget.COMPANY_DATA));

    setIsEditModalOpen(false);
  };

  const imageSource = company?.image?.imageUrl ?? '';
  return (
    <>
      <div className="BusinessCard">
        <div className="BusinessCard--header">
          <h3>{t('cards.header.business')}</h3>
          <div
            onClick={() => setIsEditModalOpen(true)}
            className="BusinessCard--button">
            <Edit />
            <span>{t('cards.edit.edit')}</span>
          </div>
        </div>

        <hr className="hr" />
        <div className="BusinessCard--body">
          <div className="BusinessCard--image">
            {imageSource ? (
              <img src={imageSource} alt="" />
            ) : !company?.image ? (
              <img src={Images.icons.company} alt="" />
            ) : (
              <CircleLoading
                style={{ position: 'static', marginInline: 'auto' }}
              />
            )}
          </div>
          <div className="BusinessCard--info">
            <h4>{company?.name}</h4>
            <p>{company?.socialReason}</p>
            <p>{company?.cnpj}</p>
          </div>
        </div>
      </div>
      <BusinessEditModal
        isOpen={isEditModalOpen}
        data={company}
        onClose={() => setIsEditModalOpen(false)}
        onConfirm={handleUpdateCompanyData}
      />
    </>
  );
};

export default BusinessData;
