import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import '../styles.scss';
import SimpleModal from 'src/Components/SimpleModal';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import makeNewStyles from '../style';

export interface AddSingle extends WithTranslation {
  setOpenSingle: React.Dispatch<React.SetStateAction<boolean>>;
  openSingle: boolean;
  segmentName?: string;
  setSegmentCustomers?: React.Dispatch<React.SetStateAction<any[]>>;
  customersPage?: boolean;
}

const AddSingle: React.FC<AddSingle> = ({
  t,
  setSegmentCustomers,
  setOpenSingle,
  openSingle,
  segmentName,
  customersPage,
}) => {
  // @ts-ignore
  const classes = makeNewStyles();
  const [segment, setSegment] = useState<any>();
  const [allSegments, setAllSegments] = useState<any>();
  const [expandBenefits, setExpandBenefits] = useState(false);
  const [newEmail, setNewEmail] = useState<string>('');

  const getSegments = async () => {
    if (customersPage) {
      const getAll = (await BusinessSegment.getAllSegments()).data;
      setAllSegments(getAll);
      setSegment(getAll[0]);
    }
  };

  useEffect(() => {
    if (customersPage) {
      getSegments();
    }
  }, [openSingle]);

  const handleAddCustomer = async () => {
    setOpenSingle(false);
    if (!customersPage) {
      setSegmentCustomers((customers) => [...customers, { email: newEmail }]);
    } else {
      await BusinessCustomerApi.createOneCustomer({
        coParticipationSegment: { id: segment.id },
        email: newEmail,
        groups: segment.groups.map((group) => {
          return { id: group.id };
        }),
      });
      window.location.reload();
    }
  };
  const handleSegmentInput = () => {
    if (!customersPage) {
      return (
        <div className="customInputDisabled mt-20">
          <span className="inputTitle">{t('business.flux.segment_name')}</span>
          <br />
          <input type="text" disabled value={segmentName || 'Sem nome'} />
        </div>
      );
    }
    if (allSegments) {
      return (
        <div className="customInputAvailable mt-20">
          <span className="inputTitle">{t('business.flux.segment_name')}</span>
          <br />
          <select
            onChange={(e) => {
              setSegment(
                allSegments.find((el) => el.nameSegment === e.target.value),
              );
            }}
          >
            {allSegments.map((segment, idx) => (
              <option key={idx}>{segment.nameSegment}</option>
            ))}
          </select>
        </div>
      );
    }
    return <></>;
  };
  const bodySingle = (
    <div className={classes.paper}>
      <div className="modalEditBusiness ListColaborators">
        <div className={classes.header}>
          <p id="simple-modal-title" className="modalTitle">
            {t('business.flux.add_colaborator')}
          </p>
          <CloseIcon
            className={classes.close}
            onClick={() => setOpenSingle(false)}
          />
        </div>
        <div className="underline" />
        <div className="flex mb-30">
          <div className="col-6 pl-0 ">
            <p className="modalSubtitles">
              {t('business.flux.colaborator_email')}
            </p>
            <div className="customInputAvailable mt-20">
              <span className="inputTitle">
                {t('business.flux.colaborator_email')}
              </span>
              <br />
              <input
                type="text"
                placeholder="colaborador@email.com.br"
                onChange={(e) => setNewEmail(e.target.value)}
                value={newEmail}
              />
            </div>
          </div>
          <div className="col-6 pl-0">
            <p className="modalSubtitles">
              {t('business.flux.colaborator_segment')}
            </p>
            {handleSegmentInput()}
          </div>
        </div>
        {segment && (
          <>
            <div className="underline" />
            <div className="flex space-btwn">
              <p className="modalSubtitles">
                {t('business.flux.segment_benefits')}
              </p>
              {!expandBenefits && (
                <ExpandMoreIcon
                  onClick={() => setExpandBenefits(!expandBenefits)}
                />
              )}
              {expandBenefits && (
                <ExpandLessIcon
                  onClick={() => setExpandBenefits(!expandBenefits)}
                />
              )}
            </div>
            <div>
              {expandBenefits && (
                <div className="BenefitCategories p-0 mt-0">
                  <div className="mb-30">
                    <tr className="line-table col-12">
                      {segment?.groups?.map((group, index) => (
                        <td className="col-4">
                          {index < 2 && (
                            <div className="addsingle__benefits">
                              {group.nameGroup}
                            </div>
                          )}
                          {index === 2 && (
                            <div className="addsingle__benefits">
                              {segment.groups.lenght - 2}
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className="underline" />
        <div className="footerModal">
          <span
            className="cancel"
            onClick={() => {
              setOpenSingle(false);
            }}
          >
            {t('business.flux.cancel')}
          </span>
          <span
            className="buttonModal"
            onClick={() => {
              handleAddCustomer();
            }}
          >
            {t('business.flux.add_colaborator')}
          </span>
        </div>
      </div>
      {/* <SimpleModal /> */}
    </div>
  );

  return (
    <Modal
      open={openSingle}
      onClose={() => {
        setOpenSingle(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="overflow-auto"
    >
      {bodySingle}
    </Modal>
  );
};

export default withTranslation()(AddSingle);
