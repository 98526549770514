import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Card, Col } from 'reactstrap';
import { WalletPieData, WalletSegment } from 'src/Models/CompanyWallet';
import '../styles.scss';
import { Tooltip } from '@material-ui/core';

export interface SegmentActivitiesProps extends WithTranslation {
  segments: WalletSegment[];
  pieData: WalletPieData[];
}

const SegmentActivities: React.FC<SegmentActivitiesProps> = ({ t, segments, pieData }) => {
  const [ hasShown, setHasShown ] = useState<boolean>(false);
  const segmentsRef = React.useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setHasShown(true)
        }
      });
    });
    observer.observe(segmentsRef.current);
    // cleanup function
    return () => {
      observer.disconnect();
    };
  },[])

  const handleSegment = (segment: WalletSegment) => {  
    const { count, activityCategories } = segment;

    const bars = activityCategories.map((category) => {
      return {percent: (category.count / count) * 100, color: category.color, count: category.count}
    });

    return (
      <div className="BigCard__segment__bar">
        {bars.map((bar, i) => (
          <Tooltip key={i} title={bar.count}>
            <div
              className="BigCard__segment__bar--part"
              style={{ width: hasShown ? `${bar.percent}%`: 0, backgroundColor: bar.color}}
            />
          </Tooltip>
        ))}
      </div>
    );
  };

  return (
    <Card className="BigCard">
      <div className="BigCard--title">Últimas atividades</div>
     
      <div style={{width: "100%"}} ref={segmentsRef}>
        {segments?.map((segment) => (
          <div key={segment.name} className="BigCard__segment">
            <Col className="no-padding">
              <div className="BigCard__segment--title">{segment.name}</div>
              {handleSegment(segment)}
            </Col>
          </div>
        ))}
      </div>
      <div className="BigCard__segment__index">
        {pieData?.map((data) => (
          <div key={data.name} className="BigCard__segment__index--part" style={{backgroundColor: data.color}}>
            {data.name}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default withTranslation()(SegmentActivities);
