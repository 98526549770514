import styled from 'styled-components';
import { ScreenDimensions } from '../style';

export const SlideContainer = styled.div`
  width: 50%;
  height: 100%;
  min-height: 648px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #455561;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    display: none;
  }
`;

export const StyledTitle = styled.h2`
  max-width: 342px;
  height: 66px;

  font-weight: 700;
  font-size: 24px;
  line-height: 40px;

  text-align: center;
  margin: 64px 0px 0px 0px;

  color: #ffffff;
`;

export const StyledDescription = styled.p`
  max-width: 400px;

  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;

  color: #ffffff;

  margin: 24px 0px 0px 0px;
`;
