import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as Search } from '../../Images/Icons/search-fill.svg';

import './styles.scss';
export interface SearchTextProps extends WithTranslation {
  searchName?: string;
  setSearchName?: React.Dispatch<React.SetStateAction<string>>;
  search?: boolean;
  setSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
}

const SearchText: React.FC<SearchTextProps> = ({
  t,
  searchName,
  setSearchName,
  search: searchAction,
  setSearch: setSearchAction,
  onSubmit,
}) => {
  const [search, setSearch] = useState<boolean>(false);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onSubmit && onSubmit();
    }
  };

  return (
    <div className="searchText">
      <div className="align-center searchText-row">
        {search && (
          <input
            className="searchText--input"
            placeholder={t("filter.search.name")}
            type="text"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        )}
        <Search
          className="searchText--icon"
          onClick={() => {
            setSearchAction(true);
            setSearch(false);
          }}
        />
        {!search && (
          <div
            className="searchText--text"
            onClick={() => {
              setSearchAction(false);
              setSearch(true);
            }}>
            {t("filter.search.simple")}
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SearchText);
