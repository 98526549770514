import React, { useEffect, useState } from 'react';
import { RotateCircleLoading } from 'react-loadingg';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { getCompanyExtract } from 'src/Stores/company/action';
import Pagination from '../Pagination';
import Historic from './Historic';
import Settings, { CalendarDate } from './Settings';
import { CompanyWalletApi } from 'src/Services/Api/CompanyWalletApi';
import { Billing } from 'src/Models/Billing';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import TableHeader from './TableHeader';
import './styles.scss';

export interface ExtractProps
  extends WithTranslation,
    DispatchProps,
    StateProps {}

const Extract: React.FC<ExtractProps> = ({
  t,
  pageExtract,
  company,
  getCompanyExtract,
  getExtractStatus,
}) => {
  const [page, setPage] = useState<number>(1);
  const [billingPage, setBillingPage] = useState<Billing[]>([]);

  //filter States
  const [searchName, setSearchName] = useState<string>('');
  const [search, setSearch] = useState<boolean>(false);
  const [sortByAsc, setSortByAsc] = useState<boolean>(true);
  const [formatedDate, setFormatedDate] = useState<CalendarDate>({
    startDate: null,
    endDate: null,
  });
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const getExtractData = () => {
    const startDateToSend = moment(
      formatedDate?.startDate,
      'DD/MM/YYYY',
    ).toISOString();
    const endDateToSend = moment(formatedDate?.endDate, 'DD/MM/YYYY')
      .add(1, 'day')
      .add(-1, 's')
      .toISOString();
    getCompanyExtract(
      company.id,
      page - 1,
      20,
      sortByAsc,
      searchName,
      startDateToSend,
      endDateToSend,
    );
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    const startDateToSend = moment(
      formatedDate?.startDate,
      'DD/MM/YYYY',
    ).toISOString();
    const endDateToSend = moment(formatedDate?.endDate, 'DD/MM/YYYY')
      .add(1, 'day')
      .add(-1, 's')
      .toISOString();

    await CompanyWalletApi.getExtractTableInExcel(company.id, {
      page: 0,
      size: pageExtract?.totalElements,
      nameOrEmail: searchName,
      sort: sortByAsc,
      startDate: startDateToSend,
      endDate: endDateToSend,
    });

    setIsDownloading(false);
  };

  useEffect(() => {
    getExtractData();
  }, [page, sortByAsc, formatedDate]);

  useEffect(() => {
    setBillingPage(pageExtract?.content);
  }, [pageExtract?.content]);

  return (
    <div className="extract">
      <Settings
        sort={sortByAsc}
        setSort={setSortByAsc}
        onSubmit={getExtractData}
        searchName={searchName}
        setSearchName={setSearchName}
        search={search}
        setSearch={setSearch}
        setFormatedDate={setFormatedDate}
        formatedDate={formatedDate}
        handleDownload={handleDownload}
        isDownloading={isDownloading}
      />
      <div className="table">
        <TableHeader />
        {getExtractStatus === HttpRequestStatus.ONGOING ? (
          <div style={{ width: '100%', height: '100%' }}>
            <RotateCircleLoading
              size="large"
              style={{
                position: 'static',
                marginInline: 'auto',
                marginBlock: '100px',
              }}
            />
          </div>
        ) : billingPage?.length > 0 ? (
          billingPage?.map((it) => <Historic billing={it} key={it.id} />)
        ) : (
          <div className="no-data">
            <p>{t('wallet.extract.noData')}</p>
          </div>
        )}
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        allPages={pageExtract?.totalPages ?? 1}
      />
    </div>
  );
};
const mapStateToProps = (state: IRootState) => {
  return {
    pageExtract: state.companyState.billingPage,
    getExtractStatus: state.companyState.status,
    company: state.authentication?.companyUser?.company,
  };
};

const mapDispatchToProps = { getCompanyExtract };
type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Extract),
);
