import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as Calendar } from '../../../Images/Icons/calendar.svg';
import SearchText from '../../SearchText';
import OrderBy from '../../OrderBy';

import { DateRange } from 'react-date-range';
import { pt } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { ArrowDownward } from '@material-ui/icons';
import { format } from 'date-fns';
import { Popover } from '@material-ui/core';
import Images from 'src/Themes/Constants/Images';
import { RotateCircleLoading } from 'react-loadingg';

export interface CalendarDate {
  startDate: string;
  endDate: string;
}

export interface SettingsProps extends WithTranslation {
  searchName: string;
  setSearchName: React.Dispatch<React.SetStateAction<string>>;
  search: boolean;
  setSearch: React.Dispatch<React.SetStateAction<boolean>>;
  sort: boolean;
  setSort: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
  formatedDate?: CalendarDate;
  setFormatedDate?: React.Dispatch<React.SetStateAction<CalendarDate>>;
  handleDownload?: () => void;
  isDownloading?: boolean;
}

const Settings: React.FC<SettingsProps> = ({
  t,
  searchName,
  setSearchName,
  search,
  setSearch,
  onSubmit,
  setSort,
  sort,
  setFormatedDate,
  formatedDate,
  handleDownload,
  isDownloading,
}) => {
  const [showCalendarDropDown, setShowCalendarDropDown] =
    useState<boolean>(false);
  const [calendarAnchor, setCalendarAnchor] = useState(null);
  const [calendarRangeDate, setCalendarRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  const handleOnChangeDate = (item: any) => {
    setCalendarRangeDate([item.selection]);
    setFormatedDate({
      startDate: format(item.selection.startDate, 'dd/MM/yyyy'),
      endDate: format(item.selection.endDate, 'dd/MM/yyyy'),
    });
  };

  return (
    <>
      <div className="settings">
        <div
          className="settings__description"
          onClick={(event) => {
            setShowCalendarDropDown(true);
            setCalendarAnchor(event.currentTarget);
          }}>
          <Calendar className="settings__description--icon" />
          <div className="settings__description--text">
            {t('partner.wallet.dateFilter')}
            {formatedDate.startDate &&
              t('partner.wallet.dateRange', {
                startDate: formatedDate?.startDate,
                finalDate: formatedDate?.endDate,
              })}
          </div>
          <ArrowDownward className="settings__description--arrow" />
        </div>
        <button
          disabled={isDownloading}
          className="download-table-btn"
          onClick={handleDownload}>
          {isDownloading ? (
            <>
              <RotateCircleLoading
                size="small"
                style={{
                  position: 'static',
                  marginInline: 'auto',
                }}
              />
              Exportando...
            </>
          ) : (
            <>
              <img src={Images.icons.downloadIcon} alt="export table" />
              Exportar relatório
            </>
          )}
        </button>
        <Popover
          anchorEl={calendarAnchor}
          open={showCalendarDropDown}
          onClose={() => setShowCalendarDropDown(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <DateRange
            locale={pt}
            editableDateInputs={true}
            onChange={(item) => handleOnChangeDate(item)}
            moveRangeOnFirstSelection={false}
            ranges={calendarRangeDate}
            rangeColors={['#25b379', '#25b379']}
          />
        </Popover>
        <div className="settings__actions">
          <SearchText
            search={search}
            setSearchName={setSearchName}
            searchName={searchName}
            setSearch={setSearch}
            onSubmit={onSubmit}
          />
          <OrderBy
            type="extract"
            setFirst={setSort}
            first={sort}
            second={!sort}
          />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Settings);
