export enum HttpResponseTypeError {
  LOGIN_ERROR_USER = 'LOGIN_ERROR_USER',
  LOGIN_ERROR_USER_AND_PASSWORD = 'LOGIN_ERROR_USER_AND_PASSWORD',
  LOGIN_ERROR_PASSWORD = 'LOGIN_ERROR_PASSWORD',
  LOGIN_ERROR_PARTNER_LOGIN = 'LOGIN_ERROR_PARTNER_LOGIN',
  LOGIN_ERROR_CUSTOMER_LOGIN = 'LOGIN_ERROR_CUSTOMER_LOGIN',
  UNKNOWN = 'UNKNOWN'
}

export interface HttpResponseError {
  status?: number;
  type?: HttpResponseTypeError;
  message?: string;
}
