import React from 'react';
import { AxiosResponse } from 'axios';
import { toast, ToastOptions } from 'react-toastify';
import i18n from 'src/Config/I18n';

class ToastService {

  static success = (message: string, options?: ToastOptions): void => {
    toast.success(message, options);
  };

  static error = (message: string): void => {
    toast.error(message);
  };

  static errorFromResponse = (t: (string) => string, err: AxiosResponse): void => {
    const errorCode = err?.data?.code;
    if (errorCode == null) {
      toast.error(t('errors.default'));
      return;
    }
    const message = t(`errors.${errorCode.replaceAll('.', '_')}`);
    toast.error(message);
  };

};

export default ToastService;