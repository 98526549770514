import fs from 'fs';
import Papa from 'papaparse';

const defaultConfig = {
  delimiter: '', // auto-detect
  newline: '', // auto-detect
  quoteChar: '"',
  escapeChar: '"',
  header: false,
  transformHeader: undefined,
  dynamicTyping: false,
  preview: 0,
  encoding: '',
  worker: false,
  comments: false,
  step: undefined,
  error: undefined,
  download: false,
  downloadRequestHeaders: undefined,
  downloadRequestBody: undefined,
  skipEmptyLines: true,
  chunk: undefined,
  chunkSize: undefined,
  fastMode: undefined,
  beforeFirstChunk: undefined,
  withCredentials: undefined,
  transform: undefined,
  delimitersToGuess: [
    ',',
    '\t',
    '|',
    ';',
    Papa.RECORD_SEP,
    Papa.UNIT_SEP,
  ] as any[],
};
export const readCSV = (args, setData, label) => {
  const csvFile = args[0];
  const config: any = {
    ...defaultConfig,
    complete: (result) => {
      setData((data) => [
        ...data,
        ...result?.data?.slice(1)?.map((data) => {
          return { email: data[0] };
        }),
      ]);
    },
  }
  Papa.parse(csvFile, config);
};
