import React from 'react';
import { Col, Row } from 'reactstrap';
import Images from 'src/Themes/Constants/Images';
import { ReactComponent as Plus } from '../../Images/Icons/plus.svg';
import './styles.scss';

interface ProfessionalProps {
  name: string;
  categories: string[];
  subcategories?: string[];
  partnerImage?: string;
}

const Professional: React.FC<ProfessionalProps> = ({
  name,
  categories,
  subcategories,
  partnerImage,
}) => {
  const image =
    partnerImage != null ? partnerImage : Images.icons.defaultPerfil;
  return (
    <div className="professional no-padding">
      <div className="professional-row">
        <div className="photo">
          <img className="img" src={image} alt="Professional" />
        </div>
        <Col className="no-padding">
          <div className="name">{name}</div>
          <Row className="no-padding professionalTags">
            {categories?.length > 0 && <div className="category">{categories[0]}</div>}
            <div className="professional-col">
              {categories?.length > 1 && (
                <div className="multipleCategories">
                  <Plus className="icon" />
                  {categories?.length - 1}
                </div>
              )}
            </div>

            <div className="professional-col">
              {subcategories?.length > 0 && (
                <div className="subcategory">{subcategories[0]}</div>
              )}
            </div>
            {subcategories?.length > 1 && (
              <div className="professional-col">
                <div className="subcategory">
                  <Plus className="icon" />
                  {subcategories?.length - 1}
                </div>
              </div>
            )}
          </Row>
        </Col>
      </div>
    </div>
  );
};

export default Professional;
