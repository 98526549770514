import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import './styles.scss';
import { NoShowProps } from 'src/Pages/Business/BusinessNewSegments';

export interface BenefitSelect extends WithTranslation {
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  title?: string;
  question?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  isSegment?: boolean;
  payrollDiscountEnabled?: boolean;
  setPayrollDiscountEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  noShowProps?: NoShowProps;
  setNoShowProps?: React.Dispatch<React.SetStateAction<NoShowProps>>;
}

const BenefitSelect: React.FC<BenefitSelect> = ({
  t,
  name,
  placeholder,
  type,
  value,
  title,
  question,
  setValue,
  isSegment,
  payrollDiscountEnabled,
  setPayrollDiscountEnabled,
  noShowProps,
  setNoShowProps,
}) => {
  const [showMore, setshowMore] = useState<boolean>(false);
  const [showPayrollMessage, setShowPayrollMessage] = useState<boolean>(false);
  const [showNoShowMessage, setShowNoShowMessage] = useState<boolean>(false);
  const { noShowMonthsBlocked, noShowActive, noShowTimesForBlock } =
    noShowProps ?? {};

  const handleUpdate = () => {
    setPayrollDiscountEnabled(!payrollDiscountEnabled);
  };

  return (
    <div className="benefit-select-container col-8">
      <div className="BenefitSelect">
        <span className="title">{title}</span>
        <div className="divider" />
        <input
          type={type}
          className="input"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {!showMore && (
          <span
            className="subtitle"
            onClick={() => {
              setshowMore(true);
            }}>
            + {question}
          </span>
        )}
        {showMore && (
          <div>
            <span
              className="subtitle"
              onClick={() => {
                setshowMore(false);
              }}>
              - {question}
            </span>
            <span className="message">
              {t(
                isSegment
                  ? 'business.flux.message_segment'
                  : 'business.flux.message',
              )}
            </span>
          </div>
        )}
      </div>

      <div className="BenefitSelect">
        <span className="title">{t('business.flux.payrollDiscount')}</span>
        <div className="divider" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '8px',
          }}>
          <label className="switch">
            <input
              type="checkbox"
              onChange={() => handleUpdate()}
              checked={payrollDiscountEnabled}
            />
            <span className="slider round" />
          </label>
          <div
            style={{
              color: '#455561',
              margin: '0px 0px 0px 8px',
              textAlign: 'left',
              width: '90%',
            }}>
            {t('business.flux.enablePayrollDiscount')}
          </div>
        </div>
        <div
          className="subtitle"
          onClick={() => setShowPayrollMessage(!showPayrollMessage)}>
          {`${showPayrollMessage ? '- ' : '+ '}`}
          {t('business.flux.whatIsPayrollDiscount')}
        </div>

        {showPayrollMessage && (
          <div>
            <span className="message">
              <div className="message">
                {t('business.flux.explainingPayrollDiscount')}
              </div>
            </span>
          </div>
        )}
      </div>
      {noShowProps && (
        <div className="BenefitSelect">
          <span className="title">{t('business.noShowCard.title')}</span>
          <div className="divider" />
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <label className="switch" style={{ margin: '0px' }}>
              <input
                type="checkbox"
                onChange={() =>
                  setNoShowProps({
                    ...noShowProps,
                    noShowActive: !noShowActive,
                  })
                }
                checked={noShowActive}
              />
              <span className="slider round" />
            </label>
            <div
              style={{
                color: '#455561',
                margin: '0px 0px 0px 8px',
                textAlign: 'left',
                width: '90%',
              }}>
              {t('business.noShowCard.subtitle')}
            </div>
          </div>
          {noShowActive && (
            <>
              <label className="number-input-container">
                <span className="number-input-label">
                  {t('business.noShowCard.label1')}
                </span>
                <input
                  type="number"
                  className="number-input"
                  value={noShowTimesForBlock}
                  onChange={(e) =>
                    setNoShowProps({
                      ...noShowProps,
                      noShowTimesForBlock: Number(e.target.value),
                    })
                  }
                />
              </label>
              <label className="number-input-container">
                <span className="number-input-label">
                  {t('business.noShowCard.label2')}
                </span>
                <input
                  type="number"
                  className="number-input"
                  value={noShowMonthsBlocked}
                  onChange={(e) =>
                    setNoShowProps({
                      ...noShowProps,
                      noShowMonthsBlocked: Number(e.target.value),
                    })
                  }
                />
              </label>
            </>
          )}
          <div
            className="subtitle"
            onClick={() => setShowNoShowMessage(!showNoShowMessage)}>
            {`${showNoShowMessage ? '- ' : '+ '}`}
            {t('business.noShowCard.whatIs')}
          </div>

          {showNoShowMessage && (
            <div>
              <span className="message">
                <div className="message">
                  {t('business.noShowCard.explaining')}
                </div>
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(BenefitSelect);
