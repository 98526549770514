import React, { useEffect, useState } from 'react';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { CommonLoading } from 'react-loadingg';

import { Address, AddressUtils } from 'src/Models/Address';
import { GEOCODE_API_KEY} from 'src/Config/Constants';

interface AddressMapProps {
  address: Address;
  onChange: (item: Address) => void;
}

const AddressMap: React.FC<AddressMapProps> = ({ address, onChange }) => {
  const [zoom, setZoom] = useState(16);
  const [position, setPosition] = useState({ lat: undefined, lng: undefined });
  
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: GEOCODE_API_KEY,
  });

  const mapContainerStyles: React.CSSProperties = {
    width: "100%",
    maxWidth: "700px",
    height: "300px",
    marginInline: "auto",
  }

  useEffect(() => {
    const lat = address?.geoPoint?.latitude;
    const long = address?.geoPoint?.longitude;
    
    if (!address || !lat || !long) {
      navigator.geolocation.getCurrentPosition( (pos) => {
        setPosition({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        });
      });
    } else if (lat && long && lat !== position.lat && long !== position.lng) {
      setPosition({
        lat: address?.geoPoint?.latitude,
        lng: address?.geoPoint?.longitude,
      });
    }
  }, [address]);

  const handleChangePosition = (e: google.maps.MapMouseEvent) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    
    setPosition({ lat, lng });
    setZoom(16);

    onChange({
      ...address,
      geoPoint: AddressUtils.builderGeoPoint(lat, lng),
    });
  };

  if (!isLoaded) {
    return <div style={{ height: 300 }} />;
  }
 
  return (
    <div className='google-maps-container'>
      {position?.lat == null ? (
        <div style={{alignContent: 'center', justifyContent: 'center', marginInline: "auto"}}>
          <CommonLoading />
        </div>
      ) : (
        <GoogleMap
          zoom={zoom}
          center={position}
          clickableIcons={false}
          options={{
            streetViewControl: false,
            disableDefaultUI: true,
            zoomControl: true,
          }}
          onRightClick={handleChangePosition}
          onDblClick={handleChangePosition}
          mapContainerStyle={mapContainerStyles}
        >
          <Marker
            draggable
            icon={{
              url: 'https://i.imgur.com/pmUI4nF.png',
              scale: 5,
              anchor: new google.maps.Point(25, 46),
            }}
            onDragEnd={handleChangePosition}
            position={position}
          />
        </GoogleMap>
      )}
    </div>
  );
};

export default AddressMap