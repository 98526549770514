import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import './styles.scss';

import Logo from '../../../Images/Keiken/logo.png';

export interface BusinessLogin extends WithTranslation {}

const BusinessLogin: React.FC<BusinessLogin> = ({ t }) => {
  return (
    <div className="BusinessLogin">
      <div className="bg">
        <div className="loginBox">
          <div>
            <img src={Logo} alt="logo" className="imageMargin" />
            <div className="input">
              <input
                type="text"
                placeholder={t('business.register.inform_email')}
                name="email"
              />
            </div>
            <div className="input">
              <input
                type="password"
                placeholder={t('business.register.inform_passwd')}
                name="password"
              />
            </div>
            <button className="button">{t('business.register.login')}</button>
            <Link to={'forgot-password'} className="span">
              {t('business.register.forgot_password')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BusinessLogin);
