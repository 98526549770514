import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { string } from 'prop-types';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import {
  BillingCoParticipation,
  BillingCoParticipationStatus,
} from 'src/Models/BillingCoParticipation';
import StringUtils from 'src/Utils/StringUtils';
import '../styles.scss';

export interface LastActivitiesCardProps extends WithTranslation {
  billingCoParticipations?: BillingCoParticipation[];
}

const LastActivitiesCard: React.FC<LastActivitiesCardProps> = ({
  t,
  billingCoParticipations,
}) => {
  const formatDate = (it: BillingCoParticipation): string => {
    const date = it?.billing?.date ?? it?.date;
    if (date == null) {
      return '';
    }
    return moment(date).format('DD/MM/YYYY');
  };

  return (
    <Card className="BigCard">
      <div className="BigCard--title">Últimas atividades</div>
      {billingCoParticipations?.map((it, index) => (
        <div
          key={`last-activities-card-${index}-${it.id}`}
          className="BigCard--LastActivities">
          <div className="BigCard--LastActivities__infosLeft">
            <div className="BigCard--LastActivities__infosLeft--top">
              {formatDate(it)} -{' '}
              {it?.coParticipationGroupActivityCategory?.activityCategory
                ?.name ?? ''}
            </div>
            <div className="BigCard--LastActivities__infosLeft--bottom">
              {it?.coParticipationCustomer?.customer?.name ?? ''}
            </div>
          </div>
          {it?.billing?.status == BillingCoParticipationStatus.FUTURE_CHARGE ? (
            <Tooltip
              title={`Essa é uma pré-reserva de saldo para : ${moment(
                it?.date,
              ).format('DD/MM/YYYY')}`}>
              <div
                className="BigCard--LastActivities__infosRight"
                style={{ color: 'orange' }}>
                -{StringUtils.currencyPtBr(it?.subsidyValue)}
              </div>
            </Tooltip>
          ) : (
            <div className="BigCard--LastActivities__infosRight">
              -{StringUtils.currencyPtBr(it?.subsidyValue)}
            </div>
          )}
        </div>
      ))}
      <Link to="/wallet/extract" className="BigCard--link">
        Ver tudo
      </Link>
    </Card>
  );
};

export default withTranslation()(LastActivitiesCard);
