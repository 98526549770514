import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CoParticipationSegment } from 'src/Models/Business/CoParticipationSegment';

import './styles.scss';

export interface SegmentsCardProps extends WithTranslation {
  item: CoParticipationSegment;
  selected?: boolean;
  onSegmentSelected?: (selected: CoParticipationSegment) => void;
}

const SegmentsCard: React.FC<SegmentsCardProps> = ({
  t,
  item,
  selected,
  onSegmentSelected,
}) => {
  return (
    <div className="SegmentCard">
      <div
        className={`col-md-12 col-md-offset-1 flex ${
          selected ? 'plr-1' : 'plr-0'
        }`}
        onClick={() => onSegmentSelected(item)}
      >
        <div className="p-2">
          <div className={`circle ${selected ? 'circle-color' : ''}`} />
          <div className="d-flex flex-column">
            <span className="title-card">{item.nameSegment}</span>
            <span className="subtitles">
              {item?.customersSize ?? item?.customers?.length}
              {' '}
              colaboradores
            </span>
            <span className="subtitles">
              {item.groups?.length ?? 0}
              {' '}
              Benefícios
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SegmentsCard);
