import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Link,
  NavLink,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AuthUtils from '../../Utils/AuthUtils';
import BusinessHeader from './BusinessHeader';
import './styles.scss';

interface IFixedHeaderProps extends RouteComponentProps, WithTranslation {
  username: string;
}

interface IFixedHeaderState {}

class FixedHeader extends React.Component<
  IFixedHeaderProps,
  IFixedHeaderState
> {
  $defaultPath: string;

  constructor(props) {
    super(props);
    this.state = {};
    this.$defaultPath = '';
  }

  componentDidMount() {}

  render() {
    const { t } = this.props;
    return (
      <div
        className="fixed-header"
        style={{ left: '0', right: '0', position: 'fixed' }}>
        <Container style={{ height: "100%" }}>
          <div className="fixed-header__items--logo" />
        </Container>
      </div>
    );
  }
}

export default withTranslation()(withRouter(FixedHeader)); 
