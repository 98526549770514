import { withTranslation, WithTranslation } from 'react-i18next';
import React from 'react';
import '../styles.scss';
import { Popover } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { ReactComponent as Plus } from '../../../Images/Icons/plus.svg';

export interface Props extends WithTranslation {
  handleOpenMultiple: any;
  handleOpenSingle: any;
  redirectToSegment?: boolean;
}

const PopoverAddCustomers: React.FC<Props> = ({
  t,
  handleOpenMultiple,
  handleOpenSingle,
  redirectToSegment
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropDown, setDropDown] = React.useState(false);
  const history = useHistory();
  const handleClick = (event) => {
    if(!redirectToSegment){
      setDropDown(true);
      setAnchorEl(event.currentTarget);
    }else{
      history.push('/benefits/segments');
    }
  };
  return (
    <>
      <div className="settings__actions__new headerBtns">
        <div
          className="settings__actions__new--row align-center"
          onClick={(event) => handleClick(event)}
        >
          <Plus className="settings__actions__new--icon iconImportant" />
          <div className="settings__actions__new--text">
            Adicionar Colaborador
          </div>
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        open={dropDown}
        onClose={() => setDropDown(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="tooltiptext">
          <span className="auto-important" onClick={() => handleOpenMultiple()}>
            {t('business.flux.add_multiple')}
          </span>
          <div className="divider2" />
          <span className="auto-important" onClick={() => handleOpenSingle()}>
            {t('business.flux.add_simple')}
          </span>
        </div>
      </Popover>
    </>
  );
};

export default withTranslation()(PopoverAddCustomers);
