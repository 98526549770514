import React from 'react';
import NumberFormat from 'react-number-format';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';

const useStyles = (theme) => ({
  numberContainer: {
    height: 54,
    marginTop: 20,
  },
  input: {
    fontFamily: 'Muller',
    fontSize: 16,
    backgroundColor: `rgba(240, 241, 242, 0.2)`,
  },
});

export interface OutlinedCurrencyTextField extends WithTranslation {
  value: number;
  onChange: (value: number) => void;
  classes?: any;
  label?: string;
}

const OutlinedCurrencyTextField: React.FC<OutlinedCurrencyTextField> = ({
  t,
  classes,
  value,
  onChange,
  label,
}) => {
  return (
    <TextField
      fullWidth
      className={classes.numberContainer}
      label={label ?? ''}
      value={value}
      // @ts-ignore
      onChange={(e) => onChange?.(e.target.floatValue)}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        classes: {
          root: classes.input,
        },
      }}
      variant="outlined"
    />
  );
};

interface CustomProps {
  onChange: (event: {
    target: { name: string; value: string; floatValue: number };
  }) => void;
  name: string;
}
const NumberFormatCustom = React.forwardRef<NumberFormat<number>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              floatValue: values.floatValue,
            },
          });
        }}
        isNumericString
        prefix="R$ "
        decimalScale={2}
        fixedDecimalScale
        decimalSeparator=","
        thousandSeparator="."
      />
    );
  },
);

export default withStyles(useStyles)(
  withTranslation()(OutlinedCurrencyTextField),
);
