import qs from 'qs';
import { AxiosResponse } from 'axios';
import { BillingCoParticipation } from 'src/Models/BillingCoParticipation';
import { PageableResponse } from 'src/Models/pageable';
import { CompanyWallet } from 'src/Models/CompanyWallet';
import { Dayjs } from 'dayjs';
import { Billing } from 'src/Models/Billing';
import { api } from '.';
import moment from 'moment';

const baseUrl = '/companies';

export interface FilterParams {
  'coParticipationCustomer.id'?: number;
  page?: number;
  size?: number;
  sort?: boolean;
  nameOrEmail?: string;
  startDate?: string;
  endDate?: string;
  search?: string;
}

export class CompanyWalletApi {
  static getWalletById = (
    id: number,
    date: Dayjs,
  ): Promise<AxiosResponse<CompanyWallet>> => {
    const start = date.startOf('month').toISOString();
    const end = date.endOf('month').toISOString();
    return api.get(`${baseUrl}/${id}/wallet?start=${start}&end=${end}`);
  };

  static handleExtractParams = (filter?: FilterParams): string => {
    const page = filter.page ?? 0;
    const size = filter.size ?? 20;
    const sort = filter.sort ? 'date,desc' : 'date,asc';
    const name = filter.nameOrEmail ? filter.nameOrEmail : null;
    const search = filter.search ? filter.search : null;
    const date = filter.startDate
      ? `&date=${filter.startDate}&date=${filter.endDate}`
      : null;

    let params = qs.stringify(
      {
        page,
        size,
        search,
        'coParticipationCustomer.customer.name': name,
        'coParticipationCustomer.customer.email': name,
        'coParticipationCustomer.id': filter['coParticipationCustomer.id'],
        sort,
      },
      { skipNulls: true },
    );

    if (date) {
      params += date;
    }

    return params;
  };

  static handleExtractBillingParams = (filter?: FilterParams): string => {
    const page = filter.page ?? 0;
    const size = filter.size ?? 20;
    const sort = filter.sort ? 'date,desc' : 'date,asc';
    const name = filter.nameOrEmail ? filter.nameOrEmail : null;
    const date = filter.startDate
      ? `&date=${filter.startDate}&date=${filter.endDate}`
      : null;

    let params = qs.stringify(
      {
        page,
        size,
        'billingCoParticipation.coParticipationCustomer.email': name,
        'billingCoParticipation.coParticipationCustomer.customer.name': name,
        sort,
      },
      { skipNulls: true },
    );

    if (date) {
      params += date;
    }

    return params;
  };

  static getExtractTableInExcel = async (
    id?: number,
    filter?: FilterParams,
  ) => {
    const paramsString = CompanyWalletApi.handleExtractParams(filter);

    const response = await api.get(
      `${baseUrl}/${id}/wallet-extract/xlsx?${paramsString}`,
      {
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/vnd.ms-excel' }),
    );

    const date = moment().format('YYYY-MM-DD_HH:mm:ss');

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.setAttribute('download', `extrato_${date}.xlsx`);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  static getCompanyWalletExtract = (
    id?: number,
    filter?: FilterParams,
  ): Promise<AxiosResponse<PageableResponse<Billing>>> => {
    const params = CompanyWalletApi.handleExtractBillingParams(filter);

    return api.get(`${baseUrl}/${id}/billing-extract?${params}`);
  };

  static getBillingsCoParticipations = (
    id?: number,
    filter?: FilterParams,
  ): Promise<AxiosResponse<PageableResponse<BillingCoParticipation>>> => {
    const params = CompanyWalletApi.handleExtractParams(filter);

    return api.get(`${baseUrl}/${id}/wallet-extract?${params}`);
  };

  static walletDetails = (
    filter?: FilterParams,
  ): Promise<AxiosResponse<PageableResponse<Billing>>> => {
    const params = CompanyWalletApi.handleExtractParams(filter);

    return api.get(`/billings/wallet-details?${params}`);
  };

  static getWalletDetailsInExcel = async (filter?: FilterParams) => {
    const params = CompanyWalletApi.handleExtractParams(filter);

    const response = await api.get<Blob>(
      `/billings/wallet-details/xlsx?${params}`,
      {
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/vnd.ms-excel' }),
    );

    const date = moment().format('YYYY-MM-DD_HH:mm:ss');

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.setAttribute('download', `extrato_desconto_em_folha_${date}.xlsx`);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
}
