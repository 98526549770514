import _, { min } from 'lodash';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import { IImage } from 'src/Models/IImage';
import 'react-image-crop/dist/ReactCrop.css';
import "./styles.scss";

const LoadImage = ({ onSave }) => {
  const { t } = useTranslation();

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState<any>({
    unit: '%',
    width: 100,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [filename, setFilename] = useState<string>('');
  const [image, setImage] = useState<IImage>({});

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      // @ts-ignore
      reader.addEventListener('load', () => setUpImg(reader?.result));
      reader.readAsDataURL(e.target.files[0]);
      setImage({
        fileName: e.target.files[0].name,
        contentType: e.target.files[0].type,
      });
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
  }, [completedCrop]);

  async function handleOnSave() {
    const file = previewCanvasRef.current?.toDataURL()?.split(',')?.[1];

    onSave({
      ...image,
      file,
    });
  }

  return (
    <div>
      <div>
        <label className='choose-logo-input'>
          {t('cards.edit.choosePic')}
          <input id='logoInput' type="file" accept="image/*" onChange={onSelectFile} />
        </label>
      </div>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        imageStyle={{ maxHeight: 350 }}
      />
      <div>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            display: 'none',
            // width: Math.round(completedCrop?.width ?? 0),
            // height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
      <div
        style={{ justifyContent: 'center', alignContent: 'center' }}
        onClick={handleOnSave}
        className={'BusinessCard--button'}>
        {t('cards.edit.pictureSave')}
      </div>
    </div>
  );
};

export default LoadImage;
