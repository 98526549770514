import React, { ComponentType, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../../Stores';
import AuthUtils from '../../../Utils/AuthUtils';
import AccessDenied from '../Error/AccessDenied';

interface IPrivateRouteProps extends StateProps, RouteComponentProps {
  path: string;
  exact?: boolean;
  accessLevel?: AccessLevel;
  accessCondition?: boolean;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

interface IPrivateRouteState {
  isPreLoading: boolean;
  accessCondition?: boolean;
}

export enum AccessLevel {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
  CUSTOMER = 'ROLE_CUSTOMER',
  COMPANY = 'ROLE_COMPANY',
  PARTNER = 'ROLE_PARTNER',
}

class PrivateRoute extends React.Component<
  IPrivateRouteProps,
  IPrivateRouteState
> {
  constructor(props) {
    super(props);
    this.state = {
      isPreLoading: true,
      accessCondition: props.accessCondition ?? true,
    };
  }

  componentDidMount() {
    this.isPreLoadingTimeout();
  }

  isPreLoadingTimeout = () => {
    setTimeout(() => this.isPreLoading(false), 350);
  };

  isPreLoading = (isPreLoading: boolean) => {
    this.setState({
      isPreLoading,
    });
  };

  checkIfAuthoritiesIncludes = () => {
    const { account, accessLevel } = this.props;

    if (!this.state.accessCondition) {
      return false;
    }

    return accessLevel
      ? account?.authorities?.some((it) => it.name == accessLevel)
      : true;
  };

  renderNonAuthenticatedRoute = () => {
    const { history } = this.props;
    const redirectProps = {
      pathname: '/',
      state: { from: history.location.pathname },
    };
    console.log('redirectProps', {
      redirectProps,
    });
    return <Redirect to={redirectProps} />;
  };

  renderAuthenticatedRoute = () => {
    const { isPreLoading } = this.state;
    const { exact, path, component } = this.props;
    if (isPreLoading) return;
    return (
      <Fragment>
        {this.checkIfAuthoritiesIncludes() ? (
          <Route exact={exact} path={path} component={component} />
        ) : (
          <AccessDenied />
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        {AuthUtils.isAuthenticated()
          ? this.renderAuthenticatedRoute()
          : this.renderNonAuthenticatedRoute()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps))(withRouter(PrivateRoute));
