import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation, WithTranslation } from 'react-i18next';
import { readCSV } from 'src/Utils/csvUtils';

import './styles.scss';

interface Emails {
  email: string;
}
export interface Dropzone extends WithTranslation {
  setEmails?: React.Dispatch<React.SetStateAction<Emails[]>>;
}

const Dropzone: React.FC<Dropzone> = ({ t, setEmails }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    readCSV(acceptedFiles, setEmails, 'E-mail');
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="Dropzone">
      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} type="file" />
        {isDragActive ? (
          <p></p>
        ) : (
          <div>
            <span className="title">{t('business.flux.drag_here')}</span>
            <span>{t('business.flux.or')}</span>
            <span className="link">{t('business.flux.find_archive')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(Dropzone);
