import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import PageTitle from 'src/Components/PageTitle';
import MenuAndContent from 'src/Components/MenuAndContent';
import Benefits from 'src/Components/Benefits';
import Segments from 'src/Components/Segments';
import Contributors from 'src/Components/Contributors';
import './styles.scss';

export type BusinessBenefitsProps = WithTranslation

const BusinessBenefits: React.FC<BusinessBenefitsProps> = ({}) => {
  return (
    <>
      {/* <FixedHeader /> */}
      <PageTitle title="benefits" />
      <MenuAndContent
        redirect="benefits"
        prefix="/benefits/"
        menuOptions={[
          {
            id: 1,
            title: 'benefits',
            component: <Benefits type="!include" />,
          },
          {
            id: 2,
            title: 'segments',
            component: <Segments type="!include" />,
          },
          {
            id: 3,
            title: 'contributors',
            component: <Contributors />,
          },
        ]}
      />
    </>
  );
};

export default BusinessBenefits;
