import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import styled from 'styled-components';

export const SimpleModalContainer = styled(Modal)`
  margin: 10rem auto;
  border-radius: 16px !important;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  div.modal-content {
    border-radius: 16px !important;
    padding-inline: 12px;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  }
`;

export const SimpleModalHeader = styled(ModalHeader)`
  margin-top: 10px;
  font-weight: 400;
  font-family: MullerBold;

  h5 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }
`;

export const SimpleModalBody = styled(ModalBody)`
  font-weight: 400;
  font-family: Muller;
  text-align: center;
`;

export const SimpleModalFooter = styled(ModalFooter)`
  justify-content: flex-end;
`;
