import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import SegmentsCard from 'src/Components/BenefitSegments/SegmentCard';
import { toast } from 'react-toastify';
import { IRootState } from 'src/Stores';
import { CoParticipationSegment } from 'src/Models/Business/CoParticipationSegment';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import { isEmpty } from 'lodash';
import CoParticipationGroupList from 'src/Components/ColaboratorBenefits/CoParticipationGroupList';
import { ReactComponent as Close } from '../../../../Images/Icons/close-gray.svg';

export interface SegmentModalProps extends WithTranslation {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const SegmentModal: React.FC<SegmentModalProps> = ({ t, close, isOpen }) => {
  const customers = useSelector(
    (state) => (state as IRootState).selectCustomer.customers,
  );
  const [coParticipationSegments, setCoParticipationSegments] = useState<
    CoParticipationSegment[]
  >([]);
  const [selectedCoParticipationSegment, setSelectedCoParticipationSegment] =
    useState<CoParticipationSegment>();
  const [selectedGroups, setSelectedGroups] = useState<CoParticipationGroup[]>();

  useEffect(() => {
    const fetch = async () => {
      setCoParticipationSegments((await BusinessSegment.getAllSegments()).data);
    };
    fetch();
  }, []);

  const handleIncludeSegment = (selected: CoParticipationSegment) => {
    setSelectedCoParticipationSegment(selected);
  };

  const handleAddBenefit = () => {
    customers.map(async (customer) => {
      const result = await BusinessCustomerApi.editOneCustomer({
        coParticipationSegment: selectedCoParticipationSegment,
        groups: selectedGroups,
        id: customer.id,
      });
      if (result.status != 200) {
        toast.warn(
          `Falha ao vincular ${customer.email}: ${result?.data?.message}`,
        );
      }
    });
    setTimeout(() => {
      window.location.reload();
      close(false);
    }, 1500);
  };

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader close={<Close onClick={() => close(false)} />}>
          {t('business.segmentModal.title')}
        </ModalHeader>
        <ModalBody>
          <div>
            {t('business.segmentModal.attention1')}
            <span style={{ color: '#25b379' }}>
              {t('business.segmentModal.attention2', {
                count: customers?.length ?? 0,
              })}
            </span>
            {t('business.segmentModal.attention3')}
          </div>
          <div className="benefitRow">
            {coParticipationSegments.map((item, index) => (
              <SegmentsCard
                key={`co-participation-segment-modal-item-${index}`}
                item={item}
                selected={item.id == selectedCoParticipationSegment?.id}
                onSegmentSelected={handleIncludeSegment}
              />
            ))}
          </div>
          <div className="benefitRow">
            {!isEmpty(selectedCoParticipationSegment?.groups) &&
              t('business.segmentModal.changeGroup')}
          </div>
          <div className="benefitRow">
            <CoParticipationGroupList
              groups={selectedCoParticipationSegment?.groups}
              onChange={(groups) => setSelectedGroups(groups)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Col className="no-padding">
            <div className="cancel" onClick={() => close(false)}>
              {t('business.segmentModal.button.cancel')}
            </div>
          </Col>
          <Col className="no-padding">
            <Button className="payment_way" onClick={() => handleAddBenefit()}>
              {t('business.segmentModal.button.confirm')}
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation()(SegmentModal);
