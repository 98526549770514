import styled from 'styled-components';

export enum ScreenDimensions {
  MOBILE = '480px',
  MID_WEB = '1200px',
  MID_TABLET = '768px',
  TABLET = '1080px',
}

export const IconLogin = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 13px;
`;

export const StyledScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    flex-direction: column;
  }
`;

export const LogoBox = styled.div`
  width: 316px;
  height: 32px;
  margin: 32px 0px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: relative;

  img {
    height: 100%;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    margin: 24px 0px 0px 0px;
    width: 280px;
  }
`;

export const StyledTitle = styled.h1`
  width: 316px;
  margin: 64px 0px 32px 0px;

  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #000000;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    width: 280px;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
`;

interface DescriptionProps {
  fontSize?: string;
  color?: string;
  marginTop?: string;
  fontWeight?: string;
}

export const StyledDescription = styled.div<DescriptionProps>`
  width: 316px;
  margin: 12px 0px 0px 0px;
  margin-top: ${(DescriptionProps) =>
    DescriptionProps.marginTop ? DescriptionProps.marginTop : null};
  font-weight: ${(DescriptionProps) =>
    DescriptionProps.fontWeight ? DescriptionProps.fontWeight : '400'};
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  z-index: 1000;
  color: #b6b6b6;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    width: 100vw;
    font-size: ${(DescriptionProps) =>
      DescriptionProps.fontSize ? DescriptionProps.fontSize : '14px'};
    color: ${(DescriptionProps) =>
      DescriptionProps.color ? DescriptionProps.color : '#b6b6b6'};
  }
`;

export const StyledForm = styled.form`
  width: 316px;
  margin: 32px 0px 0px 0px;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    width: 280px;
  }
`;

interface ButtonsProps {
  margin?: string;
}

export const StyledButton = styled.button<ButtonsProps>`
  width: 316px;
  height: 48px;
  position: relative;
  z-index: 5;

  margin: 32px 0px 0px 0px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  overflow: hidden;

  background: transparent;
  border-radius: 25px;
  overflow: hidden;

  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #2fb86f;
    transition: 0.3s;
  }

  &:hover::before {
    left: 0;
  }

  ::after {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #24b379 0%, #455561 100%);
    transition: 0.3s;
  }

  &:hover::after {
    left: 100%;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    width: 280px;
    margin: ${(ButtonsProps) =>
      ButtonsProps.margin ? ButtonsProps.margin : '32px 0px 0px 0px'};
  }
`;

export const LostPassword = styled.p`
  width: 316px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #2fb86f;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    width: 280px;
  }
`;

export const DataContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    width: 100%;
  }
`;
