import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';

import './styles.scss';
import BenefitSelect from 'src/Components/BenefitSelect';
import BenefitCategories from 'src/Components/BenefitCategories';
import RenewalFrequency from 'src/Components/RenewalFrequency';
import BenefitValue from 'src/Components/BenefitValue';
import BenefitIndividualValues from 'src/Components/BenefitIndividualValues';
import RenewingPeriod from 'src/Models/Enums/RenewingPeriod';
import { CreateBenefit } from 'src/Stores/createBenefit/actions';
import { useDispatch } from 'react-redux';
import { constants } from 'buffer';

export type BusinessCreateBenefit = WithTranslation;

const BusinessCreateBenefit: React.FC<BusinessCreateBenefit> = ({ t }) => {
  const dispatch = useDispatch();
  const [benefitName, setBenefitName] = React.useState<string>();
  const [benefitValue, setBenefitValue] = React.useState<number>();
  const [selectedCategories, setSelectedCategories] = React.useState<any[]>([]);
  const [period, setPeriod] = React.useState<RenewingPeriod>(
    RenewingPeriod.MONTH_PERIOD,
  );

  const nextStep = async () => {
    dispatch(
      CreateBenefit({
        maxSpendingGroup: benefitValue ?? 0,
        nameGroup: benefitName,
        renewingPeriod: period,
        segments: [],
        activityCategories: selectedCategories.map((category) => {
          if (!category.maxSpendingCategory) {
            return {
              ...category,
              maxSpendingCategory: benefitValue,
            };
          }
          return category;
        }),
      }),
    );
  };

  function handleOnChangeInvidualValue(id: number, value: number) {
    setSelectedCategories((prev) =>
      prev.map((it) => ({
        ...it,
        maxSpendingCategory:
          it.activityCategory.id == id ? value : it.maxSpendingCategory,
      })),
    );
  }

  return (
    <div className="businessCreateBenefit">
      <BaseHeaderBenefits step="create" to="/overview" />
      <div className="mt-100">
        <BenefitSelect
          placeholder={t('business.flux.benefit_name')}
          name="benefit"
          type="text"
          title={t('business.flux.title')}
          question={t('business.flux.subtitle')}
          value={benefitName}
          setValue={setBenefitName}
        />
        <BenefitCategories
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          maxSpendingGroup={benefitValue}
        />
        <div className="col-8 flex">
          {/* <div className="col-6 pl-0">
            <RenewalFrequency period={period} setPeriod={setPeriod} />
          </div> */}
          <div className="col-6 pr-0">
            <BenefitValue value={benefitValue} setValue={setBenefitValue} />
          </div>
        </div>
        <BenefitIndividualValues
          selectedActivityCategories={selectedCategories}
          onChangeValue={handleOnChangeInvidualValue}
          maxValue={benefitValue}
        />
      </div>
      <BaseFooterBenefits
        text={t('business.flux.go_to_colaborators')}
        hasBackButton="false"
        linkTo="add-colaborators"
        saveFunc={nextStep}
        backTo='/benefits/benefits'
      />
    </div>
  );
};

export default withTranslation()(BusinessCreateBenefit);
