import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import SubcategoryBar from '../SubcategoryBar';
import { ISubcategory } from '../Wallet';
import './styles.scss';
import StringUtils from 'src/Utils/StringUtils';

export interface SubCategoriesCardProps extends WithTranslation {
  title: string;
  total: number
  subcategories: ISubcategory[];
  color: string;
}

const SubCategoriesCard: React.FC<SubCategoriesCardProps> = ({
  t,
  total,
  title,
  subcategories,
  color,
}) => {
  const [counter, setCounter] = useState<number>(0);
  const observerRef = React.useRef(null);

  const handleCounter = () => {
    if (counter < 100) {
      setCounter(prev => prev + 1);
    }
  };

  useEffect(() => {
    const createInterval = () => {
      return setInterval(() => {
        handleCounter();
      }, 30);
    };

    let interval: NodeJS.Timeout;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          interval = createInterval();
        }
      });
    });
    observer.observe(observerRef.current);
    // cleanup function
    return () => {
      observer.disconnect();
      clearInterval(interval);
    };
  },[])

  const handleOthers = () => {
    const others = subcategories.slice(4);
    const othersCount = others.reduce((acc, curr) => acc + curr.count, 0);
    const percent = (othersCount * 100 / total).toFixed(1) + '%';
    
    return <SubcategoryBar color={color} subcategory={"Outros"} perCent={percent}/>
  }    

  return (
    <Card className="SubCategory">
      <div className="SubCategory--cardName">{t('wallet.wallet.cards.SubCategories')}</div>
      <div className="SubCategory--title">{StringUtils.characterLimit(title, 15)}</div>
      <div ref={observerRef} style={{ width: "100%"}}>
        {subcategories.slice(0, 4).map((subcategory) => {
          const { key, count } = subcategory
          const percentN = count * 100 / total
          const percent = (percentN > counter ? counter : percentN.toFixed(1)) + '%'
          
          return <SubcategoryBar color={color} key={key} category={title} subcategory={key} perCent={percent}/>
        })}
        {subcategories.length > 4 && handleOthers()}
      </div>
    </Card>
  );
};

export default withTranslation()(SubCategoriesCard);
