import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleModal from 'src/Components/SimpleModal';
import { Company } from 'src/Models/Company';
import { Edit } from '@material-ui/icons';
import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { IImage } from 'src/Models/IImage';
import { updateCompanyData } from 'src/Stores/company/action';
import { getSessionRequest } from 'src/Stores/authentication/actions';
import { TextField } from '@material-ui/core';
import LoadImage from 'src/Components/LoadImage/LoadImage';

export interface CompanyInfo {
  name: string;
  socialReason: string;
  cnpj: string;
}

interface BusinessEditModalProps {
  data: Company;
  onConfirm: (CompanyInfo, IImage) => void;
  onClose: () => void;
  isOpen: boolean;
}

const BusinessEditModal = ({ data, onClose, onConfirm, isOpen }: BusinessEditModalProps) => {
  const { t } = useTranslation();
  const [companyEditing, setCompanyEdit] = useState<CompanyInfo>({
    name: '',
    socialReason: '',
    cnpj: ''
  });
  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [image, setImage] = useState<IImage>(null);
  const [loading, setLoading] = useState<boolean>(false);
  

  useEffect(() => {
    setCompanyEdit({
      name: data.name,
      socialReason: data.socialReason,
      cnpj: data.cnpj
    });
    setImage(data.image);
  }, [data]);

  function handleOnClick() {
    setShowCropper(true);
  }
  function handleOnSaveImage(newImage: IImage) {
    setShowCropper(false);
    setImage(newImage);
  }

  function handleChange (e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;
    setCompanyEdit(prev => ({...prev, [name]: value}))
  }

  const handleSubmit = (e) => {
    e?.preventDefault?.();
    onConfirm(companyEditing, image);
    setLoading(true);
  };

  const imgSrc = image?.imageUrl || `data:image/png;base64,${image?.file}`;
  return (
    <SimpleModal
      isOpen={isOpen}
      labelConfirm={t('modalOptions.save')}
      labelCancel={t('modalOptions.cancel')}
      title={t('cards.business.edit')}
      onConfirm={handleSubmit}
      onCancel={onClose}
    >
      <div className="EditCard">
        <div className="EditCard--logotipo">
          <div className='edit-logo-container'>
            <img src={imgSrc ?? ''} alt="" />
          </div>
          <p onClick={handleOnClick}>
            <Edit />
            {t('cards.edit.picture')}
          </p>
        </div>
        {showCropper ? (
          <div className='edit-logo-box'>
            <LoadImage onSave={handleOnSaveImage} />
            <button onClick={() => setShowCropper(false)}>Cancelar</button>
          </div>
        ) : (
          <div className="form-container">
            <div className='input-container'>
              <TextField
                label={t('cards.business.fantasy')}
                className="EditCard--input"
                name="name"
                onChange={handleChange}
                value={companyEditing.name}
              />
            </div>

            <div className='input-container'>
              <TextField
                label={t('cards.business.social')}
                className="EditCard--input"
                name="socialReason"
                onChange={handleChange}
                value={companyEditing.socialReason}
              />
            </div>

            <div className='input-container'>
              <TextField
                label={t('cards.business.cnpj')}
                className="EditCard--input"
                name="cnpj"
                onChange={handleChange}
                value={companyEditing.cnpj}
              />
            </div>
          </div>
        )}
    </div>
    </SimpleModal>
  )
}

export default BusinessEditModal;