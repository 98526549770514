import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BenefitSelect from 'src/Components/BenefitSelect';
import BenefitCategories from 'src/Components/ColaboratorBenefits';
import ListColaborators from 'src/Components/ListColaborators';
import LoadingContainerScreen from 'src/Components/loading-container-screen/loading-container-screen';
import SimpleModal from 'src/Components/SimpleModal';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import ToastService from 'src/Services/ToastService';
import './styles.scss';
import { CoParticipationSegment } from 'src/Models/Business/CoParticipationSegment';
import { CoParticipationCustomer } from 'src/Models/Business/CoParticipationCustomer';
import { NoShowProps } from '../BusinessNewSegments';

export type BusinessEditSegmentProps = WithTranslation;

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: 482,
    backgroundColor: '#FFF',
    padding: 15,
    borderRadius: 15,
    margin: 'auto',
    top: '40%',
    left: '36%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  close: {
    cursor: 'pointer',
  },
});

const BusinessEditSegments: React.FC<BusinessEditSegmentProps> = ({ t }) => {
  const history = useHistory();

  const { id } = useParams() as { id: string };

  // @ts-ignore
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [segmentName, setSegmentName] = React.useState<string>();
  const [segmentCustomersSize, setSegmentCustomersSize] = React.useState<number>();
  const [segmentCustomers, setSegmentCustomers] = React.useState<CoParticipationCustomer[]>([]);
  const [segmentBenefits, setSegmentBenefits] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [noShowProps, setNoShowProps] = React.useState<NoShowProps>({
    noShowActive: false,
    noShowTimesForBlock: 0,
    noShowMonthsBlocked: 0,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getSegment();
  }, [id]);

  const getSegment = async () => {
    if (id != null) {
      const segment: CoParticipationSegment = (
        await BusinessSegment.getOneSegment(parseInt(id))
      ).data;
      setSegmentCustomersSize(segment.customersSize);
      setSegmentName(segment.nameSegment);
      setSegmentCustomers(segment.customers);
      setSegmentBenefits(segment.groups);
      setLoading(false);
      setNoShowProps({
        noShowActive: segment.noShowActive,
        noShowTimesForBlock: segment.noShowTimesForBlock ?? 0,
        noShowMonthsBlocked: segment.noShowMonthsBlocked ?? 0,
      });
    }
  };

  const handleIncludeBenefit = (selected: boolean, benefit: any) => {
    if (selected) {
      const benefits = segmentBenefits.filter(
        (item: any) => item.id !== benefit.id,
      );
      setSegmentBenefits(benefits);
    } else {
      setSegmentBenefits((benefits) => [...benefits, benefit]);
    }
  };

  const updateSegment = async () => {
    if (loading == true) {
      return;
    }
    const { noShowActive, noShowMonthsBlocked, noShowTimesForBlock } =
      noShowProps ?? {};
    if (
      noShowActive &&
      (noShowTimesForBlock < 1 ||
        noShowMonthsBlocked < 1 ||
        !noShowMonthsBlocked ||
        !noShowTimesForBlock)
    ) {
      ToastService.error(
        'Erro ao editar segmento, número de faltas para bloqueio inválido.',
      );
      return;
    }

    setLoading(true);
    const result = await BusinessSegment.editOneSegment({
      id: parseInt(id),
      customers: segmentCustomers,
      groups: segmentBenefits,
      nameSegment: segmentName,
      noShowActive,
      noShowTimesForBlock,
      noShowMonthsBlocked,
    });
    setLoading(false);
    if (result.status <= 201) {
      history.replace('/benefits/segments');
    } else {
      ToastService.error(result.data.details ?? 'Erro ao editar segmento.');
    }
  };

  const deleteSegment = async () => {
    if (loading == true) {
      return;
    }
    setLoading(true);
    const result = await BusinessSegment.deleteOneSegment(parseInt(id));
    setLoading(false);
    if (result.status == 200) {
      ToastService.success('Excluido com sucesso!');
      setTimeout(() => {
        history.replace('/benefits/segments');
      }, 500);
    } else {
      ToastService.error(result.data.details ?? 'Falha ao excluir o segmento.');
    }
  };

  const body = (
    <div className={classes.paper}>
      <div className="modalEditBusiness">
        <div className={classes.header}>
          <p id="simple-modal-title" className="modalTitle">
            {t('business.flux.delete_segment')}
          </p>
          <CloseIcon className={classes.close} onClick={handleClose} />
        </div>
        <div className="underline" />

        <p id="simple-modal-description" className="textModalDelete">
          {t('business.flux.modalTextSegment', {
            customerCount: segmentCustomersSize ?? segmentCustomers?.length,
          })}
        </p>

        <div className="footerModal">
          <span className="cancel" onClick={handleClose}>
            {t('business.flux.cancel')}
          </span>
          <span className="buttonModal" onClick={deleteSegment}>
            {t('business.flux.delete_segment')}
          </span>
        </div>
      </div>
      <SimpleModal />
    </div>
  );

  useEffect(() => {
    if (segmentCustomers.some((it) => it.groups === undefined)) {
      setSegmentCustomers((prev) =>
        prev.map((it) => ({
          ...it,
          groups: segmentBenefits,
        })),
      );
    }
  }, [segmentCustomers]);

  return (
    <LoadingContainerScreen isLoading={loading}>
      <div className="businessEditSegments">
        <BaseHeaderBenefits
          step="create"
          color="green"
          title="Editar Segmento"
          to="/benefits/segments"
        />
        <div className="mt-100">
          <BenefitSelect
            placeholder="Nome do segmento"
            name="benefit"
            type="text"
            title="Nome do Segmento"
            question="O que é o nome do segmento"
            isSegment
            value={segmentName}
            setValue={setSegmentName}
            noShowProps={noShowProps}
            setNoShowProps={setNoShowProps}
          />
          <ListColaborators
            customers={segmentCustomers}
            segmentName={segmentName}
            setSegmentCustomers={setSegmentCustomers}
            deleteOrEdit="delete"
          />
          <BenefitCategories
            benefits={segmentBenefits}
            onChange={(items) => {
              setSegmentBenefits(items);
              setSegmentCustomers((prev) =>
                prev.map((it) => ({
                  ...it,
                  groups: items,
                })),
              );
            }}
          />
          <div className="mb-40" />
        </div>
        <div className="delete-benefit">
          <DeleteOutlineOutlinedIcon />
          <span className="ml-10" onClick={handleOpen}>
            {t('business.flux.delete_segment')}
          </span>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        <BaseFooterBenefits
          disabled={loading}
          hidden={loading}
          text="Salvar alterações"
          hasBackButton="true"
          textBack="Cancelar"
          saveFunc={updateSegment}
          backTo="/benefits/segments"
        />
      </div>
    </LoadingContainerScreen>
  );
};

export default withTranslation()(BusinessEditSegments);
