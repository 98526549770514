import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Themes/_toasts.scss';
import { compose } from 'redux';
import './App.scss';
import FixedHeader from './Components/FixedHeader';
import LoadingContainerScreen from './Components/loading-container-screen/loading-container-screen';
import { HttpRequestStatus } from './Models/Enums/HttpRequestStatus';
import AppRoutes from './Routes/AppRoutes';
import { IRootState } from './Stores';
import {
  getSessionRequest,
  logoutRequest,
} from './Stores/authentication/actions';
import AuthUtils from './Utils/AuthUtils';

const baseHref = '/';

interface IAppProps extends StateProps, DispatchProps {}

interface IAppState {
  isLoading: boolean;
}

class App extends React.Component<IAppProps, IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    if (AuthUtils.isAuthenticated()) {
      this.props.getSession();
    } else {
      this.isLoading(false);
    }
  }

  componentWillReceiveProps(nextProps: Readonly<IAppProps>) {
    if (nextProps.getSessionStatus === HttpRequestStatus.SUCCESS) {
      this.isLoading(false);
    }

    if (nextProps.getSessionStatus === HttpRequestStatus.ERROR) {
      this.props.logout();
      this.isLoading(false);
    }
  }

  isLoading = (isLoading: boolean) => {
    this.setState({
      isLoading,
    });
  };

  renderApp = (appProps: RouteComponentProps<any>) => {
    const { isLoading } = this.state;
    const { isAuthenticated, account } = this.props;
    const notAllowedPathname: string[] = ['/register', '/'];
    const checkIfIsAuthenticated =
      isAuthenticated &&
      !notAllowedPathname.includes(appProps.location.pathname);
    return (
      <div className={'App'}>
        <LoadingContainerScreen isLoading={isLoading}>
          {checkIfIsAuthenticated && (
            <FixedHeader
              {...this.props}
              {...appProps}
              username={account?.name || ''}
            />
          )}
          <Route
            path={'/'}
            component={() => (
              <AppRoutes
                {...appProps}
                isAuthenticated={checkIfIsAuthenticated}
              />
            )}
          />
        </LoadingContainerScreen>
      </div>
    );
  };

  render() {
    return (
      <div className={'page-container'}>
        <BrowserRouter basename={baseHref}>
          <Route render={this.renderApp} />
        </BrowserRouter>
        <ToastContainer
          draggable
          rtl={false}
          closeOnClick
          pauseOnHover
          autoClose={5000}
          newestOnTop={false}
          className={'toast-wrapper'}
          position={toast.POSITION.TOP_RIGHT}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  account: state.authentication.account,
  isAuthenticated: state.authentication.isAuthenticated,
  getSessionStatus: state.authentication.getSessionStatus,
});

const mapDispatchToProps = {
  logout: logoutRequest,
  getSession: getSessionRequest,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  App,
) as React.ComponentType<any>;
