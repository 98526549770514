import jwt_decode from 'jwt-decode';
import { AUTHORITIES } from 'src/Config/Constants';
import { DEFAULT_ROUTE_BUSINESS } from 'src/Routes/AppRoutes';

export interface DecodeJwt {
  auth: AUTHORITIES;
  exp: number;
  sub: string;
  uuid: string;
}

class AuthUtils {
  static AUTH_TOKEN_KEY = 'jhi-authenticationToken';

  static isAuthenticated = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY) !== null;
  };

  static isCustomer = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    return jwt_decode<DecodeJwt>(AuthUtils.getToken()!).auth === AUTHORITIES.CUSTOMER;
  };

  static isBusiness = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    
    return jwt_decode<DecodeJwt>(AuthUtils.getToken()!).auth === AUTHORITIES.COMPANY;
  };

  static isAdmin = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    
    return jwt_decode<DecodeJwt>(AuthUtils.getToken()!).auth === AUTHORITIES.ADMIN;
  };

  static getToken = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY);
  };

  static setToken = (token: string) => {
    localStorage.setItem(AuthUtils.AUTH_TOKEN_KEY, token);
  };

  static removeToken = () => {
    localStorage.removeItem(AuthUtils.AUTH_TOKEN_KEY);
  };

  static getMainPath = () => {
    // return AuthUtils.isAdmin() ? '/admin' : AuthUtils.isUser() ? '/home' : '/';
    return AuthUtils.isBusiness() ? DEFAULT_ROUTE_BUSINESS : '/';
  };
}

export default AuthUtils;
