import React, { useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Wallet from 'src/Components/Wallet';
import Extract from 'src/Components/Extract';
import PageTitle from 'src/Components/PageTitle';
import { IRootState } from 'src/Stores';
import MenuAndContent from 'src/Components/MenuAndContent';
import PayrollDiscount from 'src/Components/PayrollDiscount';
import BusinessWalletReport from 'src/Components/BusinessWalletReport';
import { getCompanyReports } from 'src/Stores/company/action';

export type BusinessWalletProps = WithTranslation;

const BusinessWallet: React.FC<BusinessWalletProps> = ({}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyReports({ page: 0, size: 20 }));
  }, []);

  const companyReports = useSelector(
    (state: IRootState) => state.companyState.reportsPage,
  )?.content;
  const reportsDisabled = !companyReports || companyReports.length === 0;

  return (
    <>
      {/* <FixedHeader /> */}
      <PageTitle title="wallet" />
      <MenuAndContent
        redirect="wallet"
        prefix="/wallet/"
        menuOptions={[
          {
            id: 1,
            title: 'wallet',
            component: <Wallet />,
          },
          {
            id: 2,
            title: 'extract',
            component: <Extract />,
          },
          {
            id: 3,
            title: 'payrollDiscount',
            component: <PayrollDiscount />,
          },
          {
            id: 4,
            title: 'report',
            disabled: reportsDisabled,
            component: <BusinessWalletReport />,
          },
        ]}
      />
    </>
  );
};

export default BusinessWallet;
