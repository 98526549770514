import BusinessAccount, { ChangePass } from 'src/Services/Api/BusinessAccount';
import { AxiosResponse } from 'axios';
import { checkApiStatus } from 'src/Utils/ApiUtils';
import CompanyApi from './Api/CompanyApi';

class BusinessService {
  
  static changePass = async (passObj: ChangePass): Promise<AxiosResponse> => {
    try {
      const result = await BusinessAccount.changePass(passObj);
      if(result.status === 400){
        return Promise.reject(result.data);
      }
      return Promise.resolve(result);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  }

}

export default BusinessService;