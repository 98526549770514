import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import BenefitsCard from '../Benefits/BenefitsCard';
import CustomIconTextButton from '../CustomIconTextButton';
import SegmentsCard from './SegmentsCard';
import './styles.scss';

export interface SegmentsProps extends WithTranslation {
  type?: string;
  onClick?: () => void;
  segmentsProps?: any[];
}

const Segments: React.FC<SegmentsProps> = ({
  t,
  type,
  onClick,
  segmentsProps,
}) => {
  const [segments, setSegments] = useState<any>(segmentsProps || []);

  const getSegments = async () => {
    setSegments((await BusinessSegment.getAllSegments()).data);
  };

  useEffect(() => {
    if (!segmentsProps) getSegments();
  }, []);

  return (
    <Container className="no-padding all-segment-row ">
      {segments?.map((segment, index) => (
        <SegmentsCard
          key={`segments-card-${segment?.id}-${index}`}
          linkTo={`/edit-segment/${segment?.id}`}
          categories={segment?.groups?.map((group) => group.nameGroup) ?? []}
          nContributors={segment?.customersSize ?? segment?.customers?.length ?? 0}
          segment={segment.nameSegment}
        />
      ))}
      <CustomIconTextButton
        newDescription="segment"
        url="/new-segment"
        type={type}
        onClick={onClick}
      />
    </Container>
  );
};

export default withTranslation()(Segments);
