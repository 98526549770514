import { Tooltip } from '@material-ui/core';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import './styles.scss';

interface LinkComponent {
  id: number;
  title: string;
  component: React.ReactNode;
  comingSoon?: boolean;
  disabled?: boolean;
}
export interface MenuAndContentProps extends WithTranslation {
  prefix: string;
  redirect: string;
  menuOptions: LinkComponent[];
}

const MenuAndContent: React.FC<MenuAndContentProps> = ({
  t,
  menuOptions,
  prefix,
  redirect,
}) => {
  return (
    <>
      <div className="menu-container">
        <Container className="no-padding">
          <Row>
            {menuOptions.map((menu) => (
              <div key={menu.id} className="position-relative">
                {menu.comingSoon == true ? (
                  <Tooltip title="Em breve">
                    <a className="menu-title">{t(`menu.${menu.title}`)}</a>
                  </Tooltip>
                ) : menu.disabled ? (
                  <Tooltip title="Indisponível">
                    <a className="menu-title">{t(`menu.${menu.title}`)}</a>
                  </Tooltip>
                ) : (
                  <NavLink
                    to={prefix + menu.title}
                    className="menu-title"
                    activeClassName="menu-title-active">
                    {t(`menu.${menu.title}`)}
                  </NavLink>
                )}
              </div>
            ))}
          </Row>
        </Container>
      </div>
      <div className="Content">
        <Switch>
          <Redirect exact from={`${prefix}`} to={prefix + redirect} />
          {menuOptions.map((menu) => (
            <Route key={menu.id} exact path={prefix + menu.title}>
              {menu.component}
            </Route>
          ))}
        </Switch>
      </div>
    </>
  );
};

export default withTranslation()(MenuAndContent);
