import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row, Button, ModalBody, ModalFooter } from 'reactstrap';
import { STATES } from 'src/Utils/BrazilStates';

export interface InfosProps extends WithTranslation {
  toggle: (main: string, step?: string) => void;
  companyWallet: string;
}

const Infos: React.FC<InfosProps> = ({ t, toggle, companyWallet }) => {
  return (
    <>
      <ModalBody className="WalletModal__body">
        {companyWallet === 'fund' ? (
          <input
            className={`value ${companyWallet}`}
            type="text"
            placeholder="Valor do saldo "></input>
        ) : (
          <div className={`value ${companyWallet}`}>
            <div className="value_col">
              <div className="description">Valor a ser pago:</div>
              <div className="total">R$20.000,00</div>
            </div>
          </div>
        )}
        <div className="subtitle">Dados do Pagador</div>
        <Row className="no-padding">
          <label className="one">
            <div className="description">Nome/ Razão Social</div>
            <input type="text" />
          </label>
          <label className="one">
            <div className="description">CPF/ CNPJ</div>
            <input type="text" />
          </label>
        </Row>
        <Row className="no-padding">
          <label className="two">
            <div className="description">CEP</div>
            <input type="text" />
          </label>
          <label className="four">
            <div className="description">Rua</div>
            <input type="text" />
          </label>
          <label className="one">
            <div className="description">Número</div>
            <input type="text" />
          </label>
        </Row>
        <Row className="no-padding">
          <label className="two">
            <div className="description">Bairro</div>
            <input type="text" />
          </label>
          <label className="two">
            <div className="description">Cidade</div>
            <input type="text" />
          </label>
          <label className="one">
            <div className="description">Estado</div>
            <select>
              {STATES.map((state) => (
                <option value={state}>{state}</option>
              ))}
            </select>
          </label>
        </Row>
      </ModalBody>
      <ModalFooter className="WalletModal__footer">
        <Col className="no-padding">
          <div className="cancel" onClick={() => toggle('')}>
            Cancelar
          </div>
        </Col>
        <Col className="no-padding">
          <Row className="no-padding payment_way_row">
            <Button
              className="payment_way"
              onClick={() => toggle(companyWallet, 'pix')}>
              Pagar com PIX
            </Button>
            <Button
              className="payment_way"
              onClick={() => toggle(companyWallet, 'ticket')}>
              Pagar com Boleto
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </>
  );
};

export default withTranslation()(Infos);
