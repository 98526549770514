import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import './styles.scss';

export interface BusinessForgotPassword extends WithTranslation {}

const BusinessForgotPassword: React.FC<BusinessForgotPassword> = ({ t }) => {
  return (
    <div className="BusinessForgotPassword">
      <div className="bg">
        <div className="loginBox">
          <div>
            <span className="title">
              {t('business.register.forgot_passwd')}
            </span>
            <br />
            <span className="subtitle">
              {t('business.register.retype_email')}
            </span>
            <div className="input">
              <input
                type="text"
                placeholder={t('business.register.inform_email')}
                name="email"
              />
            </div>
            <button className="button">{t('business.register.recover')}</button>
            <Link to={'login'} className="span">
              {t('business.register.back')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BusinessForgotPassword);
