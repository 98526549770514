import { useState, useEffect } from 'react';

type ViaCepAddress = {
  cep: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade: string;
  uf: string;
  unidade?: string;
  ibge: string;
  gia?: string;
}

export default function useViaCep (initialCep = "") {
  const [ cep, setCep ] = useState<string>(initialCep)
  const [ cepObj, setCepObj ] = useState<ViaCepAddress>(null)
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<boolean>(false);
  const [ counter, setCounter ] = useState<number>(0); 

  const fetchCep = () => {
    const regexedCep = cep.replace(/\D/g, '')
    if (regexedCep.length !== 8) {
      setCepObj(null)
      return
    }
    setCounter(prev => prev + 1);
    if(counter > 10){
      return;
    }
    setIsLoading(true);
    fetch(`https://viacep.com.br/ws/${regexedCep}/json`)
      .then(resp => resp.json())
        .then(data => {
          if(data.erro){
            setCepObj(null);
            setError(true);
            return
          }
          setCepObj(data);
          setError(false);
      }).
      catch(err => {
        setError(true);
        setCepObj(null)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    fetchCep();
  }, [cep])

  return [setCep, cepObj, isLoading, error, fetchCep] as const;
}