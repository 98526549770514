import { Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import CustomBoxCheckBox from 'src/Components/CustomBoxCheckBox';
import { CoParticipationCustomer } from 'src/Models/Business/CoParticipationCustomer';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { selectCustomer } from 'src/Stores/selectedCustomers/actions';
import Images from 'src/Themes/Constants/Images';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import { toast } from 'react-toastify';
import PayrollDicountService from 'src/Services/payrollDiscountService';
import { PayrollDicountType } from 'src/Models/payrollDiscountType';
import { ReactComponent as Arrow } from '../../../Images/Icons/arrow.svg';

export interface ContributorProps extends WithTranslation {
  contributor: CoParticipationCustomer;
  setContributorsSelected: React.Dispatch<React.SetStateAction<any[]>>;
  contributorsSelected: any[];
}

const Contributor: React.FC<ContributorProps> = ({
  t,
  contributor,
  contributorsSelected,
  setContributorsSelected,
}) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleSelect = () => {
    if (!contributorsSelected.find((item) => item === contributor)) {
      const customers = [...contributorsSelected, contributor];
      setContributorsSelected(customers);
      dispatch(selectCustomer(customers as CoParticipationCustomer[]));
    } else {
      const customers = contributorsSelected.filter(
        (item) => item !== contributor,
      );
      setContributorsSelected(customers);
      dispatch(selectCustomer(customers as CoParticipationCustomer[]));
    }
  };

  useEffect(() => {
    if (!contributorsSelected.find((item) => item === contributor)) {
      return setIsChecked(false);
    }
    return setIsChecked(true);
  }, [contributorsSelected]);

  useEffect(() => {
    if (contributor.payrollDiscountEnabled) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [contributor.payrollDiscount]);

  const handleDelete = (id: number) => {
    BusinessCustomerApi.deleteCoParticipationCustomers([{ id }])
      .then((res) => {
        if (res.status == 200) {
          toast.success('Colaborador removido com sucesso!', {
            onClose: () => {
              window.location.reload();
            },
          });
        } else {
          toast.warn('Falha ao remover colaborador');
        }
      })
      .catch((err) => {
        toast.warn('Falha ao remover colaborador');
        console.error(err);
      });
  };

  const handleUpdate = () => {
    setIsActive(!isActive);

    const updatePayroll: PayrollDicountType[] = [
      {
        id: contributor.id,
        payrollDiscountEnabled: !isActive,
      },
    ];
    PayrollDicountService.updatePayroll(updatePayroll);
  };

  return (
    <>
      <div className="Contributor">
        <div>
          <Row style={{ width: '300px', alignItems: 'center' }}>
            <CustomBoxCheckBox
              onClick={() => handleSelect()}
              isChecked={isChecked}
            />
            <div className="Contributor--photo" style={{ scale: '1.2' }}>
              <img src={Images.icons.defaultPerfil} alt="perfil photo" />
            </div>
            <div className="Contributor__personalInfos">
              <div className="Contributor__personalInfos--name">
                {contributor?.customer?.name || t('Contributors.noName')}
              </div>
              <div className="Contributor__personalInfos--email">
                {contributor.email}
              </div>
            </div>
          </Row>
        </div>
        <div className="Contributor__followUp">
          <Row>
            <div className="Contributor__segment">
              {contributor.coParticipationSegment?.nameSegment ||
                'Sem segmento'}
            </div>
          </Row>
        </div>
        <div className="Contributor__benefits">
          <Row>
            {contributor.groups.slice(0, 3).map((group) => (
              <div
                key={group.id}
                className="Contributor__segment benefit-segment">
                {group.nameGroup}
              </div>
            ))}
            {contributor.groups.length > 3 && (
              <div className="Contributor__segment benefit-segment">
                + {contributor.groups.length - 3}
              </div>
            )}
          </Row>
          {/* <div className="delete-benefit">
            <DeleteOutlineOutlinedIcon />
            <span className="ml-10" onClick={() => {}} />
          </div> */}
        </div>
        <div className="Contributor_payrollDiscount">
          <label className="switch">
            <input
              type="checkbox"
              onChange={() => handleUpdate()}
              checked={isActive}
            />
            <span className="slider round" />
          </label>
        </div>
        <div className="Contributor_actions">
          <div
            className="Contributor__delete"
            onClick={() => handleDelete(contributor.id)}>
            <DeleteOutlineOutlinedIcon />
          </div>
          <Link
            to={{
              pathname: `/contributors/${contributor.id}`,
              state: { customer: contributor },
            }}
            className="Contributor__edit">
            Editar <Arrow />
          </Link>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Contributor);
