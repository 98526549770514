import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import { CoParticipationSegment } from 'src/Models/Business/CoParticipationSegment';
import BusinessGroupBenefits from 'src/Services/Api/BusinessGroupBenefits';
import CustomIconTextButton from '../CustomIconTextButton';
import BenefitsCard from './BenefitsCard';
import './styles.scss';

export interface BenefitsProps extends WithTranslation {
  benefits?: CoParticipationGroup[];
  type?: string;
  onClick?: any;
}

const Benefits: React.FC<BenefitsProps> = ({
  t,
  benefits: benefitsProps,
  type,
  onClick,
}) => {

  const handleContributorsValue = (segments: CoParticipationSegment[]) => {
    const nContributors = segments.reduce((prev, curr) => prev + (curr?.customersSize ?? curr?.customers?.length), 0);
    return `${nContributors}`;
  };

  const [benefits, setBenefits] = useState<any>(benefitsProps);
  const getBenefits = async () => {
    setBenefits((await BusinessGroupBenefits.getAllGroupBenefits()).data);
  };

  useEffect(() => {
    if (benefitsProps != null) {
      setBenefits(benefitsProps);
    } else if (!benefits) {
      getBenefits();
    }
  }, [benefitsProps]);
  
  return (
    <Container className="all-benefit-row">
      {benefits?.map?.((benefit, index) => (
        <BenefitsCard
          key={`benefits-card-${benefit.id}-${index}`}
          title={benefit.nameGroup}
          value={benefit.maxSpendingGroup}
          linkTo={`/edit-benefit/${benefit.id}`}
          nCategories={benefit?.activityCategories?.length?.toString?.()}
          nContributors={handleContributorsValue(benefit?.segments)}
          segments={benefit.segments.map((segment) => segment.nameSegment)}
          renovate={benefit?.renewingPeriod}
        />
      ))}
      <CustomIconTextButton
        newDescription="benefit"
        url="/new-benefit"
        type={type}
        onClick={onClick}
      />
    </Container>
  );
};

export default withTranslation()(Benefits);
