import { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { all, call, fork, put, takeEvery, delay } from 'redux-saga/effects';
import { AUTHORITIES } from 'src/Config/Constants';
import { Authentication } from 'src/Models/authentication';
import { CompanyUser } from 'src/Models/CompanyUser';
import { HttpResponseError, HttpResponseTypeError } from 'src/Models/Utils/HttpReponseError';
import authenticationApi from 'src/Services/Api/AuthenticationApi';
import AuthUtils, { DecodeJwt } from 'src/Utils/AuthUtils';
import { getSessionError, getSessionRequest, getSessionSuccess, loginError, loginRequest, loginSuccess } from './actions';
import { AuthenticationActionTypes } from './types';

const mapToHttpError = (status: number, result?: AxiosResponse<Authentication>) => {
  if (status === 400 && result?.data?.code != null) {
    const fields = result.data.code;
    if (fields.includes('password') && fields.includes('username')) {
      return {
        status,
        type: HttpResponseTypeError.LOGIN_ERROR_USER_AND_PASSWORD
      };
    }
    return {
      status,
      type: fields.includes('password') ? HttpResponseTypeError.LOGIN_ERROR_PASSWORD : HttpResponseTypeError.LOGIN_ERROR_USER
    };
  }
  if (status === 401) {
    return {
      status,
      type: HttpResponseTypeError.LOGIN_ERROR_PASSWORD
    };
  }

  if (status === 404) {
    return {
      status,
      type: HttpResponseTypeError.LOGIN_ERROR_USER
    };
  }

  return {
    type: HttpResponseTypeError.UNKNOWN,
    message: 'login.error.unknown'
  };
};

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  let result: AxiosResponse<Authentication> | null;
  try {
    result = yield call(authenticationApi.login, action.payload);
    if (result?.status !== 200 && result?.data.token == null) {
      const error: HttpResponseError = mapToHttpError(result?.status, result);
      yield put(loginError(error));
      return;
    }

    const jwt: string = result?.data.tokenId!;
    const decodeJwt = jwt_decode<DecodeJwt>(jwt);
    
    if (decodeJwt.auth === AUTHORITIES.COMPANY) {
      AuthUtils.setToken(jwt);
      yield put(getSessionRequest());
      yield put(loginSuccess());
      return;

    } else if (decodeJwt.auth === AUTHORITIES.PARTNER) {
      yield put(loginError({
        type: HttpResponseTypeError.LOGIN_ERROR_PARTNER_LOGIN,
        message: 'No company user',
      }));
      return;

    } else {
      yield put(loginError({
        type: HttpResponseTypeError.LOGIN_ERROR_CUSTOMER_LOGIN,
        message: 'No company user'
      }))
    }

  } catch (err) {
    if (err?.response?.status != null) {
      yield put(loginError(mapToHttpError(err.response.status)));
    } else if (err instanceof Error && err.stack) {
      yield put(loginError({ message: err.stack }));
    } else {
      yield put(loginError({ message: 'An unknown error occured.' }));
    }
  }
}

function* handleGetSession() {
  try {
    const sessionRequest =  authenticationApi.getSession;

    const result: AxiosResponse<CompanyUser> = yield call(sessionRequest);

    if (result.status === 200 && result.data != null) {
      yield put(getSessionSuccess(result.data));
    } else {
      yield put(getSessionError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getSessionError(err.stack));
    } else {
      yield put(getSessionError('An unknown error occured.'));
    }
  }
}

function* watchLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* watchGetSessionRequest() {
  yield takeEvery(AuthenticationActionTypes.GET_SESSION_REQUEST, handleGetSession);
}

function* authenticationSaga() {
  yield all([fork(watchLoginRequest), fork(watchGetSessionRequest)]);
}

export default authenticationSaga;
