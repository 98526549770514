

import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';

export enum CreateNewBenefitTypes {
  CREATE_BENEFIT = '@@groupBenefit/CREATE',
}

export interface CreateNewBenefitState  {
  readonly benefit: CoParticipationGroup;
}
