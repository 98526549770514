import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import CheckIcon from '@material-ui/icons/Check';

import './styles.scss';
import { Link } from 'react-router-dom';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import { CoParticipationSegment } from 'src/Models/Business/CoParticipationSegment';
import SegmentCard from './SegmentCard';
import Segments from '../Segments';

export interface BenefitSegmentsProps extends WithTranslation {
  segments?: CoParticipationSegment[];
  onChange?: (items: CoParticipationSegment[]) => void;
}

const BenefitSegments: React.FC<BenefitSegmentsProps> = ({
  t,
  segments,
  onChange,
}) => {
  const [showMore, setshowMore] = useState<boolean>(false);
  const [showSelectedSegments, setShowSelectedSegments] =
    useState<boolean>(false);
  const [allSegments, setAllSegments] = useState<CoParticipationSegment[]>([]);

  const [selecteds, setSelecteds] = useState<CoParticipationSegment[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await BusinessSegment.getAllSegments();
      setAllSegments(data);
    })();
  }, []);

  useEffect(() => {
    setSelecteds(segments ?? []);
  }, [segments]);

  function handleSegmentSelected(item: CoParticipationSegment) {
    if (!selecteds.some((it) => it.id == item.id)) {
      setSelecteds((prev) => {
        const newValue = [...prev, item];
        onChange(newValue);
        return newValue;
      });
    } else {
      setSelecteds((prev) => {
        const newValue = prev.filter((it) => it.id != item.id);
        onChange(newValue);
        return newValue;
      });
    }
  }

  return (
    <div className="col-8 mt-40 BenefitCategories">
      <span className="title">{t('business.flux.included_segments')}</span>
      <Link to="new-segment" className="headerBtns item ">
        + {t('business.flux.create_new_segment')}
      </Link>
      <div className="divider mb-25" />
      {showSelectedSegments ? (
        <div>
          <div className="align-cards col-12 pd-lr-0">
            {allSegments?.map((item) => (
              <SegmentCard
                item={item}
                selected={selecteds.some((it) => it.id === item.id)}
                onSegmentSelected={handleSegmentSelected}
              />
            ))}
          </div>
          <div className="btns" onClick={() => setShowSelectedSegments(false)}>
            <CheckIcon />
            <span>{t('business.flux.include_segments')}</span>
          </div>
        </div>
      ) : (
        <div>
          <Segments
            type="include"
            onClick={() => setShowSelectedSegments(true)}
            segmentsProps={segments}
          />
          {!showMore && (
            <span className="subtitle" onClick={() => setshowMore(true)}>
              + {t('business.flux.what_is_a_segment')}
            </span>
          )}
          {showMore && (
            <div>
              <span className="subtitle" onClick={() => setshowMore(false)}>
                - {t('business.flux.include_segments')}
              </span>
              <span className="message">
                {t('business.flux.segmentMessage')}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(BenefitSegments);
