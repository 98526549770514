import { Reducer } from 'redux';
import { CreateNewBenefitTypes, CreateNewBenefitState } from './types';
import { ActionType } from 'typesafe-actions';
import * as createBenefitAction from "./actions"

export type CreateBenefitAction = ActionType<typeof createBenefitAction>

export const initialState: CreateNewBenefitState = {benefit:{}};

const reducer: Reducer<CreateNewBenefitState> = (state = initialState, action: CreateBenefitAction) => {
  switch (action.type) {
    case CreateNewBenefitTypes.CREATE_BENEFIT: {  
      return { ...state, benefit:action.payload  };
    }
    
    default: {
      return state;
    }
  }
};

export { reducer as createBenefitReducer };
