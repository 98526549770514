enum RenewingPeriod {
  NO_RENEW = 'NO_RENEW',
  WEEKLY_PERIOD = 'WEEKLY_PERIOD',
  BI_WEEKLY_PERIOD = 'BI_WEEKLY_PERIOD',
  MONTH_PERIOD = 'MONTH_PERIOD',
  TWO_MONTHS_PERIOD = 'TWO_MONTHS_PERIOD',
  THREE_MONTHS_PERIOD = 'THREE_MONTHS_PERIOD',
  SIX_MONTHS_PERIOD = 'SIX_MONTHS_PERIOD',
  YEARLY = 'YEARLY',
}

export default RenewingPeriod;
