import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import { CompanyWallet } from 'src/Models/CompanyWallet';
import StringUtils from 'src/Utils/StringUtils';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import WalletModal from '../Modal';
import { ReactComponent as Arrow } from '../../../Images/Icons/arrow.svg';

export interface BalanceProps extends WithTranslation {
  companyWallet: string;
  wallet?: CompanyWallet;
  toggle: (main: string, step?: string) => void;
  modal: string;
}

const Balance: React.FC<BalanceProps> = ({
  t,
  companyWallet,
  toggle,
  wallet,
  modal,
}) => {
  const [walletValue, setWalletValue] = useState<CompanyWallet>();
  const balance = StringUtils.currencyPtBr(walletValue?.balance);
  const limit = StringUtils.currencyPtBr(walletValue?.creditLimit);
  const reservedValue = StringUtils.currencyPtBr(walletValue?.reservedValue);
  const history = useHistory();
  useEffect(() => {
    if (wallet != null) {
      setWalletValue(wallet);
    }
  }, [wallet]);

  // const handleMoney = (value: string): number => {
  //   return Number(value.replace(/[^0-9]+/g, ''));
  // };
  // const handleBar = () => {
  //   const width = (handleMoney(have) / handleMoney(total)) * 100;
  //   return (
  //     <div className="limitBar">
  //       <div className="gradient" style={{ width: `${width}%` }} />
  //     </div>
  //   );
  // };

  return (
    <>
      <Row className="balance">
        <Col>
          <Row className="infos">
            <div className="left">
              <div className="title">
                {!(walletValue?.balance < 0)
                  ? t('wallet.wallet.balance')
                  : t('wallet.wallet.balance2')
                }
              </div>
              <div className="values">
                <div className="have">{balance} </div>
                {/* <div className="total">/{limit}</div> */}
              </div>
            </div>
            {/* <div className="right">
              <Button
                onClick={() => toggle(companyWallet)}
                className="payButton">
                {companyWallet === 'fund' ? 'Adicionar saldo' : 'Pagar'}
              </Button>
            </div> */}
            <div className="right">
              <Tooltip
                title={
                  !(walletValue?.balance < 0)
                    ? 'Falar com o financeiro para adição de saldo'
                    : 'Falar com o financeiro para pagar o saldo'
                }>
                <a
                  className="payButton"
                  href={
                    !(walletValue?.balance < 0)
                      ? 'https://api.whatsapp.com/send?phone=4192555590&text=Olá Keiken, gostaria de adicionar saldo na minha carteira!'
                      : 'https://api.whatsapp.com/send?phone=4192555590&text=Olá Keiken, gostaria pagar o saldo na minha carteira!'
                  }
                  target="_blank"
                  rel="noreferrer">
                  {!(walletValue?.balance < 0)
                    ? t('wallet.wallet.addBalance')
                    : t('wallet.wallet.payBalance')}
                </a>
              </Tooltip>
            </div>
          </Row>
          {/* {companyWallet === 'fund' ? (
            <>{handleBar()}</>
          ) : (
            <div className="dueDate">Vence em 25/08</div>
          )} */}
          <div className="title">
            <p>{t('wallet.wallet.limit', {
              limit,
            })}</p>
            <p>{t('wallet.wallet.reserved') + reservedValue}</p>
          </div>
          <div
            className="go-to-extract"
            onClick={() => history.push('/wallet/extract')}>
            {t('wallet.wallet.seeExtractBtn')}
            <Arrow />
          </div>
        </Col>
      </Row>
      <WalletModal
        toggle={toggle}
        modal={modal}
        companyWallet={companyWallet}
      />
    </>
  );
};

export default withTranslation()(Balance);
