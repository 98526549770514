import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import { ReactComponent as PlusIcon } from '../../Images/Icons/plus.svg';
import { ReactComponent as Pencil } from '../../Images/Icons/pencil.svg';

import './styles.scss';
import { Link } from 'react-router-dom';

export interface CustomIconTextButtonProps extends WithTranslation {
  type?: string;
  newDescription: string;
  onClick?: () => void;
  url?: string;
}

const CustomIconTextButton: React.FC<CustomIconTextButtonProps> = ({
  t,
  newDescription,
  type,
  onClick,
  url,
}) => {
  if (type === 'include') {
    return (
      <Card className="AddButton">
        <div className="include_div" onClick={onClick}>
          <Pencil className="PlusIcon" />
          <div className="ButtonText">
            Incluir {t(`menu.${newDescription}`)}
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Link to={url} className="AddButton">
      <Card className="link">
        <PlusIcon className="PlusIcon" />
        <div className="ButtonText">
          Criar novo {t(`menu.${newDescription}`)}
        </div>
      </Card>
    </Link>
  );
};

export default withTranslation()(CustomIconTextButton);
