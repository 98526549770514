import { AxiosResponse } from 'axios';
import { CoParticipationCustomer } from 'src/Models/Business/CoParticipationCustomer';
import { api } from '.';

const baseUrl = 'co-participation-customers';

interface Emails {
  email: string;
}
class BusinessCustomerApi {
  static getAllCustomers = (data: {
    name?: string;
    email?: string;
    sort: boolean;
    newer: boolean;
    page: number;
    block?: boolean;
    blockedByNoShow?: boolean;
    segments: {
      filter?: boolean;
      value?: boolean;
    };
  }): Promise<AxiosResponse> => {
    const {
      name,
      sort,
      newer,
      page,
      email,
      segments,
      blockedByNoShow = false,
    } = data;
    let filter = '';
    if (name) filter = `${filter}customer.name=${name}&`;
    if (email) filter = `${filter}email=${email}&`;
    if (sort) filter += 'sort=asc&';
    if (newer) filter += 'newer=true&';
    if (segments?.filter && !blockedByNoShow)
      filter += `containsSegment=${segments?.value}&`;
    if (blockedByNoShow) filter += 'isBlocked=true&';

    filter = `?${filter}page=${page}`;
    return api.get(`${baseUrl}${filter}`);
  };

  static getOneCustomer = (
    id: number,
  ): Promise<AxiosResponse<CoParticipationCustomer>> => {
    return api.get(`${baseUrl}/${id}`);
  };

  static editOneCustomer = (
    customer: CoParticipationCustomer,
  ): Promise<AxiosResponse> => {
    return api.put(`${baseUrl}`, customer);
  };

  static createOneCustomer = (
    customer: CoParticipationCustomer,
  ): Promise<AxiosResponse> => {
    return api.post(`${baseUrl}`, customer);
  };

  static createManyCustomer = (emails: Emails[]): Promise<AxiosResponse> => {
    return api.post(`${baseUrl}/list`, emails);
  };

  static deleteCoParticipationCustomers = (
    coParticipationCustomers: CoParticipationCustomer[],
  ): Promise<AxiosResponse> => {
    const ids = coParticipationCustomers.map((it) => it.id).join(',');
    return api.delete(`${baseUrl}/${ids}`);
  };
}

export default BusinessCustomerApi;
