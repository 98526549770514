export enum ConferenceSessionTypes {
  CHECK_CUSTOMER_TOKEN = '@@conference/CHECK_CUSTOMER_TOKEN',
  CHECK_CUSTOMER_TOKEN_SUCCESS = '@@conference/CHECK_CUSTOMER_TOKEN_SUCCESS',
  CHECK_CUSTOMER_TOKEN_ERROR = '@@conference/CHECK_CUSTOMER_TOKEN_ERROR',

  CHECK_BY_CUSTOMER_ACTIVITY_TOKEN = '@@conference/CHECK_BY_CUSTOMER_ACTIVITY_TOKEN',
  CHECK_BY_CUSTOMER_ACTIVITY_TOKEN_SUCCESS = '@@conference/CHECK_BY_CUSTOMER_ACTIVITY_TOKEN_SUCCESS',
  CHECK_BY_CUSTOMER_ACTIVITY_TOKEN_ERROR = '@@conference/CHECK_BY_CUSTOMER_ACTIVITY_TOKEN_ERROR',

  FORCE_DISCONNECT_CONFERENCE = '@@conference/FORCE_DISCONNECT_CONFERENCE',
  FORCE_DISCONNECT_CONFERENCE_SUCCESS = '@@conference/FORCE_DISCONNECT_CONFERENCE_SUCCESS',
  FORCE_DISCONNECT_CONFERENCE_ERROR = '@@conference/FORCE_DISCONNECT_CONFERENCE_ERROR',

  RESET = '@@conference/RESET'
}
