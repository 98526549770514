import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import pix from '../../../../Images/Icons/pix-image.svg';
export interface PixProps extends WithTranslation {
  toggle: (main: string, step?: string) => void;
  companyWallet: string;
}

const Pix: React.FC<PixProps> = ({ t, toggle, companyWallet }) => {
  useEffect(() => {
    setTimeout(() => {
      toggle(companyWallet, 'success');
    }, 5000);
  }, []);
  return (
    <>
      <ModalBody className="WalletModal__body">
        <div className="modal_col">
          <div className="pix_code">
            <img src={pix} alt="pix code" />
          </div>
          <div className="modal_col_description">
            Utilize o aplicativo do seu internet banking para escanear o código
            acima e efetuar o pagamento via PIX. Uma mensagem de confirmação irá
            aparecer assim que o pagamento for creditado.
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="WalletModal__footer">
        <Col className="no-padding">
          <div
            className="cancel"
            onClick={() => toggle(companyWallet, 'infos')}>
            Cancelar
          </div>
        </Col>
      </ModalFooter>
    </>
  );
};

export default withTranslation()(Pix);
