import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import BusinessGroupBenefits from 'src/Services/Api/BusinessGroupBenefits';
import { useSelector } from 'react-redux';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import CoParticipationGroupList from 'src/Components/ColaboratorBenefits/CoParticipationGroupList';
import _, { values } from 'lodash';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ReactComponent as Close } from '../../../../Images/Icons/close-gray.svg';

export interface BenefitModalProps extends WithTranslation {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const BenefitModal: React.FC<BenefitModalProps> = ({ t, close, isOpen }) => {
  const customers = useSelector(
    (state) => (state as any).selectCustomer.customers,
  );
  const [allBenefits, setAllBenefits] = useState<CoParticipationGroup[]>([]);
  const [groupsSelected, setGroupsSelected] = useState<CoParticipationGroup[]>([]);

  useEffect(() => {
    (async () => {
      const segmentId = customers?.[0]?.coParticipationSegment?.id;
      const result = await BusinessGroupBenefits.getAllGroupBenefits();
      const filtered = _.filter(result.data, (group) =>
        _.some(group?.segments, (seg) => seg.id == segmentId),
      );
      setAllBenefits(filtered);
    })();
  }, []);

  const handleAddBenefit = async () => {
    let someError = null;
    await customers.forEach(async (item) => {
      const result = await BusinessCustomerApi.editOneCustomer({
        id: item.id,
        coParticipationSegment: item.coParticipationSegment,
        groups: groupsSelected,
      });

      if (result.status != 200) {
        someError = result?.data?.detail;
      }
    });
    if (someError != null) {
      toast.error(someError);
    } else {
      toast.success('Alterado com sucesso!');
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader close={<Close onClick={() => close(false)} />}>
          <div className="deleteModal">
            {' '}
            {t('business.groupBenefitModal.title')}
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            {t('business.groupBenefitModal.attention1')}
            <span style={{ color: '#25b379' }}>
              {t('business.segmentModal.attention2', {
                count: customers?.length ?? 0,
              })}
            </span>
          </div>
          <div className="benefitRow">
            <CoParticipationGroupList
              groups={allBenefits}
              onChange={(items) => setGroupsSelected(items)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Col className="no-padding">
            <div className="cancel" onClick={() => close(false)}>
              {t('business.groupBenefitModal.button.cancel')}
            </div>
          </Col>
          <Col className="no-padding">
            <Button className="payment_way" onClick={() => handleAddBenefit()}>
              {t('business.groupBenefitModal.button.confirm')}
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation()(BenefitModal);
