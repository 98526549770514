import React, { useRef, useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import '../styles.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowLeft, ArrowRight} from '@material-ui/icons';
import dayjs from 'dayjs';

interface PieDataRow {
  name?: string;
  count?: number;
  color?: string;
}
export interface GraphCardProps extends WithTranslation {
  pieData?: PieDataRow[];
  emptyMessage?: string;
  withDatePicker?: boolean;
  date?: dayjs.Dayjs;
  setDate?: (date: dayjs.Dayjs) => void;
}

const GraphCard: React.FC<GraphCardProps> = ({ t, pieData, emptyMessage, withDatePicker, date, setDate }) => {
  const [ graphValues, setGraphValues ] = useState<PieDataRow[]>([])
  const graphRef = useRef(null);

  const handleIntersect = () => {
    setGraphValues(pieData);
  }

  useEffect(() => {
    if (!graphRef.current || !pieData) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          handleIntersect();
        }
      });
    });

    observer.observe(graphRef.current);
    // cleanup function
    return () => {
      observer.disconnect();
    };
  },[handleIntersect, pieData])

  const data = graphValues ? {
    datasets: [
      {
        data: graphValues?.map((item) => item.count),
        backgroundColor: graphValues?.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  } : {};

  return (
    <Card className="BigCard Graph">
      <div className="BigCard--title">
        <div>{t('wallet.wallet.cards.graphTitle')}</div>
        {withDatePicker &&
        <div style={{display: "flex", alignItems: "center", maxWidth: "220px", height: "45px"}}>
          <ArrowLeft fontSize='large' style={{cursor: "pointer", color: "#25b379"}} onClick={() => setDate(date?.subtract(1, 'month'))} />
          <DatePicker
            label={'Veja de outros meses:'} 
            views={['month', 'year']}
            disableFuture
            format='MM/YYYY'
            disableOpenPicker
            value={date}
            onChange={(newDate) => {setDate(newDate)}}
          />
          <ArrowRight fontSize='large' style={{cursor: "pointer", color: "#25b379"}} onClick={() => setDate(date?.add(1, 'month'))} />
          </div>
        }
        </div>
      {pieData?.length > 0 || !emptyMessage ? (
      <> 
        <div className="BigCard__Graph">
          <div className="BigCard__Graph--Details">
            {pieData?.map((item, index) => (
              <div
                key={`graph-act-${index}`}
                className="BigCard__Graph--Details__activity"
                style={{ backgroundColor: item.color }}>
                {item.name}
              </div>
            ))}
          </div>
          <div ref={graphRef} className="BigCard__Graph--Chart">
            {/* @ts-ignore */}
            <Doughnut type="" data={data} />
          </div>
        </div>
        <Link to="/wallet/extract" className="BigCard--link">
          {t('wallet.wallet.cards.seeAllBtn')}
        </Link> 
      </>
      ) : (
        <div className="BigCard--empty">
          <h4>{emptyMessage}</h4>
        </div>
      )}
    </Card>
  );
};

export default withTranslation()(GraphCard);
