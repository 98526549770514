import styled, { css } from 'styled-components';

interface ButtonContainerProps {
  isSecundary?: boolean;
  disabled?: boolean;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  background: ${({ isSecundary, disabled }) =>
    disabled ? '#f4f5f6' : isSecundary ? '#FFF' : '#25b379'};

  ${(props) =>
    props.isSecundary &&
    css`
      border: 1px solid #2fb86f;
    `};

  border-radius: 30px;

  &:active {
    background: ${({ isSecundary, disabled }) =>
      disabled ? '#f4f5f6' : isSecundary ? '#fafafa' : '#16cf6f'};
  }

  *:hover {
    cursor: pointer;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 6px 20px;
    ${(props) =>
      props.disabled &&
      css`
        border: 1px solid #b2bcc2;
      `};
    label {
      font-family: MullerBold;
      font-size: 18px;
      margin: 0;
      text-align: center;
      color: ${({ isSecundary, disabled }) =>
        disabled ? '#b2bcc2' : isSecundary ? '#2FB86F' : '#fafafa'};
      user-select: none;
    }
  }
`;
