import { AxiosResponse } from 'axios';
import { checkApiStatus } from 'src/Utils/ApiUtils';
import { PageableResponse } from 'src/Models/pageable';
import { Billing } from 'src/Models/Billing';
import { CompanyWalletApi, FilterParams } from './Api/CompanyWalletApi';
import ConferenceApi from './Api/ConferenceApi';

class CompanyWalletService {
  static walletDetails = async (
    filter?: FilterParams,
  ): Promise<AxiosResponse<PageableResponse<Billing>>> => {
    try {
      const result = await CompanyWalletApi.walletDetails(filter);
      if (!checkApiStatus(result.data.content != null, result.status)) {
        return Promise.reject({
          data: result.data,
          status: result.status,
          statusText: result.statusText,
          headers: result.headers,
          config: result.config,
        });
      }
      return Promise.resolve(result);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static billingsCoParticipations = async (
    id?: number,
    filter?: FilterParams,
  ) => {
    try {
      const result = await CompanyWalletApi.getBillingsCoParticipations(
        id,
        filter,
      );
      if (!checkApiStatus(result.data != null, result.status)) {
        return Promise.reject({
          status: result.status,
          statusText: result.statusText,
        });
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
}

export default CompanyWalletService;
