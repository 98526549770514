import { Reducer } from 'redux';
import { SelectCustomer, SelectCustomerState } from './types';
import { ActionType } from 'typesafe-actions';
import * as selectCustomerAction from "./actions"

export type SelectCustomerAction = ActionType<typeof selectCustomerAction>

export const initialState: SelectCustomerState = {customers:[]};

const reducer: Reducer<SelectCustomerState> = (state = initialState, action: SelectCustomerAction) => {
  switch (action.type) {
    case SelectCustomer.SELECT_CUSTOMER: {  
      return { ...state, customers:action.payload  };
    }
    
    default: {
      return state;
    }
  }
};

export { reducer as selectCustomerReducer };
