import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import StringUtils from 'src/Utils/StringUtils';
import { ReactComponent as DoubleArrow } from '../../../Images/Icons/doubleArrow.svg';
import { ReactComponent as Arrow } from '../../../Images/Icons/arrow.svg';

export interface BenefitsCardProps extends WithTranslation {
  title: string;
  value: number;
  linkTo: string;
  nCategories: string;
  nContributors: string;
  segments: string[];
  renovate?: string;
  renovateButton?: boolean;
}

const BenefitsCard: React.FC<BenefitsCardProps> = ({
  t,
  title,
  value,
  linkTo,
  nCategories,
  nContributors,
  segments,
  renovate,
  renovateButton,
}) => {
  const formattedValue = StringUtils.currencyPtBr(value);
  return (
    <div className="BenefitCard-Col">
      <Card className="BenefitCard">
        <div className="BenefitCard--type">
          {renovate
            ? t(`business.RenewingPeriod.${renovate}`)
            : 'Renovação Mensal'}
        </div>
        <div className="BenefitCard--title">{title}</div>
        <div className="BenefitCard__ValueCategories">
          <div className="BenefitCard__ValueCategories--value">
            {formattedValue}
            {' '}
          </div>
          <div className="BenefitCard__ValueCategories--categories">
            • 
            {' '}
            {nCategories}
            {' '}
            Categorias
          </div>
        </div>
        <div className="BenefitCard--contributors">
          {nContributors} 
          {' '}
          {t('menu.contributors')}
        </div>
        <div className="BenefitCard__segments">
          {segments?.slice(0, 2)?.map((segment, index) => (
            <div
              key={`benifits-card-seg-${index}`}
              className="BenefitCard__segments--segment"
            >
              {segment}
            </div>
          ))}
          {segments?.length > 2 && (
            <div className="BenefitCard__segments--segment">
              + 
              {' '}
              {segments.length - 2}
            </div>
          )}
        </div>
        <Link to={linkTo} className="BenefitCard--link">
          Editar 
          {' '}
          <Arrow />
        </Link>
      </Card>
      {renovateButton && (
        <div className="BenefitCard__renovateButton">
          <DoubleArrow className="BenefitCard__renovateButton--icon" />
          <div className="BenefitCard__renovateButton--description">
            Renovar benefício
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(BenefitsCard);
