import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { authenticationReducer } from './authentication/reducer';
import authenticationSaga from './authentication/sagas';
import { AuthenticationState } from './authentication/types';
import { companyWalletReducer } from './company/reducer';
import companySaga from './company/sagas';
import { CompanyState } from './company/types';
import { conferenceReducer } from './conference/reducer';
import { IConferenceState } from './conference/state';
import { createBenefitReducer } from './createBenefit/reducer';
import { CreateNewBenefitState } from './createBenefit/types';
import { selectCustomerReducer } from './selectedCustomers/reducer';
import { SelectCustomerState } from './selectedCustomers/types';

export interface IRootState {
    readonly authentication: AuthenticationState;
    readonly conference: IConferenceState;
    readonly createBenefit: CreateNewBenefitState;
    readonly selectCustomer: SelectCustomerState;
    readonly companyState: CompanyState;
}

const rootReducer = combineReducers<IRootState>({
    authentication: authenticationReducer,
    conference: conferenceReducer,
    createBenefit: createBenefitReducer,
    selectCustomer: selectCustomerReducer,
    companyState: companyWalletReducer
});

export function* rootSaga() {
    yield all([
      fork(authenticationSaga),
      fork(companySaga)
    ]);
}

export default rootReducer;
