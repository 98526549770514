import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row, Button, ModalBody, ModalFooter } from 'reactstrap';
import { ReactComponent as ClipBoard } from '../../../../Images/Icons/clipboard.svg';
import { ReactComponent as Money } from '../../../../Images/Icons/moneySmall.svg';
import { ReactComponent as Arrow } from '../../../../Images/Icons/arrow.svg';
export interface TicketProps extends WithTranslation {
  toggle: (main: string, step?: string) => void;
  companyWallet: string;
}

const Ticket: React.FC<TicketProps> = ({ t, toggle, companyWallet }) => {
  const [stateEmail, setStateEmail] = useState<boolean>(false);
  return (
    <>
      <ModalBody className="WalletModal__body">
        <div className="modal_col">
          <div className="description">
            <p>
              Seu boleto no valor de <span className="value">R$20.000,00 </span>
              foi criado com sucesso! O prazo de pagamento é até dia 12/06/2021.
            </p>
          </div>
          <div className="code">
            34191.79001 01043.510047 91020.150008 4 86210026000
          </div>
          <div className="copy_code">
            <ClipBoard className="icon" />
            <div className="text">Copiar código</div>
          </div>
          <div className="download_ticket">
            <Money className="icon" />
            <div className="text">Baixar boleto</div>
          </div>
          <Row className="no-padding subtitle__ticket">
            <Col className="no-padding">
              <div className="subtitle__ticket--header">
                <div className="text">Enviar boleto por email</div>
                <Arrow
                  className={`icon ${stateEmail}`}
                  onClick={() => setStateEmail(!stateEmail)}
                />
              </div>
              {stateEmail && (
                <div className="subtitle__ticket--stateEmail">
                  <label className="four">
                    <div className="label--description">Email</div>
                    <input type="text" />
                  </label>
                  <div className="byEmailButton">Receber boleto por email</div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter className="WalletModal__footer">
        <Col className="no-padding">
          <div
            className="cancel"
            onClick={() => toggle(companyWallet, 'infos')}>
            Voltar
          </div>
        </Col>
        <Col className="no-padding">
          <Row className="no-padding payment_way_row">
            <Button className="payment_way" onClick={() => toggle('')}>
              Concluir pagamento
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </>
  );
};

export default withTranslation()(Ticket);
