import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './styles.scss';

export interface BenefitCategoriesCard extends WithTranslation {
  categories: any[];
  setCategories: React.Dispatch<React.SetStateAction<any[]>>;
  image: string;
  name: string;
  categoryId: number;
}

const BenefitCategoriesCard: React.FC<BenefitCategoriesCard> = ({
  t,
  image,
  name,
  categories,
  setCategories,
  categoryId,
}) => {
  const [selected, setSelected] = useState<boolean>(false);

  const handleInclude = () => {
    if (!selected) {
      setCategories((categories) => [
        ...categories,
        { activityCategory: { id: categoryId, name }, maxSpendingCategory: 0 },
      ]);
    } else {
      setCategories((categories) =>
        categories.filter(
          (category) => category.activityCategory.id !== categoryId,
        ),
      );
    }
  };

  useEffect(() => {
    const founded = categories.some(
      (it) => it.activityCategory.id === categoryId,
    );
    setSelected(founded);
  }, [categories]);

  return (
    <div className={`item ${selected}`} onClick={handleInclude}>
      <img src={image} />
      <span className="title">{name}</span>
    </div>
  );
};

export default withTranslation()(BenefitCategoriesCard);
