import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import './styles.scss';

export interface PageTitleProps extends WithTranslation {
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ t, title }) => {
  return (
    <div className="TitleRow">
      <Container>
        <div className="Title">{t(`header.${title}`)}</div>
      </Container>
    </div>
  );
};

export default withTranslation()(PageTitle);
