import React from 'react';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined, ImageSearch } from '@material-ui/icons';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import { BillingCoParticipationStatus } from 'src/Models/BillingCoParticipation';
import StringUtils from 'src/Utils/StringUtils';
import { Billing, PaymentMethod } from 'src/Models/Billing';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

export interface HistoricProps extends WithTranslation {
  billing?: Billing;
}

const Historic: React.FC<HistoricProps> = ({ t, billing }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentImg, setCurrentImg] = React.useState(0);

  const billingStatus =
    billing.billingCoParticipation?.status ?? billing?.status;

  let activityName =
    billing?.activityPlan?.name ??
    billing?.activitySchedules[0]?.activity?.name ??
    t('partner.wallet.invoiceActivityName');

  const customer = billing?.billingCoParticipation?.coParticipationCustomer;

  const billingDate = billing?.date;
  let coParticipationDate = billing?.billingCoParticipation?.date;

  if (!coParticipationDate) {
    coParticipationDate = billingDate;
  }

  // dia da compra
  const actionDate = moment(billingDate);
  const formatedDate = actionDate.format('DD/MM/YYYY');
  const formatedHour = actionDate.format('HH:mm');

  let value = -billing.billingCoParticipation?.subsidyValue.toFixed(2);

  if (
    billing.paymentMethod === PaymentMethod.DEPOSIT_BY_COMPANY ||
    billing.paymentMethod === PaymentMethod.COMPANY_EXTERNAL
  ) {
    if (billing.paymentMethod === PaymentMethod.COMPANY_EXTERNAL) {
      value = -billing?.value;
      activityName = t('partner.wallet.withdraw');
      coParticipationDate =
        billing.billingCompany?.transactionDate ??
        billing?.billingCompany?.scheduledDate ??
        billing?.billingCompany?.referenceDate;
    } else {
      value = billing?.value;
      activityName = t('partner.wallet.deposit');
    }
  }

  const transactionDate = moment(coParticipationDate);

  const keikenPayImageUrl =
    billing?.billingCoParticipation?.keikenPayInvoice?.image?.imageUrl;

  const allKeikenPayImgs = [
    billing?.billingCoParticipation?.keikenPayInvoice?.image?.imageUrl,
    ...(billing?.billingCoParticipation?.keikenPayInvoice?.auxUserImages?.map(
      (img) => img.imageUrl,
    ) ?? []),
  ];

  return (
    <>
      <div className="historic">
        <div className="date">
          <div className="day">{formatedDate}</div>
          <div className="hour">{formatedHour}</div>
        </div>
        <Col className="type">
          <div className="balance">{activityName}</div>
          <div className="deposit">
            {/* {t(`partner.wallet.billingType.${typeOfBilling}`)} */}
            Compensado em: {transactionDate.format('DD/MM/YYYY')}
          </div>
        </Col>
        <Col className="person">
          {customer ? (
            <>
              <div className="name">{customer?.customer?.name}</div>
              <div className="email">{customer?.email}</div>
            </>
          ) : (
            billing.description && <p>{billing?.description}</p>
          )}
        </Col>
        <Col className="price">
          {/* {keikenPayImageUrl && (
            <Tooltip
              className="pointer"
              title={t('partner.wallet.tooltip.keikenPay')}>
              <ImageSearch onClick={() => setIsModalOpen(true)} />
            </Tooltip>
          )} */}
          <div className="price__info">
            {billingStatus === BillingCoParticipationStatus.FUTURE_CHARGE ? (
              <PreScheduledTooltip
                t={t}
                date={transactionDate.format('DD/MM/YYYY')}>
                <div className="value pack" style={{ color: 'orange' }}>
                  {StringUtils.currencyPtBr(value)}
                </div>
              </PreScheduledTooltip>
            ) : (
              <div className={`value pack ${value > 0 && 'positive'}`}>
                {StringUtils.currencyPtBr(value)}
              </div>
            )}
            <div className="balance">{t('partner.wallet.balance')}</div>
          </div>
        </Col>
        <Col>
          <div className="status-container">
            <span
              className={`status ${
                billingStatus === 'WAITING' ||
                billingStatus === 'FUTURE_CHARGE' ||
                billingStatus === 'CREATED'
                  ? 'pending'
                  : billingStatus === 'NOT_PAID'
                  ? 'error'
                  : ''
              }`}>
              {t(`partner.wallet.billingStatus.${billingStatus}`)}
            </span>
          </div>
        </Col>
      </div>
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <ModalHeader toggle={() => setIsModalOpen(false)}>
          {t('wallet.extract.receipt')}
        </ModalHeader>
        <ModalBody className="keikenPay-modal">
          <div className="keikenPay-container">
            <img
              src={allKeikenPayImgs[currentImg]}
              alt="KeikenPay Invoice Receipt"
            />
          </div>
          <div className="img-btns-box">
            <Button
              className="img-btn"
              onClick={() => {
                if (currentImg > 0) {
                  setCurrentImg(currentImg - 1);
                }
              }}>
              <ArrowLeft />
            </Button>
            <h3 style={{ fontSize: '20px' }}>
              {currentImg + 1} / {allKeikenPayImgs.length}
            </h3>
            <Button
              className="img-btn"
              onClick={() => {
                if (currentImg < 3) {
                  setCurrentImg(currentImg + 1);
                }
              }}>
              <ArrowRight />
            </Button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setIsModalOpen(false)}>
            ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const PreScheduledTooltip = ({ t, date, children }) => {
  return (
    <Tooltip
      className="pointer"
      title={t('partner.wallet.tooltip.preScheduled', {
        date,
      })}>
      {children}
    </Tooltip>
  );
};

export default withTranslation()(Historic);
