import { Company } from 'src/Models/Company';
import { Customer } from 'src/Models/Customer';
import { action } from 'typesafe-actions';
import { Authentication } from '../../Models/authentication';
import { User } from '../../Models/user';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';
import { AuthenticationActionTypes } from './types';

export const loginRequest = (authenticate: Authentication) => action(AuthenticationActionTypes.LOGIN_REQUEST, authenticate);
export const loginSuccess = () => action(AuthenticationActionTypes.LOGIN_SUCCESS);
export const loginError = (error: HttpResponseError) => action(AuthenticationActionTypes.LOGIN_ERROR, error);
export const loginResetStatus = () => action(AuthenticationActionTypes.LOGIN_RESET_STATUS);

export const getSessionRequest = () => action(AuthenticationActionTypes.GET_SESSION_REQUEST);
export const getSessionSuccess = (customer: Customer) => action(AuthenticationActionTypes.GET_SESSION_SUCCESS, customer);
export const getSessionError = (message: string) => action(AuthenticationActionTypes.GET_SESSION_ERROR, message);

export const updateCompanySessionState = (company: Company) => action(AuthenticationActionTypes.UPDATE_COMPANY_SESSION_STATE, company);

export const logoutRequest = () => action(AuthenticationActionTypes.LOGOUT_REQUEST);
