import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import OutlinedCurrencyTextField from '../OutlinedCurrencyTextField/OutlinedCurrencyTextField';

import './styles.scss';

export interface BoxComponentCurrencyTextField extends WithTranslation {
  value?: number;
  onChange?: (value: number) => void;
  title: string;
  placeholder: string;
  infoQuestion: string;
  infoAnswer: string;
}

const BoxComponentCurrencyTextField: React.FC<BoxComponentCurrencyTextField> =
  ({ t, onChange, value, title, placeholder, infoQuestion, infoAnswer }) => {
    const [showMore, setshowMore] = useState<boolean>(false);
    const handleSetValue = (value: number) => {
      onChange(value != 0 ? value : null);
    };
    return (
      <div className="container mt-40 BoxComponentCurrencyTextField">
        <span className="title">{title}</span>
        <div className="divider" />
        <OutlinedCurrencyTextField
          value={value}
          onChange={handleSetValue}
          label={placeholder}
        />
        <div>
          <span
            className="subtitle"
            onClick={() => setshowMore((prevState) => !prevState)}>
            {showMore ? '-' : '+'} {infoQuestion}
          </span>
          {showMore && <span className="message">{infoAnswer}</span>}
        </div>
      </div>
    );
  };

export default withTranslation()(BoxComponentCurrencyTextField);
