import { makeStyles } from '@material-ui/styles';

const makeNewStyles = makeStyles({
    paper: {
      width: 900,
      backgroundColor: '#FFF',
      padding: 15,
      borderRadius: 15,
      margin: 'auto',
      marginBottom: 20,
      marginTop: 20,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    close: {
      cursor: 'pointer',
    }
  });

export default makeNewStyles