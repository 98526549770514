import { AxiosResponse } from 'axios';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import { api } from '.';

const baseUrl = 'co-participation-groups';

class BusinessGroupBenefits {

  static getActivityCategories = (): Promise<AxiosResponse> => {
    return api.get(`/activity-categories?page=0&size=100&type=REGULAR`);
  };
  
  static getAllGroupBenefits = (): Promise<AxiosResponse<CoParticipationGroup[]>> => {
    return api.get<CoParticipationGroup[]>(`${baseUrl}/from-my-company`);
  };

  static getOneGroupBenefit = (id: number): Promise<AxiosResponse<CoParticipationGroup>> => {
    return api.get(`${baseUrl}/${id}`);
  };

  static addOneGroupBenefit = (groupBenefit:CoParticipationGroup): Promise<AxiosResponse> => {
    return api.post(`${baseUrl}`,groupBenefit);
  };

  // static addOneGroupBenefitGuided = (groupBenefit:GroupBenefit): Promise<AxiosResponse> => {
  //   return api.post(`${baseUrl}/guided`,groupBenefit);
  // };

  static editOneGroupBenefit = (groupBenefit:CoParticipationGroup): Promise<AxiosResponse> => {
    return api.put(`${baseUrl}`,groupBenefit);
  };

  static deleteOneGroupBenefit = (id:number): Promise<AxiosResponse> => {
    return api.delete(`${baseUrl}/${id}`);
  };
}


export default BusinessGroupBenefits;