import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { ReactComponent as Arrow } from '../../Images/Icons/arrow.svg';
import './styles.scss';
export interface CustomInfoCardProps extends WithTranslation {
  title: string;
  value: string;
  linkTitle: string;
  linkTo: string;
  value2?: number;
  title2?: string;
  alternativeLink?: string;
  alternativeLinkTitle?: string;
}

const CustomInfoCard: React.FC<CustomInfoCardProps> = ({
  t,
  title,
  value,
  linkTitle,
  linkTo,
  value2,
  title2,
  alternativeLink,
  alternativeLinkTitle,
}) => {
  const link = value2 ? alternativeLink : linkTo;
  const linkTitleText = value2 ? alternativeLinkTitle : linkTitle;

  
  return (
    <Card className="OverviewCard">
      {!value2 ? (
        <div className='OverviewCard--info'>
          <div className="OverviewCard--title">{title}</div>
          <div className="OverviewCard--value">{value}</div>
        </div>
      ) : (
          <div className='OverviewCard--divided'>
            <div className='OverviewCard--divided--info'>
              <div className="OverviewCard--title">{title}</div>
              <div className="OverviewCard--value">{value}</div>
            </div>
            <div className='OverviewCard--divided--info'>
              <div className="OverviewCard--title">{title2}</div>
              <div className="OverviewCard--value" style={{color: "#f44336"}}>{value2}</div>
            </div>
          </div>
        )}
      <Link to={link} className="OverviewCard--link">
        {linkTitleText}
        <Arrow />
      </Link>
    </Card>
  );
};

export default withTranslation()(CustomInfoCard);
