import { AxiosResponse } from 'axios';
import { api } from '.';
import { IBusinessDashboard } from 'src/Models/Business/BusinessDashboard';
import dayjs, { Dayjs } from 'dayjs';

const baseUrl = 'companies';
class BusinessDashboard {
  static getDashboard = async (date: Dayjs): Promise<AxiosResponse<IBusinessDashboard>> => {
    const start = date.startOf('month').toISOString();
    const end = date.endOf('month').toISOString();
    return await api.get(`${baseUrl}/dashboard?start=${start}&end=${end}`);
  };

}

export default BusinessDashboard;
