import { AxiosResponse } from 'axios';
import { api } from '.';

const baseUrl = '/companies';

class CompanyApi {

  static register = (payload): Promise<AxiosResponse<void>> => {
    return api.post<void>(`${baseUrl}/new`, payload);
  };

}


export default CompanyApi;