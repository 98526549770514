import { action } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import {
  all,
  call,
  delay,
  fork,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { CompanyWalletApi } from 'src/Services/Api/CompanyWalletApi';
import BusinessCompany from 'src/Services/Api/BusinessCompany';
import { CompanyWallet } from 'src/Models/CompanyWallet';
import { PageableResponse } from 'src/Models/pageable';
import { CompanyUser } from 'src/Models/CompanyUser';
import graphColors from 'src/Themes/graphColors';
import { Billing } from 'src/Models/Billing';
import { updateCompanySessionState } from '../authentication/actions';
import { CompanyActionTypes, UpdateCompanyTarget } from './types';
import { IRootState } from '../index';
import {
  getCompanyExtract,
  getCompanyExtractError,
  getCompanyExtractSuccess,
  getCompanyReports,
  getCompanyReportsError,
  getCompanyReportsSuccess,
  getWalletById,
  getWalletByIdError,
  getWalletByIdSuccess,
  updateCompanyData,
  updateCompanyDataError,
  updateCompanyDataSuccess,
} from './action';
import CompanyReportApi from 'src/Services/Api/CompanyReportApi';

const getCompanyWalletWithColors = (companyWallet: CompanyWallet) => {
  const activitiesWithColors = {};

  companyWallet.pieData.forEach((activity, index) => {
    const { name } = activity;
    activitiesWithColors[name] = graphColors[`cor${index + 1}`];
  });

  const newPieData = companyWallet.pieData.map((item) => ({
    ...item,
    color: activitiesWithColors[item.name],
  }));

  const newActivitiesCategories = companyWallet.activityCategories.map(
    (activity) => {
      return {
        ...activity,
        color: activitiesWithColors[activity.name],
      };
    },
  );

  const newSegments = companyWallet.segments.map((segment) => {
    const newActivitiesCategories = segment.activityCategories.map(
      (activity) => {
        return {
          ...activity,
          color: activitiesWithColors[activity.name],
        };
      },
    );

    return {
      ...segment,
      activityCategories: newActivitiesCategories,
    };
  });
  const newCompanyWallet = {
    ...companyWallet,
    pieData: newPieData,
    activityCategories: newActivitiesCategories,
    segments: newSegments,
  };
  return newCompanyWallet;
};

function* handleGetCompanyWalletById(action: ReturnType<typeof getWalletById>) {
  const { id, date } = action.payload;
  try {
    const result: AxiosResponse<CompanyWallet> = yield call(
      CompanyWalletApi.getWalletById,
      id,
      date,
    );

    if (result.status === 200 && result.data !== null) {
      const companyWalletWithColors = getCompanyWalletWithColors(result.data);

      yield put(getWalletByIdSuccess(companyWalletWithColors));
    } else {
      yield put(getWalletByIdError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getWalletByIdError(error.stack));
    } else {
      yield put(getWalletByIdError('Um erro inesperado aconteceu'));
    }
  }
}

function* handleGetCompanyExtract(
  action: ReturnType<typeof getCompanyExtract>,
) {
  const { page, nameOrEmail, id, sort, startDate, endDate } = action.payload;
  const getCompanyFilterObject = {
    page,
    nameOrEmail,
    id,
    sort,
    startDate,
    endDate,
  };
  try {
    const result: AxiosResponse<PageableResponse<Billing>> = yield call(
      CompanyWalletApi.getCompanyWalletExtract,
      id,
      getCompanyFilterObject,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getCompanyExtractSuccess(result.data));
    } else {
      yield put(getCompanyExtractError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getCompanyExtractError(error.stack));
    } else {
      yield put(getCompanyExtractError('Um erro inesperado aconteceu'));
    }
  }
}

function* handleUpdateCompanyData(
  action: ReturnType<typeof updateCompanyData>,
) {
  try {
    const result: AxiosResponse<void> = yield call(
      BusinessCompany.update,
      action.payload.company,
    );

    if (result.status === 200 && result.data !== null) {
      yield delay(500);
      yield put(updateCompanyDataSuccess());

      let companyDataResult: AxiosResponse<CompanyUser> = yield call(
        BusinessCompany.getCompanyData,
      );
      yield put(updateCompanySessionState(companyDataResult.data?.company));

      const imageUrl =
        action.payload.type === UpdateCompanyTarget.COMPANY_DATA
          ? companyDataResult.data.company?.image?.imageUrl
          : action.payload.type === UpdateCompanyTarget.COMPANY_ADDRESS
          ? companyDataResult.data.company?.address.image?.imageUrl
          : undefined;

      switch (action.payload.type) {
        case UpdateCompanyTarget.COMPANY_DATA:
          // api uploads the company-image async and we need to wait for it to be uploaded
          if (!imageUrl) {
            let counter1 = 0;
            do {
              yield delay(1000);
              companyDataResult = yield call(BusinessCompany.getCompanyData);
              counter1 += 1;
            } while (
              !companyDataResult.data.company?.image?.imageUrl &&
              counter1 < 5
            );
          }
          break;
        case UpdateCompanyTarget.COMPANY_ADDRESS:
          // api generates an address-image async and we need to wait for it to be uploaded
          if (!imageUrl) {
            let counter2 = 0;
            do {
              yield delay(1000);
              companyDataResult = yield call(BusinessCompany.getCompanyData);
              counter2 += 1;
            } while (
              !companyDataResult.data.company?.address.image?.imageUrl &&
              counter2 < 5
            );
          }
          break;
        default:
          companyDataResult = yield call(BusinessCompany.getCompanyData);
      }

      yield put(updateCompanySessionState(companyDataResult.data?.company));
    } else {
      yield put(updateCompanyDataError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(updateCompanyDataError(error.stack));
    } else {
      yield put(updateCompanyDataError('Um erro inesperado aconteceu'));
    }
  }
}

function* handleGetCompanyReports(
  action: ReturnType<typeof getCompanyReports>,
) {
  const { pageable } = action.payload;
  try {
    const companyId = yield select(
      (state: IRootState) => state.authentication.companyUser.company?.id,
    );

    const result: AxiosResponse<PageableResponse<any>> = yield call(
      CompanyReportApi.getCompanyReports,
      companyId,
      pageable,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getCompanyReportsSuccess(result.data));
    } else {
      yield put(getCompanyReportsError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getCompanyReportsError(error.stack));
    } else {
      yield put(getCompanyReportsError('Um erro inesperado aconteceu'));
    }
  }
}

function* companySaga() {
  yield all([
    yield takeLatest(
      CompanyActionTypes.GET_COMPANY_EXTRACT,
      handleGetCompanyExtract,
    ),
    yield takeLatest(
      CompanyActionTypes.GET_WALLET_BY_ID,
      handleGetCompanyWalletById,
    ),
    yield takeLatest(
      CompanyActionTypes.UPDATE_COMPANY,
      handleUpdateCompanyData,
    ),
    yield takeLatest(
      CompanyActionTypes.GET_COMPANY_REPORTS,
      handleGetCompanyReports,
    ),
  ]);
}

export default companySaga;
