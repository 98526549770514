import { AxiosResponse } from 'axios';
import { api } from '.';

const baseUrl = 'account';
export interface ChangePass {
  newPassword: string;
  currentPassword: string;
}
class BusinessAccount {
  static changePass = (data: ChangePass): Promise<AxiosResponse> => {
    return api.put(`${baseUrl}/change-password`, data);
  };
}

export default BusinessAccount;
