import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Row } from 'reactstrap';
import AuthUtils from 'src/Utils/AuthUtils';
import { ReactComponent as Sair } from '../../../Images/Icons/sair-white.svg';

export type LoggedInProps = WithTranslation

const LoggedIn: React.FC<LoggedInProps> = ({ t }) => {
  // const [headquarters, setHeadquarters] = useState<string>('Curitiba');
  // const [dropDown, setDropDown] = useState<boolean>(false);

  return (
    <>
      {AuthUtils.isBusiness() && (
        <li>
          <NavLink to="/logout" className="fixed-header__items--menu--logout">
            <Sair color="white" />
            <div className="logout-button">{t('header.account.logout')}</div>
          </NavLink>
        </li>
      )}
    </>
  );
};
export default withTranslation()(LoggedIn);



// TODO: waiting multiple headquarters
//
//  <li className="fixed-header__items--menu--headquarters">
//           <div className="logout-button">
//             <NavLink to="/logout">
//               <Sair color="white" />
//               {t('header.account.logout')}
//             </NavLink>
//           </div>
//           <div
//             className="fixed-header__items--menu--headquarters--text"
//             onClick={() => setDropDown(!dropDown)}>
//             {t('header.headquarters')}
//             {headquarters}
//           </div>
//           {dropDown && (
//             <>
//               <div className="fixed-header__items--menu--headquarters--dropdown">
//                 <div className="fixed-header__items--menu--headquarters--dropdown--clip-path">
//                   <div className="fixed-header__items--menu--headquarters--dropdown--clip-path--left" />
//                   <div className="fixed-header__items--menu--headquarters--dropdown--clip-path--middle" />
//                   <div className="fixed-header__items--menu--headquarters--dropdown--clip-path--right" />
//                 </div>
//                 <div className="fixed-header__items--menu--headquarters--dropdown__card">
//                   <div
//                     className={`fixed-header__items--menu--headquarters--dropdown__card--title ${
//                       headquarters === 'Curitiba'
//                     }`}
//                     onClick={() => setHeadquarters('Curitiba')}>
//                     {t('header.headquarters')}
//                     Curitiba
//                   </div>
//                   <div
//                     className={`fixed-header__items--menu--headquarters--dropdown__card--title ${
//                       headquarters === 'Rio de Janeiro'
//                     }`}
//                     onClick={() => setHeadquarters('Rio de Janeiro')}>
//                     {t('header.headquarters')}
//                     Rio de Janeiro
//                   </div>
//                   <NavLink to={'/logout'}>
//                     <div className="logout-button">
//                       <Sair />
//                       {t('header.account.logout')}
//                     </div>
//                   </NavLink>
//                 </div>
//               </div>
//             </>
//           )} 