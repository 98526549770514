import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import DevTools from './Config/DevTools';
import i18n from './Config/I18n';
import './index.css';
import ErrorBoundary from './Routes/Components/Error/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import configureStore from './Stores/configureStore';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import defaultMaterialUI from './Themes/defaultMaterialUI';

import { ptBR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;
const theme = createTheme(defaultMaterialUI, ptBR);

const initialState = window.INITIAL_REDUX_STATE;
const store = configureStore(initialState);

const rootEl = document.getElementById('root');

ReactDOM.render(
  <ErrorBoundary>
    <ThemeProvider  theme={theme}>
      {/* adding translation to materialUi components */}
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="pt"
        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
              <div>
                {/* If this slows down the app in dev disable it and enable when required  */}
                {/* {devTools} */}
                <App />
              </div>
          </I18nextProvider>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  rootEl,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
