import { ActivityPlan } from './ActivityPlan';
import { ActivitySchedule } from './ActivitySchedule';
import { BillingCoParticipation } from './BillingCoParticipation';
import { Customer } from './Customer';

export enum BillingStatus {
  FUTURE_CHARGE = 'FUTURE_CHARGE',
  WAITING = 'WAITING',
  PAID = 'PAID',
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  NONE = 'NONE',
  PAYROLL_DISCOUNT = 'PAYROLL_DISCOUNT',
  PIX = 'PIX',
  BANK_SLIP = 'BANK_SLIP',
  DEPOSIT_BY_COMPANY = 'DEPOSIT_BY_COMPANY',
  COMPANY_EXTERNAL = 'COMPANY_EXTERNAL',
}

export interface BillingCompany {
  id?: number;
  paymentMethod?: 'NONE' | 'BANK_SLIP' | 'PIX';
  referenceDate?: string;
  scheduledDate?: string;
  transactionDate?: string;
}

export interface Billing {
  id: number;
  value: number;
  date?: string;
  status?: BillingStatus;
  billingCompany?: BillingCompany;
  paymentMethod?: PaymentMethod;
  activitySchedules?: ActivitySchedule[];
  customerActivityPlan?: CustomerActivityPlan;
  activityPlan?: ActivityPlan;
  billingCoParticipation?: BillingCoParticipation;
  customerBillingAccount?: CustomerBillingAccount;
  pricePaid?: number;
  description?: string;
}

interface CustomerBillingAccount {
  id: number;
  customer: Customer;
}

interface CustomerActivityPlan {
  startPeriodDate: string;
  endPeriodDate: string;
}
