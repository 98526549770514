import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import { IRootState } from 'src/Stores';
import styled from 'styled-components';
import { ReactComponent as Close } from '../../../../Images/Icons/close-gray.svg';
import './styles.scss';

export interface DeleteCustomersModalProps extends WithTranslation {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const DeleteCustomersModal: React.FC<DeleteCustomersModalProps> = ({ t, close, isOpen }) => {

  const toDelete = useSelector(
    (state) => (state as IRootState).selectCustomer.customers,
  );

  const handleConfirm =  async () => {

    const result = await BusinessCustomerApi.deleteCoParticipationCustomers(toDelete);

    if (result.status === 200) {
      toast.success('Benefícios deletados com sucesso!');
      setTimeout(() => {
        window.location.reload();
      }, 400);
      close(false);
    } else {
      toast.error('Falha ao deletar os benefícios')
    }
  }

  return (
    <Modal isOpen={isOpen} centered className='deleteModal'>
      <ModalHeader close={<CloseModal onClick={() => close(false)} />}>
        {t('business.deleteModal.title')}
      </ModalHeader>
      <ModalBody>
        <div>
          {t('business.deleteModal.attention1')}
          <span style={{ color: '#25b379' }}>
            {t('business.deleteModal.attention2', { count: toDelete?.length ?? 0 })}
          </span>
          {t('business.deleteModal.attention3')}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="cancel" onClick={() => close(false)}>
          {t('business.deleteModal.button.cancel')}
        </Button>
        <Button className="payment_way" onClick={handleConfirm}>
          {t('business.deleteModal.button.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const CloseModal = styled(Close)`
  width: 15px;
  height: 15px;
  color: black;
  align-self: center;
  cursor: pointer;
`

export default withTranslation()(DeleteCustomersModal);
