import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';

import './styles.scss';
import { Link } from 'react-router-dom';
import BusinessGroupBenefits from 'src/Services/Api/BusinessGroupBenefits';
import { CoParticipationCustomer } from 'src/Models/Business/CoParticipationCustomer';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import Benefits from '../Benefits';
import CoParticipationGroupList from './CoParticipationGroupList';

export interface BenefitCategoriesProps extends WithTranslation {
  benefitClassName?: string;
  title?: string;
  allowCreate?: boolean;
  benefits?: CoParticipationGroup[];
  options?: CoParticipationGroup[];
  customer?: CoParticipationCustomer;
  onChange?: (items: CoParticipationGroup[]) => void;
}

const BenefitCategories: React.FC<BenefitCategoriesProps> = ({
  t,
  benefitClassName = 'col-8 mt-40',
  title,
  allowCreate = true,
  customer,
  benefits,
  options,
  onChange,
}) => {
  const [showSegments, setShowSegments] = useState<boolean>(false);
  const [allBenefits, setAllBenefits] = useState<CoParticipationGroup[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    BusinessGroupBenefits.getAllGroupBenefits().then(({ data }) => {
      if (options != null) {
        setAllBenefits(data.filter(it => options.some(o => o.id == it.id)));
      } else {
        setAllBenefits(data);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [options, showSegments]);

  if (loading === true) {
    return <div />;
  }

  return (
    <div className={`${benefitClassName} BenefitCategories`}>
      <span className="title">
        {title ?? t('business.flux.segment_benefits')}
      </span>
      {/* todo: remove allowCreate */}
      {allowCreate && (
        <Link to={{ pathname: '/new-benefit', state: { customer } }} className="headerBtns item">
          {`+  ${title || t('business.flux.create_new_benefit')}`}
        </Link>
      )}
      <div className="divider mb-25" />
      {showSegments && (
        <>
          <div className="align-cards col-12 pd-lr-0">
            <CoParticipationGroupList
              groups={allBenefits}
              initialSelected={benefits}
              onChange={(items) => onChange && onChange(items)}
            />
          </div>
          <div className="btns" onClick={() => setShowSegments(false)}>
            <CheckIcon />
            <span>{t('business.flux.include_groups')}</span>
          </div>
        </>
      )}

      {!showSegments && (
        <div style={{ padding: 10 }}>
          <Benefits
            benefits={benefits}
            type="include"
            onClick={() => setShowSegments(true)}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(BenefitCategories);
