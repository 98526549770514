import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row, Button, ModalBody, ModalFooter } from 'reactstrap';
import { ReactComponent as Money } from '../../../../Images/Icons/money.svg';

export interface SuccessProps extends WithTranslation {
  toggle: (main: string, step?: string) => void;
  companyWallet: string;
}

const Success: React.FC<SuccessProps> = ({ t, toggle, companyWallet }) => {
  return (
    <>
      <ModalBody className="WalletModal__body">
        <div className="modal_col">
          <Money className="money-icon" />
          <div className="modal_col_description">
            Seu pagamento via Pix foi confirmado! Seus colaboradores já podem
            aproveitar os benefícios da Keiken.
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="WalletModal__footer">
        <Col className="no-padding">
          <Row className="no-padding payment_way_row">
            <Button className="payment_way" onClick={() => toggle('')}>
              Concluir pagamento
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </>
  );
};

export default withTranslation()(Success);
