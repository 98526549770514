import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './styles.scss';
import StringUtils from 'src/Utils/StringUtils';

export interface SubcategoryBarProps extends WithTranslation {
  category?: string;
  subcategory: string;
  perCent: string;
  color: string;
}

const SubcategoryBar: React.FC<SubcategoryBarProps> = ({
  subcategory,
  perCent,
  color,
}) => {
  return (

    <div className="SubCategory__bar" style={{borderColor: color}}>
      <div className='SubCategory__bar--text' style={{backgroundColor: color, width: perCent}}>
        <p className={`SubCategory__bar--title`}>
          {StringUtils.characterLimit(subcategory, 15)} 
        </p>
        <p>{perCent}</p>
      </div>
    </div>
  )
};

export default withTranslation()(SubcategoryBar);
