import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    fontFamily: ['Muller', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(
      ',',
    ),
  },
  palette: {
    primary: {
      light: '#64e6a8',
      main: '#25B379',
      dark: '#00824d',
      contrastText: '#fff',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    // },
  },
});