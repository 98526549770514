import { ConferenceSession } from 'src/Models/ConferenceSession';
import { AxiosResponse } from 'axios';
import { checkApiStatus } from 'src/Utils/ApiUtils';
import ConferenceApi from './Api/ConferenceApi';
import CompanyApi from './Api/CompanyApi';


class CompanyService {
  
  static register = async (params): Promise<void> => {
    try {
      const result = await CompanyApi.register(params);
      if (!checkApiStatus(result.data != null, result.status)) {
        return Promise.reject({ status: result.status, statusText: result.statusText });
      }
      return Promise.resolve();
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  }

}

export default CompanyService;