import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './styles.scss';
import CurrencyInput from 'react-currency-input';
import _ from 'lodash';
import { GroupActivityCategory } from 'src/Models/Business/CoParticipationGroup';

export interface BenefitCategoriesInputValue extends WithTranslation {
  onChangeValue?: (index, value) => void;
  maxValue: number;
  groupActivityCategory: GroupActivityCategory;
}

const BenefitCategoriesInputValue: React.FC<BenefitCategoriesInputValue> = ({
  t,
  onChangeValue,
  maxValue,
  groupActivityCategory,
}) => {
  const { activityCategory, maxSpendingCategory } = groupActivityCategory;
  const [value, setValue] = useState<number>(
    maxSpendingCategory != null 
      ? maxSpendingCategory
      : maxValue,
  );

  const handleChange = (value: number) => {
    onChangeValue(activityCategory?.id, value);
    setValue(value);
  };

  return (
    <div className="col-4 padd-none">
      <div className="customInput mt-20">
        <span className="inputTitle">{activityCategory?.name}</span>
        <br />
        <CurrencyInput
          value={value}
          onChangeEvent={(e, formatted, value) => handleChange(value)}
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          precision="2"
        />
      </div>
    </div>
  );
};

export default withTranslation()(BenefitCategoriesInputValue);
