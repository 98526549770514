import React from 'react';

import '../styles.scss';
import pocket from '../../Images/Keiken/Business/pocket.svg';
import InputMask from 'react-input-mask';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, FormikErrors } from 'formik';
import { BusinessFormValues, classnameField } from '..';

export interface BusinessRegisterUserFormProps extends WithTranslation {
  errors: FormikErrors<BusinessFormValues>;
}

const BusinessRegisterUserForm: React.FC<BusinessRegisterUserFormProps> = ({
  t,
  errors,
}) => {
  return (
    <>
      <div className="mt-20" />
      <span className="AddressStyle">{t('business.register.user.title')}</span>

      <div className={classnameField(errors.userName)}>
        <span className="inputTitle">{t('business.register.user.name')}</span>
        <br />
        <Field
          type="text"
          className="no-border"
          placeholder={t('business.register.user.name-placeholder')}
          name="userName"
          id="userName"
        />
      </div>
      <div className="user-fields">
        <div className="col-md-5 pl-0 pr-0-mq">
          <div className={classnameField(errors.phone)}>
            <span className="inputTitle">
              {t('business.register.user.phone')}
            </span>
            <br />
            <Field
              id="phone"
              name="phone"
              type="text"
              className="no-border"
              render={({ field, ...props }) => (
                <InputMask
                  mask="(99) 9 9999-9999"
                  placeholder={t('business.register.user.phone-placeholder')}
                  {...field}
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-md-7 pl-0 pr-0">
          <div className={classnameField(errors.email)}>
            <span className="inputTitle">
              {t('business.register.user.email')}
            </span>
            <br />
            <Field
              type="text"
              className="no-border"
              placeholder={t('business.register.user.email-placeholder')}
              name="email"
              id="email"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(BusinessRegisterUserForm);
