import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { PayrollDicountType } from 'src/Models/payrollDiscountType';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import PayrollDicountService from 'src/Services/payrollDiscountService';
import { useLocation } from 'react-router-dom';
import CustomBoxCheckBox from '../CustomBoxCheckBox';
import Pagination from '../Pagination';

import Contributor from './Contributor';
import Options from './Options';
import Settings from './Settings';
import './styles.scss';

export enum Filter {
  WITH_BENEFITS = 'withBenefits',
  WITHOUT_BENEFITS = 'withoutBenefits',
  BLOCKED_BY_NO_SHOW = 'blockedByNoShow',
  ALL = 'all',
}

export type ContributorsProps = WithTranslation;

const Contributors: React.FC<ContributorsProps> = ({ t }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const withBenefits = queryParams.get('withBenefits');

  const [contributorsSelected, setContributorsSelected] = useState<any[]>([]);
  const [filter, setFilter] = useState<Filter>(Filter.ALL);
  const [searchName, setSearchName] = useState<string>('');
  const [search, setSearch] = useState<boolean>(false);
  const [sort, setSort] = useState<boolean>(false);
  const [newer, setNewer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [allCustomers, setAllCustomers] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [allPages, setAllPages] = useState<number>(1);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (withBenefits === 'true') {
      setFilter(Filter.WITH_BENEFITS);
    } else if (withBenefits === 'false') {
      setFilter(Filter.WITHOUT_BENEFITS);
    }
  }, [location]);

  useEffect(() => {
    let isSubscribed = true;
    async function fetchAllCustomers2() {
      const all = (
        await BusinessCustomerApi.getAllCustomers({
          email: searchName,
          newer,
          blockedByNoShow: filter === Filter.BLOCKED_BY_NO_SHOW,
          sort,
          page: page - 1,
          segments: {
            filter: filter !== Filter.ALL,
            value: filter === Filter.WITH_BENEFITS,
          },
        })
      ).data;
      if (!isSubscribed) {
        return;
      }
      setAllCustomers(all.content);
      setAllPages(all.totalPages);
      setLoading(false);
    }

    fetchAllCustomers2();

    return () => {
      isSubscribed = false;
    };
  }, [search, sort, newer, page, filter]);

  async function fetchAllCustomers() {
    const all = (
      await BusinessCustomerApi.getAllCustomers({
        email: searchName,
        newer,
        sort,
        page: page - 1,
        segments: {
          filter: filter !== Filter.ALL,
          value: filter === Filter.WITH_BENEFITS,
        },
      })
    ).data;

    setAllCustomers(all.content);
    setAllPages(all.totalPages);
    setLoading(false);
  }

  const checkAll = () => {
    if (isChecked) {
      setContributorsSelected([]);
    } else {
      setContributorsSelected(allCustomers);
    }
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    let updateList: PayrollDicountType[] = [];

    contributorsSelected.map((contributor) => {
      if ('payrollDiscount' in contributor) {
        const newItem = {
          id: contributor.id,
          payrollDiscountEnabled: contributor.payrollDiscount,
        };
        updateList = [...updateList, newItem];
      }
    });

    if (contributorsSelected.length > 0) {
      if ('payrollDiscount' in contributorsSelected[0]) {
        PayrollDicountService.updatePayroll(updateList);
      }
    }
  }, [contributorsSelected]);

  return (
    <Row>
      {!loading ? (
        <Container className="contributors">
          <Settings
            setFilter={setFilter}
            filter={filter}
            searchName={searchName}
            setSearchName={setSearchName}
            search={search}
            setSearch={setSearch}
            sort={sort}
            setSort={setSort}
            newer={newer}
            setNewer={setNewer}
            onSubmit={fetchAllCustomers}
            setContributorsSelected={setContributorsSelected}
            contributorsSelected={contributorsSelected}
            redirectToSegment
          />
          <Row style={{ width: '100%' }}>
            <Col>
              <div className="contributors-table-wrapper">
                <div className="contributors-table">
                  <div className="table-header">
                    <div className="contribuitorsCheckBox">
                      <CustomBoxCheckBox
                        onClick={checkAll}
                        isChecked={isChecked}
                      />
                    </div>
                    <div
                      style={{ width: '300px' }}
                      className="table-header-item"
                    >
                      Colaborador
                    </div>
                    <div style={{ flex: 3 }} className="table-header-item">
                      Segmento
                    </div>
                    <div style={{ flex: 3 }} className="table-header-item">
                      Beneficios
                    </div>

                    <div style={{ flex: 3 }} className="table-header-item">
                      Descontro em Folha
                    </div>
                    <div style={{ flex: 1 }} className="table-header-item" />
                  </div>
                  {allCustomers.map((customer, idx) => (
                    <Contributor
                      key={idx}
                      contributor={customer}
                      setContributorsSelected={setContributorsSelected}
                      contributorsSelected={contributorsSelected}
                    />
                  ))}
                </div>
              </div>
              <Pagination page={page} setPage={setPage} allPages={allPages} />
            </Col>
          </Row>
          {contributorsSelected.length > 0 && (
            <Options customers={contributorsSelected} />
          )}
        </Container>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default withTranslation()(Contributors);
