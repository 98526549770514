import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import CompanyReportApi, {
  ICompanyReport,
} from 'src/Services/Api/CompanyReportApi';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CircleLoading } from 'react-loadingg';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

import './styles.scss';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/Stores';

interface IBusinessWalletReportProps {
  insideOverview?: boolean;
}

function BusinessWalletReport({ insideOverview = false }: IBusinessWalletReportProps) {
  const [companyReports, setCompanyReports] = useState<ICompanyReport[]>([]);
  const [currentReport, setCurrentReport] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [hide, setHide] = useState<boolean>(false);

  const companyId = useSelector(
    (state: IRootState) => state.authentication.companyUser.company?.id,
  );
  const getCompanyReports = async () => {
    if (!companyId) return;
    const reportData = await CompanyReportApi.getCompanyReports(companyId, {
      size: 20,
      page: 0,
    });
    setCompanyReports(reportData.data.content);
  };
  useEffect(() => {
    getCompanyReports();
  }, [companyId]);

  const nextReport = () => {
    if (currentReport - 1 > 0) {
      setCurrentReport(currentReport - 1);
      setLoading(true);
    }
  };

  const previousReport = () => {
    if (currentReport + 1 < companyReports.length) {
      setCurrentReport(currentReport + 1);
      setLoading(true);
    }
  };

  useEffect(() => {
    // iframe reload hack
    setHide(true);
    setTimeout(() => {
      setHide(false);
    }, 100);
  }, [currentReport]);

  return (
    <div className="reports-container">
      {!insideOverview && (
        <div className="select-report">
          <ArrowLeft
            fontSize="large"
            style={{ cursor: 'pointer', color: '#25b379' }}
            onClick={previousReport}
          />
          <DatePicker
            label="Mês de referência:"
            views={['month', 'year']}
            readOnly
            disableFuture
            format="MM/YYYY"
            disableOpenPicker
            value={dayjs(companyReports[currentReport]?.reportDate)}
          />
          <ArrowRight
            fontSize="large"
            style={{ cursor: 'pointer', color: '#25b379' }}
            onClick={nextReport}
          />
        </div>
      )}
      {loading && <CircleLoading />}
      {companyReports.length > 0 && !hide && (
        <iframe
          title="embeddedPage"
          style={{
            width: '100%',
            height: loading ? '0%' : '100%',
            borderRadius: '10px',
            backgroundColor: 'white',
          }}
          src={companyReports[currentReport].url}
          onLoad={() => {
            setLoading(false);
          }}
          frameBorder="0"
          allowFullScreen
        />
      )}
    </div>
  );
}

export default BusinessWalletReport;
