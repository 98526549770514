import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import BaseFooterContributor from 'src/Components/BaseFooterContributor';
import BaseHeaderContributor from 'src/Components/BaseHeaderContributor';
import BenefitCategories from 'src/Components/ColaboratorBenefits';
import Professional from 'src/Components/Professional';
import './styles.scss';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import { CoParticipationCustomer } from 'src/Models/Business/CoParticipationCustomer';
import { parseInt } from 'lodash';
import { CoParticipationSegment } from 'src/Models/Business/CoParticipationSegment';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/Stores';
import {
  BillingCoParticipation,
  BillingCoParticipationStatus,
} from 'src/Models/BillingCoParticipation';
import moment from 'moment';
import { CoParticipationGroup } from 'src/Models/Business/CoParticipationGroup';
import { toast } from 'react-toastify';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PayrollDicountType } from 'src/Models/payrollDiscountType';
import PayrollDicountService from 'src/Services/payrollDiscountService';
import CompanyWalletService from 'src/Services/CompanyWalletServices';
import ToastService from 'src/Services/ToastService';
import Images from 'src/Themes/Constants/Images';
import SimpleModal from 'src/Components/SimpleModal';
import StringUtils from 'src/Utils/StringUtils';

type Props = WithTranslation;

const BusinessEditContributor: React.FC<Props> = ({ t }) => {
  const [benefits, setBenefits] = useState<CoParticipationGroup[]>([]);
  const [allSegments, setAllSegments] = useState<CoParticipationSegment[]>([]);
  const [coParticipationCustomer, setCoParticipationCustomer] =
    useState<CoParticipationCustomer>();
  const [lastBillings, setLastBillings] = useState<BillingCoParticipation[]>(
    [],
  );

  const companyId = useSelector(
    (root: IRootState) => root.authentication.companyUser,
  )?.company?.id;

  const location = useParams<{ id: string }>();
  useEffect(() => {
    if (location.id != null) {
      getCoParticipationCustomer(parseInt(location.id));
      BusinessSegment.getAllSegments().then(({ data }) => {
        setAllSegments(data);
      });
    }
  }, [location]);

  const getCoParticipationCustomer = (id: number) => {
    BusinessCustomerApi.getOneCustomer(id).then(({ data }) => {
      setCoParticipationCustomer(data);
      setBenefits(data?.groups);
    });

    CompanyWalletService.billingsCoParticipations(companyId, {
      'coParticipationCustomer.id': id,
      sort: true,
      size: 7,
    })
      .then(({ content }) => {
        setLastBillings(content);
      })
      .catch((err) => {
        ToastService.error('Falha ao buscar faturas');
      });
  };

  const history = useHistory();
  const handleEdit = async () => {
    BusinessCustomerApi.editOneCustomer(coParticipationCustomer)
      .then(({ data, status }) => {
        if (status == 200) {
          toast.success('Colaborador editado com sucesso!');
          setTimeout(() => {
            history?.goBack();
          }, 1000);
        } else if (data.detail != null) {
          toast.error(data.detail);
        } else {
          toast.error('Falha ao editar colaborador');
        }
      })
      .catch((err) => {
        toast.error('Falha ao editar colaborador');
      });
  };

  const handleChangeSelect = (selected: CoParticipationSegment) => {
    setCoParticipationCustomer((prev) => ({
      ...prev,
      coParticipationSegment: selected,
      groups: [],
    }));
    setBenefits(selected.groups);
  };

  const segment = coParticipationCustomer?.coParticipationSegment;
  return (
    <>
      {location?.id == null && <Redirect to="/benefits/contributors" />}
      <BaseHeaderContributor />
      <div className="EditContributor">
        <div className="EditContributor__Infos">
          <CoPartipationCustomerAvatar
            t={t}
            coParticipationCustomer={coParticipationCustomer}
          />
          <CoParticipationCustomerEditSegment
            selected={segment}
            options={allSegments}
            onChange={handleChangeSelect}
          />
        </div>
        <div className="EditContributor__Benefit">
          <div className="EditContributor__Benefit--col">
            <BenefitCategories
              title={t('business.flux.colaborator_benefits')}
              benefitClassName=""
              allowCreate={false}
              options={benefits}
              benefits={coParticipationCustomer?.groups}
              onChange={(items) => {
                setCoParticipationCustomer((prev) => ({
                  ...prev,
                  groups: items,
                }));
              }}
            />
          </div>
        </div>
        <LastBillingsList billings={lastBillings} />
      </div>
      <BaseFooterContributor handleEdit={handleEdit} />
    </>
  );
};

interface AvatarProps {
  coParticipationCustomer?: CoParticipationCustomer;
  t?: any;
}

const CoPartipationCustomerAvatar: React.FC<AvatarProps> = ({
  coParticipationCustomer,
  t,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [unblockModal, setUnblockModal] = useState<boolean>(false);

  const handleUpdate = () => {
    setIsActive(!isActive);

    const updatePayroll: PayrollDicountType[] = [
      {
        id: coParticipationCustomer?.id,
        payrollDiscountEnabled: !isActive,
      },
    ];
    PayrollDicountService.updatePayroll(updatePayroll);
  };

  function handleOpenUnblockCustomerModal() {
    setUnblockModal(true);
  }

  function onClose() {
    setUnblockModal(false);
  }

  function handleUnblockCustomer() {
    BusinessCustomerApi.editOneCustomer({
      id: coParticipationCustomer.id,
      block: false,
    })
      .then((res) => {
        if (res.status == 200) {
          toast.success(t('Contributors.colaboratorUnBlockedWithSuccess'), {
            onClose: () => {
              window.location.reload();
            },
          });
        } else {
          toast.warn(t('Contributors.failureToUnblockColaborator'));
        }
      })
      .catch((err) => {
        toast.warn(t('Contributors.failureToUnblockColaborator'));
        console.error(err);
      });
  }

  const nameSegment =
    coParticipationCustomer?.coParticipationSegment?.nameSegment;
  const styleSegment = nameSegment ? {} : { backgroundColor: '#ff0062' };

  const firstTwoNames =
    `${coParticipationCustomer?.customer?.name.split(' ')[0]} ${coParticipationCustomer?.customer?.name.split(' ')[1] != undefined
      ? coParticipationCustomer?.customer?.name.split(' ')[1]
      : ''
    }` || 'Sem nome';
  return (
    <div className="EditContributor__Infos--personal">
      <div className="EditContributor__Infos--row">
        <div
          style={{
            height: '100px',
            width: '100px',
            marginRight: '24px',
            borderRadius: '37px',
            display: 'flex',
          }}
        >
          <img
            style={{ height: '100%', width: '100%', marginTop: '-5px' }}
            src={Images.icons.defaultPerfil}
            alt="Foto Perfil"
          />
        </div>
        {' '}
        <div className="EditContributor__Infos--personal--infos">
          <div className="EditContributor__Infos--personal--infos--name">
            {firstTwoNames}
          </div>

          <div
            className="EditContributor__Infos--personal--infos--segment"
            style={styleSegment}
          >
            {nameSegment ?? 'Sem segmento'}
          </div>
          <div className="EditContributor__Infos--personal--infos--email">
            {coParticipationCustomer?.email}
          </div>
          <div className="EditContributor__Infos--personal--infos--phone">
            {coParticipationCustomer?.customer?.phones?.[0]?.number ||
              'Sem telefone'}
          </div>

          {coParticipationCustomer?.unblockDate && (
            <>
              <span style={{ color: 'red', marginTop: '5px' }}>
                {t('Contributors.colaboratorBlockedUntil', {
                  date: StringUtils.formatDateTimestamp(
                    coParticipationCustomer?.unblockDate,
                  ),
                })}
              </span>
              <button
                onClick={() => handleOpenUnblockCustomerModal()}
                className="button--remove__noShowBlock"
              >
                {t('Contributors.removeBlock')}
              </button>
            </>
          )}
        </div>
        <div className="EditContributor__Infos--personal--infos--payrollDiscount">
          <div>Desconto em folha</div>
          <div className="Contributor_payrollDiscount">
            <label className="switch">
              <input
                type="checkbox"
                onChange={() => handleUpdate()}
                checked={isActive}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>
      <SimpleModal
        isOpen={unblockModal}
        labelConfirm="Desbloquear"
        labelCancel="Sair"
        title="Desbloquear colaborador"
        onConfirm={() => handleUnblockCustomer()}
        onCancel={() => onClose()}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <span>{t('Contributors.wishToUnBlockUser')}</span>
          <span>{t('Contributors.BenefitToUnblock')}</span>

          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '20px',
              flexDirection: 'column',
              gap: '4px',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <span style={{ fontSize: '18px' }}>
              {t('Contributors.Colaborator')}
            </span>
            <div>
              <span>Nome: </span>
              <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
                {` ${coParticipationCustomer?.customer?.name}`}
              </span>
            </div>
            <div>
              <span>Email: </span>
              <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
                {` ${coParticipationCustomer?.email}`}
              </span>
            </div>
            <div>
              <span>Telefone: </span>
              <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
                {coParticipationCustomer?.customer?.phones?.[0]?.number ||
                  'Sem telefone'}
              </span>
            </div>
          </div>
        </div>
      </SimpleModal>
    </div>
  );
};

interface EditSegmentProps {
  options?: CoParticipationSegment[];
  selected?: CoParticipationSegment;
  onChange?: (item: CoParticipationSegment) => void;
}

const CoParticipationCustomerEditSegment: React.FC<EditSegmentProps> = ({
  options,
  selected,
  onChange,
}) => {
  const handleOnChange = (e) => {
    const id = e?.target?.value;
    if (id != null && id != selected?.id) {
      const newSegment = options.find((it) => it.id == id);
      onChange?.(newSegment);
    }
  };

  return (
    <div className="EditContributor__Infos--segment">
      <div className="EditContributor__Infos--segment--title">
        Editar Segmento
      </div>
      <div className="EditContributor__Infos--segment--edit">
        <label className="EditContributor__Infos--segment--edit--label">
          <div className="EditContributor__Infos--segment--edit--description">
            Segmentos
          </div>
          <select
            onChange={handleOnChange}
            defaultValue="DEFAULT"
            value={selected?.id}
          >
            <option value="DEFAULT" disabled>
              Escolher segmento
            </option>
            {options?.map?.((it, index) => (
              <option key={`segment-${it.id}-${index}`} value={it.id}>
                {it?.nameSegment ?? ''}
              </option>
            ))}
          </select>
        </label>
      </div>
    </div>
  );
};

interface LastBillingsListProps {
  billings: BillingCoParticipation[];
}

const LastBillingsList: React.FC<LastBillingsListProps> = ({ billings }) => {
  return (
    <div className="EditContributor__LastActivities">
      <div className="EditContributor__LastActivities__section">
        <div className="EditContributor__LastActivities__section--title">
          Últimas Atividades
        </div>
      </div>
      <Row className="no-padding">
        <div className="EditContributor__LastActivities__activities">
          {billings?.map((item, index, array) => (
            <LastBillingCoParticipationItem
              key={`billings-${item?.id}`}
              item={item}
              index={index}
              length={array?.length}
            />
          ))}
        </div>
      </Row>
    </div>
  );
};

interface LastBillingsProps {
  item: BillingCoParticipation;
  index: number;
  length: number;
}
const LastBillingCoParticipationItem: React.FC<LastBillingsProps> = ({
  item,
  index,
  length,
}) => {
  const billingDescription =
    item.status == BillingCoParticipationStatus.FUTURE_CHARGE
      ? 'Pré reservou'
      : 'Comprou';
  const date = moment(item.date).format('DD/MM/YYYY');
  const time = moment(item.date).format('HH:mm');

  const billingActivity =
    item.billing?.activityPlan ??
    item.billing?.activitySchedules?.[0]?.activity;

  const categories =
    billingActivity?.activityCategories?.map?.((it) => it?.name) ?? [];
  const activityName = billingActivity?.name ?? '';
  const partnerPlace = billingActivity?.partnerPlace;

  return (
    <div className="EditContributor__LastActivities__activities--activity">
      <div className="EditContributor__LastActivities__activities--activity__date">
        {index !== 0 && length > 1 && (
          <div className="EditContributor__LastActivities__activities--activity__date__bar" />
        )}
        <div className="EditContributor__LastActivities__activities--activity__date--day">
          {date}
        </div>
        <div className="EditContributor__LastActivities__activities--activity__date--hour">
          {time}
        </div>
        {index !== length - 1 && length > 1 && (
          <div className="EditContributor__LastActivities__activities--activity__date__bar" />
        )}
      </div>
      <div className="DescriptionAndProfessional">
        <Row className="no-padding align_center">
          <div className="EditContributor__LastActivities__activities--activity__description">
            <p>
              {billingDescription}
              <b>{` ${activityName}`}</b>
            </p>
          </div>
          <div className="EditContributor__LastActivities__activities--activity__professional">
            {partnerPlace && (
              <Professional
                name={partnerPlace.fantasyName}
                partnerImage={partnerPlace?.image?.imageUrl}
                categories={categories}
              />
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(BusinessEditContributor);
