import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import ToastService from 'src/Services/ToastService';

import { IRootState } from 'src/Stores';
import { companyUpdateReset } from 'src/Stores/company/action';

import LoadingContainerScreen from '../loading-container-screen/loading-container-screen';
import AddressData from './AdressData';
import BusinessData from './BusinessData';
import ChangePassCard from './ChangePassword';
import OwnerData from './ownerData';
import './styles.scss';

export interface BusinessCardsProps extends StateProps, DispatchProps, WithTranslation {}

export const BusinessCards: React.FC<BusinessCardsProps> = ({
  t,
  companyUser,
  updateStatus,
  companyUpdateReset,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    switch(updateStatus){
      case HttpRequestStatus.SUCCESS:
        companyUpdateReset()
        ToastService.success(t('cards.toastSucess'))
        break;
      case HttpRequestStatus.ERROR:
        companyUpdateReset()
        ToastService.error(t('cards.toastError'));
    }
  }, [updateStatus])

  const company = companyUser?.company;

  return (
    <LoadingContainerScreen isLoading={loading}>
      <BusinessData
        company={companyUser.company}
      />
      <AddressData company={company} />
      <OwnerData companyUser={companyUser} />
      <ChangePassCard />
    </LoadingContainerScreen>
  );
};

const mapStateToProps = ({ authentication, companyState }: IRootState) => ({
  account: authentication.account,
  companyUser: authentication.companyUser,
  updateStatus: companyState.updateStatus,
});

const mapDispatchToProps = {
  companyUpdateReset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(BusinessCards) as React.ComponentType<{}>;
