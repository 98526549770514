import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SimpleModal from 'src/Components/SimpleModal';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import '../styles.scss';
import Pagination from 'src/Components/Pagination';
import Dropzone from 'src/Components/Dropzone';
import BusinessSegment from 'src/Services/Api/BusinessSegment';
import makeNewStyles from '../style';
import { isEmpty } from 'lodash';

export interface AddManyProps extends WithTranslation {
  setSegmentCustomers?: React.Dispatch<React.SetStateAction<any[]>>;
  setOpenMultiple: React.Dispatch<React.SetStateAction<boolean>>;
  openMultiple: boolean;
  segmentName?: string;
  customersPage?: boolean;
}

const AddMany: React.FC<AddManyProps> = ({
  t,
  setOpenMultiple,
  openMultiple,
  segmentName,
  setSegmentCustomers,
  customersPage,
}) => {
  const classes = makeNewStyles();
  const [allEmails, setAllEmails] = React.useState<any[]>([]);
  const [emailsPage, setEmailPage] = React.useState<any[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [segment, setSegment] = useState<any>();
  const [allSegments, setAllSegments] = useState<any>();

  const getSegments = async () => {
    if (customersPage) {
      const getAll = (await BusinessSegment.getAllSegments()).data;
      setAllSegments(getAll);
      setSegment(getAll[0]);
    }
  };

  useEffect(() => {
    if (customersPage) {
      getSegments();
    }
  }, []);

  const handleDelete = (contributor: any) => {
    setAllEmails((customers) =>
      customers.filter((customer) => customer?.email !== contributor?.email),
    );
  };

  const handleAllPages = () => {
    if (allEmails.length > 0) {
      return Math.ceil(allEmails.length / 5);
    }
    return 1;
  };

  const handleAddCustomer = async () => {
    if (!customersPage) {
      setOpenMultiple(false);
      setSegmentCustomers((customers) => [
        ...customers,
        ...allEmails.filter(
          (it) => !customers.some((customer) => customer.email === it.email),
        ),
      ]);
    }
  };

  const handleSegmentInput = () => {
    if (!customersPage) {
      return (
        <div className="customInputDisabled mt-20">
          <span className="inputTitle">{t('business.flux.segment_name')}</span>
          <br />
          <input type="text" disabled value={segmentName || 'Sem nome'} />
        </div>
      );
    } else if (allSegments) {
      return (
        <div className="customInputAvailable mt-20">
          <span className="inputTitle">{t('business.flux.segment_name')}</span>
          <br />
          <select
            onChange={(e) => {
              setSegment(
                allSegments.find((el) => el.nameSegment === e.target.value),
              );
            }}
          >
            {allSegments.map((segment, idx) => (
              <option key={idx}>{segment.nameSegment}</option>
            ))}
          </select>
        </div>
      );
    }
    return <></>;
  };

  React.useEffect(() => {
    setEmailPage(allEmails.slice((page - 1) * 5, page * 5));
  }, [page, allEmails]);

  const bodyMultiple = (
    <div className={classes.paper}>
      <div className="modalEditBusiness ListColaborators">
        <div className={classes.header}>
          <p id="simple-modal-title" className="modalTitle">
            {t('business.flux.add_colaborator')}
          </p>
          <CloseIcon
            className={classes.close}
            onClick={() => setOpenMultiple(false)}
          />
        </div>
        <div className="underline" />
        {isEmpty(allEmails) && (
          <>
            <a
              className="buttonModalModal"
              href="https://keiken-prod.s3.amazonaws.com/documents/template.csv"
              download
            >
              + 
              {' '}
              {t('business.flux.download_template')}
            </a>
            <Dropzone setEmails={setAllEmails} />
            <div className="underline mt-40" />
            <p className="modalSubtitles">
              {t('business.flux.add_colaborator')}
            </p>
          </>
        )}
        {handleSegmentInput()}
        <div className="underline mt-30" />
        <div className="AddedColaborators mt-0 mb-0">
          <span className="title mb-10">
            {t('business.flux.added_colaborators')}
          </span>
          {allEmails.length == 0 && (
            <div className="d-flex justify-content-center text-center">
              <div className="mb-30">
                <AccountBoxOutlinedIcon className="icon" />
                <br />
                <span className="col-3 text-card">
                  {t('business.flux.dont_have')}
                  <br />
                  {t('business.flux.added_colaborator')}
                </span>
              </div>
            </div>
          )}
        </div>

        <div>
          <table className="width-100">
            {emailsPage.map((item) => (
              <tr className="line-table col-12">
                <td className="col-4 email p-0">{item?.email}</td>
                <td className="col-2">
                  <div className="tag">
                    {segmentName || segment?.nameSegment || 'Sem segmento'}
                  </div>
                </td>
                {segment?.groups.map((group, index) => (
                  <td className="col-2">
                    {index < 2 && (
                      <div className="tag2">{group?.nameGroup}</div>
                    )}
                    {index === 2 && (
                      <div className="tag2">{segment?.groups?.length - 2}</div>
                    )}
                  </td>
                ))}
                <td className="col-6" onClick={() => handleDelete(item)}>
                  <DeleteOutlineOutlinedIcon className="right circle" />
                </td>
              </tr>
            ))}
          </table>
        </div>
        <Pagination page={page} allPages={handleAllPages()} setPage={setPage} />

        <div className="footerModal">
          <span className="cancel" onClick={() => setOpenMultiple(false)}>
            {t('business.flux.cancel')}
          </span>
          <span className="buttonModal" onClick={() => handleAddCustomer()}>
            {t('business.flux.add_colaborators')}
          </span>
        </div>
      </div>
      <SimpleModal />
    </div>
  );
  return (
    <>
      <Modal
        open={openMultiple}
        onClose={() => setOpenMultiple(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="overflow-auto"
      >
        {bodyMultiple}
      </Modal>
    </>
  );
};

export default withTranslation()(AddMany);
